import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { MOVEMENT_TYPES } from "constants/index";
import { recursiveSearchFilter, searchFilter } from "helpers/filters";
import { groupBy } from "helpers/groupBy";
import {
  Article,
  IArticleLocationQuery,
  Movement,
  MovementArticle,
  Store,
  Location,
} from "types";
import AddIncomeRow from "./AddIncomeRow";
import AddMovementArticleRow from "./AddMovementArticleRow";
import { GroupedMovementArticles } from "pages/DischargesPage";
import { MOVEMENT_ARTICLES_URL } from "constants/urls";
import useCrud from "hooks/useCrud";
import { queryBuilder } from "helpers/query";
import { debounce } from "lodash";
import Alert from "components/utils/Alert";

type Props = {
  open: boolean;
  handleOpen: () => void;
  movement: Movement;
  articles?: Article[];
  rowIndex: number;
  handleAddMovementArticle: (newMovementArticle: MovementArticle) => void;
  operationType: string;
  locations: Location[];
  stores: Store[];
};

export default function AddMovementArticleModal({
  open = true,
  handleOpen,
  movement,
  articles,
  rowIndex,
  handleAddMovementArticle,
  operationType,
  locations,
  stores,
}: Props) {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const { data: movement_articles, fetchData: getMovementArticles } =
    useCrud<GroupedMovementArticles>(MOVEMENT_ARTICLES_URL);

  const latestSearch = useRef(search);
  const handleMovementArticlesFilter = useCallback(
    async (search: string) => {
      const query = queryBuilder({
        clientId: movement.client_id,
        storeId: movement.store_id,
        search: search,
      } as IArticleLocationQuery);
      await getMovementArticles(query, true);
      setLoading(false);
    },
    [getMovementArticles, movement.client_id, movement.store_id]
  );

  const debouncedSearch = useCallback(
    debounce(async (query: string) => {
      if (latestSearch.current.trim()) {
        await handleMovementArticlesFilter(latestSearch.current);
      } else {
        setLoading(false);
      }
    }, 1000),
    [handleMovementArticlesFilter]
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setSearch(value);
    if (value.length > 2) {
      latestSearch.current = value;
      setLoading(true);
      debouncedSearch(value);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredArticles = useMemo(() => {
    return searchFilter(search, articles || [], ["code", "description"])
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [search, articles, page, rowsPerPage]);

  const groupedMA = useMemo(() => {
    if (movement_articles)
      return Object.entries(
        groupBy(
          recursiveSearchFilter(
            search,
            movement_articles,
            ["article_locations"],
            [
              ["article", "code"],
              ["article", "description"],
            ]
          ) || [],
          ["article_locations", "article_id"]
        )
      );
  }, [movement_articles, search]);

  useEffect(() => {
    if (!open) {
      setPage(0);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="product-search"
              label="Buscar artículo"
              fullWidth
              value={search}
              onChange={handleChange}
            />
          </Grid>
          {loading ? (
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ maxHeight: "20rem" }}>
                  {/* Si se muestran los artículos de income */}
                  {operationType === MOVEMENT_TYPES.INCOME.label && articles ? (
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' style={{ minWidth: 170 }}>Codigo</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Descripción</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Unidades por pallet</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Cantidad</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Ubicación</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Cantidad por Contenedor</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Lote</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Orden de Compra</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Fecha de Vencimiento</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredArticles.map((article: Article) => {
                          return (
                            <AddIncomeRow
                              key={article.id}
                              movement={movement}
                              articleInput={article}
                              rowIndex={rowIndex}
                              onMovementArticleAdd={handleAddMovementArticle}
                              locations={locations}
                              stores={stores}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : movement_articles &&
                    movement_articles.length > 0 &&
                    search !== "" ? (
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' style={{ minWidth: 170 }}>Codigo</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Descripción</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Unidades por pallet</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Ubicación</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Lote</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Cantidad</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}>Cantidad por Contenedor</TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}></TableCell>
                          <TableCell align='center' style={{ minWidth: 170 }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupedMA?.map((articleLocation) => (
                          <AddMovementArticleRow
                            key={articleLocation[0]}
                            movement={movement}
                            availableMovementArticles={articleLocation[1]}
                            rowIndex={rowIndex}
                            onMovementArticleAdd={handleAddMovementArticle}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  ) : search !== "" ? (
                    <Alert
                      severity="warning"
                      text="No se encontraron resultados para esta búsqueda"
                    />
                  ) : (
                    <Alert
                      severity="info"
                      text="Ingresa el código o la descripción del producto"
                    />
                  )}
                </TableContainer>
                {operationType === MOVEMENT_TYPES.INCOME.label && articles && (
                  <TablePagination
                    component="div"
                    count={articles?.length || 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"end"} gap={2}>
                <Button
                  variant="outlined"
                  onClick={() => handleOpen()}
                  data-cy={"finish-adding-button"}
                >
                  Cerrar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Modal>
  );
}
