import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';

type Props = {
  pageSize: number;
  totalCount: number;
  onPageChange: (page: number) => void;
  isDisabled?: boolean;
  currentPage: number;
};

export default function PaginationBar({
  pageSize = 10,
  totalCount = 0,
  onPageChange,
  isDisabled,
  currentPage,
}: Props) {
  const theme = useTheme();

  return (
    <Stack spacing={2}>
      <Pagination
        disabled={isDisabled}
        page={currentPage}
        count={Math.ceil(totalCount / pageSize)}
        variant='outlined'
        shape='rounded'
        onChange={(_, value: number) => onPageChange(value)}
        sx={{
          '& .MuiPaginationItem-root': {
            color: theme.palette.primary.main,
          },
          '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          },
        }}
      />
    </Stack>
  );
}
