import { useCallback, useState, useMemo } from "react";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { Box, Paper } from "@mui/material";

import CustomGrid from "components/lists/CustomGrid";
import { gridStructure } from "constants/CustomGridStructure";
import DeleteConfirmationModaL from "components/utils/DeleteConfirmationModal";
import { EnhancedTableToolbar } from "components/lists/EnhancedToolbar";
import AddMovementArticleModal from "./AddMovementArticleModal";
import EditMovementModal from "./EditMovementModal";

import { ENTITY } from "constants/entities";
import { Article, Location, Movement, MovementArticle, Store } from "types";
import { MOVEMENT_TYPES, MovementTypes } from "constants/index";
import useModals from "hooks/useModals";
import { useToast } from "hooks/useToast";

type ArticlesListProps = {
  articles?: Article[];
  movement: Movement;
  onMovementUpdate: <K extends keyof Movement>(
    name: K,
    value: Movement[K]
  ) => void;
  operationType: string;
  locations: Location[];
  hasPalletAutocalculation: boolean;
  hasWeightAutocalculation: boolean;
  stores: Store[];
  rowIndex?: number;
  setRowIndex?: (index: number) => void;
};

export default function ArticlesList({
  articles,
  movement,
  onMovementUpdate,
  operationType,
  locations,
  hasPalletAutocalculation,
  hasWeightAutocalculation,
  stores,
  rowIndex = 0,
  setRowIndex,
}: ArticlesListProps) {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const { addModalOpen, setAddModalOpen, editModalOpen, setEditModalOpen, deleteModalOpen, setDeleteModalOpen } = useModals();
  const { showSnackBar } = useToast();

  const handleSetMovements = useCallback(
    (newMovement: MovementArticle) => {
      if (setRowIndex) {
        setRowIndex(rowIndex + 1);
      }
      onMovementUpdate("articles", [newMovement, ...movement.articles]);

      if (hasWeightAutocalculation) {
        onMovementUpdate("weight", movement.weight + newMovement.weight * newMovement.amount);
      }

      const isPalletIncluded = movement.articles.some(
        (article) => article.article_locations.location_id === newMovement.article_locations.location_id
      );

      if (!isPalletIncluded && hasPalletAutocalculation) {
        onMovementUpdate("pallets", movement.pallets + 1);
      }

      showSnackBar("Se ha agregado el artículo correctamente", "success");
    },
    [hasPalletAutocalculation, hasWeightAutocalculation, movement, onMovementUpdate, rowIndex, setRowIndex, showSnackBar]
  );

  const handleDeleteSelected = useCallback(() => {
    const updatedArticles = movement.articles.filter(
      (movementArticle) => !selected.includes(movementArticle.id)
    );

    selected.forEach((id) => {
      const movementArticle = movement.articles.find(
        (movementArticle) => movementArticle.id === id
      );

      if (movementArticle) {
        const isLocationStillInUse = movement.articles.filter(
          (ma) => ma.article_locations.location_id === movementArticle.article_locations.location_id
        ).length > 1;

        if (!isLocationStillInUse && hasPalletAutocalculation) {
          onMovementUpdate("pallets", movement.pallets - 1);
        }

        if (hasWeightAutocalculation) {
          onMovementUpdate("weight", movement.weight - movementArticle.article_locations.article.weight * movementArticle.amount);
        }
      }
    });

    onMovementUpdate("articles", updatedArticles);
    setSelected([]);
  }, [selected, movement, onMovementUpdate, hasPalletAutocalculation, hasWeightAutocalculation]);

  const handleEdit = useCallback(
    (movementArticleToEdit: MovementArticle) => {
      onMovementUpdate(
        "articles",
        movement.articles.map((movementArticle) =>
          movementArticle.id === movementArticleToEdit.id ? movementArticleToEdit : movementArticle
        )
      );
      setSelected([]);
    },
    [movement.articles, onMovementUpdate]
  );

  const deleteValues = useMemo(
    () =>
      movement.articles
        .filter((movementArticle) => selected.includes(movementArticle.id))
        .map((movementArticle) => movementArticle.description),
    [movement.articles, selected]
  );

  const isToolbarDisabled = useMemo(
    () =>
      !movement.client_id ||
      movement.client_id === 0 ||
      !movement.associated_client ||
      !movement.date ||
      !movement.created_by ||
      !movement.updated_by ||
      !movement.client_document ||
      !movement.numero_remito,
    [movement, operationType]
  );

  const gridFields = useMemo(() => {
    const baseFields = gridStructure[ENTITY.MOVEMENT_ARTICLES].fields;
  
    const movementTypeId = movement.movement_type_id as MovementTypes;
  
    const extraFields = gridStructure[ENTITY.MOVEMENT_ARTICLES].extraFields?.[movementTypeId] || [];
    const allFields = [...baseFields, ...extraFields].sort((a, b) => {
      const orderA = a.order !== undefined ? a.order : Infinity;
      const orderB = b.order !== undefined ? b.order : Infinity;
      return orderA - orderB;
    });
  
    return allFields;
  }, [movement.movement_type_id]);
  
  return (
    <>
      <AddMovementArticleModal
        open={addModalOpen}
        handleOpen={() => setAddModalOpen(!addModalOpen)}
        movement={movement}
        articles={articles}
        rowIndex={rowIndex}
        handleAddMovementArticle={handleSetMovements}
        operationType={operationType}
        stores={stores}
        locations={
          operationType === MOVEMENT_TYPES.INCOME.label && articles
            ? movement.store_id === 0 
              ? locations
              : locations.filter((location) => location.store_id === movement.store_id)
            : locations
        }
      />
      <DeleteConfirmationModaL
        open={deleteModalOpen}
        handleOpen={() => setDeleteModalOpen(!deleteModalOpen)}
        entityName={ENTITY.ARTICLES}
        values={deleteValues}
        handleDelete={handleDeleteSelected}
      />
      {selected.length > 0 && (
        <EditMovementModal
          open={editModalOpen}
          handleOpen={() => setEditModalOpen(!editModalOpen)}
          value={movement.articles.find((c) => c.id === selected[0]) as MovementArticle}
          onEdit={handleEdit}
        />
      )}
      <Box width="100%">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            disabled={isToolbarDisabled}
            handleAddModalOpen={() => setAddModalOpen(!addModalOpen)}
            handleEditModalOpen={() => setEditModalOpen(!editModalOpen)}
            handleDeleteModalOpen={() => setDeleteModalOpen(!deleteModalOpen)}
          />
          <CustomGrid<MovementArticle>
            structure={{ 
              ...gridStructure[ENTITY.MOVEMENT_ARTICLES],
              fields: gridFields
            }}
            rows={movement.articles}
            onEdit={handleEdit}
            selectionModel={selected}
            onRowSelection={setSelected}
          />
        </Paper>
      </Box>
    </>
  );
}
