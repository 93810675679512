import { useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
} from '@mui/material';

import { MovementArticle } from 'types';

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: MovementArticle;
  onEdit: (movement: MovementArticle) => void;
};

export default function EditMovementModal({
  open = true,
  handleOpen,
  value,
  onEdit,
}: Props) {
  const [data, setData] = useState<MovementArticle>(value);

  const handleChange = (name: string, value: string) => {
    name === 'amount'
      ? setData({
          ...data,
          [name]: Number(value),
          subtotal: Number(value),
        })
      : setData({
          ...data,
          [name]: value,
        });
  };

  const onSubmit = (updatedMovement: MovementArticle) => {
    onEdit(updatedMovement);
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Editar Transacción
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id='outlined-required'
              value={data.article_id}
              fullWidth
              defaultValue={data.article_id}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id='outlined-required'
              label='Descripción'
              fullWidth
              value={data.description}
              defaultValue={data.description}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id='outlined-required'
              label='Cantidad'
              fullWidth
              defaultValue={data.amount}
              onChange={e => handleChange('amount', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                handleOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              onClick={() => {
                onSubmit(data);
                handleOpen();
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
