import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  CustomHeadCell,
  SettlementHeaderCells,
  UnitHeaderCell,
  UnitValueCell,
  UnitValueHelperText,
} from "components/utils/LiquidationDetails";
import { SETTLEMENT_TYPES } from "constants/index";
import { arsFormatter, floatFormatter } from "helpers";
import { dateToArgentinianTime } from "helpers/utcFormatter";
import React, { useMemo } from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import {
  ArticleLocationLog,
  LiquidationAggregation,
  LiquidationSettlementType,
} from "types";

export default function Row({
  date,
  logsOfDay,
  liquidation,
  settlementType,
  print,
}: {
  date: string;
  logsOfDay: ArticleLocationLog[];
  liquidation: LiquidationAggregation;
  settlementType: LiquidationSettlementType;
  print: boolean;
}) {
  const [open, setOpen] = React.useState(print);
  const unitsTotal = useMemo(() => {
    switch (settlementType.settlement_type) {
      case SETTLEMENT_TYPES.ABONO:
        return 0;
      case SETTLEMENT_TYPES.PALLETS:
        return liquidation.liquidation_movements
          .map((m) => m.pallets)
          .reduce((a, b) => a + b);
      case SETTLEMENT_TYPES.PESO:
        let weight = 0;
        logsOfDay.forEach((log) => {
          const article_weight = liquidation.liquidation_articles.find(
            (a) => a.article_id === log.article_id
          )?.weight;

          if (article_weight) weight += article_weight * log.quantity;
        });
        return weight;
      case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
        let volume_weight = 0;
        logsOfDay.forEach((log) => {
          const article_volume_weight = liquidation.liquidation_articles.find(
            (a) => a.article_id === log.article_id
          )?.volume_weight;

          if (article_volume_weight) volume_weight += article_volume_weight * log.quantity;
        });
        return volume_weight;
      case SETTLEMENT_TYPES.VOLUMEN:
        let volumen = 0;
        logsOfDay.forEach((log) => {
          const container = liquidation.liquidation_articles.find(
            (a) => a.article_id === log.article_id
          )?.container;

          if (container) {
            const { width, height, long } = container;
            volumen += width * height * long * log.quantity;
          }
        });
        return volumen;
      case SETTLEMENT_TYPES.SUPERFICIE:
        let superficie = 0;
        logsOfDay.forEach((log) => {
          const container = liquidation.liquidation_articles.find(
            (a) => a.article_id === log.article_id
          )?.container;

          if (container) {
            const { width, height } = container;
            superficie += width * height * log.quantity;
          }
        });
        return superficie;
    }
  }, [liquidation, settlementType, logsOfDay]);

  const logValue = (article_id: number) => {
    switch (settlementType.settlement_type) {
      case SETTLEMENT_TYPES.ABONO:
        return 0;

      case SETTLEMENT_TYPES.PALLETS:
        return logsOfDay.filter((lod) => lod.article_id === article_id).length;

      case SETTLEMENT_TYPES.PESO:
        let weight = 0;
        const article_weight = liquidation.liquidation_articles.find(
          (a) => a.article_id === article_id
        )?.weight;

        if (article_weight) {
          logsOfDay
            .filter((log) => log.article_id === article_id)
            .forEach((log) => {
              weight += article_weight * log.quantity;
            });
        }

        return weight;

      case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
        let volume_weight = 0;
        const article_volume_weight = liquidation.liquidation_articles.find(
          (a) => a.article_id === article_id
        )?.volume_weight;

        if (article_volume_weight) {
          logsOfDay
            .filter((log) => log.article_id === article_id)
            .forEach((log) => {
              volume_weight += article_volume_weight * log.quantity;
            });
        }

        return volume_weight;

      case SETTLEMENT_TYPES.VOLUMEN:
        let volumen = 0;
        const container = liquidation.liquidation_articles.find(
          (a) => a.article_id === article_id
        )?.container;

        if (container) {
          const { width, height, long } = container;
          logsOfDay
            .filter((log) => log.article_id === article_id)
            .forEach((log) => {
              volumen += width * height * long * log.quantity;
            });
        }
        return volumen;

      case SETTLEMENT_TYPES.SUPERFICIE:
        let superficie = 0;
        if (container) {
          const { width, height } = container;
          logsOfDay
            .filter((log) => log.article_id === article_id)
            .forEach((log) => {
              superficie += width * height * log.quantity;
            });
        }
        return superficie;
      default:
        return 0;
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {!print && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <BsArrowUp /> : <BsArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{dateToArgentinianTime(date)}</TableCell>
        {settlementType.settlement_type !== SETTLEMENT_TYPES.ABONO && (
          <TableCell>{floatFormatter(unitsTotal || 0)}</TableCell>
        )}
        <TableCell>{arsFormatter(settlementType.subtotal)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalle del Día
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {SettlementHeaderCells(settlementType.settlement_type)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logsOfDay.map((log, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {
                          liquidation.liquidation_articles.find(
                            (article) => article.article_id === log.article_id
                          )?.code
                        }
                      </TableCell>
                      <TableCell>
                        {
                          liquidation.liquidation_articles.find(
                            (article) => article.article_id === log.article_id
                          )?.description
                        }
                      </TableCell>
                      <TableCell>{log.quantity}</TableCell>

                      {settlementType.settlement_type !== SETTLEMENT_TYPES.ABONO &&
                        UnitValueCell(
                          settlementType.settlement_type,
                          liquidation,
                          undefined,
                          log
                        )}

                      {settlementType.settlement_type !== SETTLEMENT_TYPES.ABONO && (
                        <>
                          <TableCell>{floatFormatter(logValue(log.article_id))}</TableCell>
                          <TableCell>{arsFormatter(settlementType.calculation)}</TableCell>
                          <TableCell>
                            {arsFormatter(
                              logValue(log.article_id) * settlementType.calculation
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
