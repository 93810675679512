import { Checkbox } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { MovementTypes } from 'constants/index';

type CustomGridColDef = GridColDef & {
  order?: number;
};

type CustomGridProps = {
  name: string;
  labelName: string;
  fields: CustomGridColDef[];
  extraFields?: { [key in MovementTypes]?: CustomGridColDef[] };
};

export const gridStructure: { [key: string]: CustomGridProps } = {
  users: {
    name: 'users',
    labelName: 'Usuarios',
    fields: [
      {
        field: 'first_name',
        headerName: 'Nombre',
        headerAlign: 'center',
        type: 'string',
        flex: 1,
      },
      {
        field: 'last_name',
        headerName: 'Apellido',
        headerAlign: 'center',
        type: 'string',
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email',
        headerAlign: 'center',
        type: 'string',
        flex: 1,
      },
      {
        field: 'role',
        headerName: 'Rol',
        headerAlign: 'center',
        type: 'string',
        flex: 1,
      },
      {
        field: 'is_active',
        headerName: 'Activo',
        headerAlign: 'center',
        type: 'boolean',
        flex: 1,
        editable: false,
        renderCell: params => (
          <Checkbox
            checked={params.value}
            onClick={() => {
              params.value = !params.value;
            }}
            disabled
          />
        ),
      },
    ],
  },
  clients: {
    name: 'clients',
    labelName: 'Clientes',
    fields: [
      {
        field: 'code',
        headerName: 'Codigo',
        headerAlign: 'center',
        type: 'string',
      },
      {
        field: 'razon_social',
        headerName: 'Razón social',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'address',
        headerName: 'Dirección',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'cuit',
        headerName: 'CUIT',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'contact',
        headerName: 'Contacto',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'observations',
        headerName: 'Observaciones',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'is_active',
        headerName: 'Activo',
        headerAlign: 'center',
        type: 'boolean',
        flex: 1,
        editable: false,
        renderCell: params => (
          <Checkbox checked={params.value} disabled />
        ),
      },
    ],
  },
  associated_clients: {
    name: 'associated_clients',
    labelName: 'Clientes asociados',
    fields: [
      {
        field: 'razon_social',
        headerName: 'Razón social',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'client',
        valueGetter: params => {
          return params.row.client?.razon_social;
        },
        headerName: 'Asociado a',
        headerAlign: 'center',
        type: 'string',
        editable: false,
        flex: 1,
      },
      {
        field: 'address',
        headerName: 'Dirección',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'cuit',
        headerName: 'CUIT',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'contact',
        headerName: 'Contacto',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'is_active',
        headerName: 'Activo',
        headerAlign: 'center',
        type: 'boolean',
        flex: 1,
        editable: false,
        renderCell: params => (
          <Checkbox checked={params.value} disabled />
        ),
      },
    ],
  },
  articles: {
    name: 'articles',
    labelName: 'Artículos',
    fields: [
      {
        field: 'code',
        headerName: 'Codigo',
        headerAlign: 'center',
        type: 'string',
        editable: false,
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Descripción',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'amount',
        headerName: 'Stock',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
      {
        field: 'weight',
        headerName: 'Peso',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
      {
        field: 'volume_weight',
        headerName: 'Peso Volumétrico',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
      {
        field: 'amount_per_container',
        valueGetter: params => {
          return params.row.amount_per_container;
        },
        headerName: 'Cantidad por Contenedor',
        headerAlign: 'center',
        type: 'string',
        editable: false,
        flex: 1,
      },
      {
        field: 'unit',
        valueGetter: params => {
          return params.row.unit.description;
        },
        headerName: 'Unidad',
        headerAlign: 'center',
        type: 'number',
        editable: false,
        flex: 1,
      },
      {
        field: 'units_per_pallet',
        valueGetter: params => {
          return params.row.units_per_pallet;
        },
        headerName: 'Unidades por pallet',
        headerAlign: 'center',
        type: 'number',
        editable: false,
        flex: 1,
      },
      {
        field: 'container',
        valueGetter: params => {
          return params.row.container.description;
        },
        headerName: 'Contenedor',
        headerAlign: 'center',
        type: 'string',
        editable: false,
        flex: 1,
      },
      {
        field: 'client',
        valueGetter: params => {
          return params.row.client.razon_social;
        },
        headerName: 'Cliente',
        headerAlign: 'center',
        type: 'string',
        editable: false,
        flex: 1,
      },
    ],
  },
  containers: {
    name: 'containers',
    labelName: 'Contenedores',
    fields: [
      {
        field: 'description',
        headerName: 'Descripción',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'width',
        headerName: 'Ancho',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
      {
        field: 'height',
        headerName: 'Alto',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
      {
        field: 'long',
        headerName: 'Largo',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
      {
        field: 'is_active',
        headerName: 'Activo',
        headerAlign: 'center',
        type: 'boolean',
        flex: 1,
        editable: false,
        renderCell: params => <Checkbox checked={params.value} disabled/>,
      },
    ],
  },
  units: {
    name: 'units',
    labelName: 'Unidades',
    fields: [
      {
        field: 'description',
        headerName: 'Descripción',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'is_active',
        headerName: 'Activo',
        headerAlign: 'center',
        type: 'boolean',
        flex: 1,
        editable: false,
        renderCell: params => <Checkbox checked={params.value} disabled/>,
      },
    ],
  },
  client_settlement_types: {
    name: 'client_settlement_types',
    labelName: 'Preferencias de Liquidacion',
    fields: [
      {
        field: 'Tipo de liquidación',
        headerName: 'Tipo de liquidación',
        headerAlign: 'center',
        maxWidth: 10,
        type: 'string',
        editable: false,
      },
      {
        field: 'is_default',
        headerName: 'Default',
        headerAlign: 'center',
        type: 'boolean',
        editable: true,
        flex: 1,
        renderCell: params => (
          <Checkbox checked={params.value} disabled />
        ),
      },
      {
        field: 'Precio',
        headerName: 'Precio',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
    ],
  },
  stores: {
    name: 'stores',
    labelName: 'Almacenes',
    fields: [
      {
        field: 'code',
        headerName: 'Código',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Descripción',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'is_active',
        headerName: 'Activo',
        headerAlign: 'center',
        type: 'boolean',
        flex: 1,
        editable: false,
        renderCell: params => (
          <Checkbox checked={params.value} disabled />
        ),
      },
    ],
  },
  additional_items: {
    name: 'additional_items',
    labelName: 'Artículos adicionales',
    fields: [
      {
        field: 'description',
        headerName: 'Descripción',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        flex: 1,
      },
      {
        field: 'client',
        headerName: 'Cliente',
        valueGetter: params => {
          if (params.row.client_id === null) {
            return 'TODOS';
          }
          return params.row.client.razon_social;
        },
        headerAlign: 'center',
        type: 'string',
        editable: false,
        flex: 1,
      },
      {
        field: 'price',
        headerName: 'Precio',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
    ],
  },
  movement_articles: {
    name: 'movement_articles',
    labelName: 'MovementArticles',
    fields: [
      {
        field: 'code',
        headerName: 'Código',
        headerAlign: 'center',
        type: 'string',
        valueGetter: params => {
          return params.row.article_locations.article.code;
        },
        editable: false,
        flex: 1,
        order: 1,
      },
      {
        field: 'amount',
        headerName: 'Cantidad',
        headerAlign: 'center',
        editable: true,
        type: 'number',
        flex: 1,
        order: 2,
      },
      {
        field: 'description',
        headerName: 'Descripción',
        headerAlign: 'center',
        flex: 1,
        editable: false,
      },
      {
        field: 'amount_per_container',
        headerName: 'Cantidad por Contenedor',
        headerAlign: 'center',
        editable: true,
        type: 'number',
        valueGetter: params => {
          return params.row.amount_per_container;
        },
        flex: 1,
      },
      {
        field: 'amount_per_pallets',
        headerName: 'Pallets totales',
        headerAlign: 'center',
        type: 'number',
        valueGetter: params => {
          const amount = params.row.amount;
          const unitsPerPallet = params.row.article_locations.article.units_per_pallet;
          return unitsPerPallet ? Math.ceil(amount / unitsPerPallet) : 0;
        },
        editable: false,
        flex: 1,
      },
      {
        field: 'unit',
        headerName: 'UM',
        headerAlign: 'center',
        editable: false,
        flex: 1,
        type: 'string',
      },
      {
        field: 'container',
        headerName: 'Contenedor',
        headerAlign: 'center',
        editable: false,
        flex: 1,
      },
      {
        field: 'lote',
        headerName: 'Lote',
        headerAlign: 'center',
        editable: true,
        flex: 1,
      },
      {
        field: 'subtotal_kg',
        headerName: 'Subtotal (Kg)',
        headerAlign: 'center',
        flex: 1,
        editable: false,
      },
      {
        field: 'subtotal',
        headerName: 'Subtotal (Un)',
        valueGetter: params => {
          return (
            params.row.subtotal +
            ` ${params.row.unit}`
          );
        },
        headerAlign: 'center',
        editable: false,
        flex: 1,
      },
    ],
    extraFields: {
      [MovementTypes.Discharge]: [
        {
          field: 'remaining_stock',
          headerName: 'Cantidad Restante',
          headerAlign: 'center',
          flex: 1,
          editable: false,
          valueGetter: params => {
            const { stock, reserved } = params.row.article_locations;
            const { amount } = params.row;
            return stock - reserved - amount;
          },
          order: 3,
        },
      ],
      [MovementTypes.Preparation]: [
        {
          field: 'remaining_stock',
          headerName: 'Cantidad Restante',
          headerAlign: 'center',
          flex: 1,
          editable: false,
          valueGetter: params => {
            const { stock, reserved } = params.row.article_locations;
            const { amount } = params.row;
            return stock - reserved - amount;
          },
          order: 3,
        },
      ],
      [MovementTypes.Income]: [],
      [MovementTypes.Transfer]: [],
    },
  },
  movement_additional_items: {
    name: 'movement_additional_items',
    labelName: 'MovementAdditionalItems',
    fields: [
      {
        field: 'additional_item_id',
        headerName: 'Código',
        headerAlign: 'center',
        editable: false,
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Descripción',
        headerAlign: 'center',
        flex: 1,
        editable: false,
      },
      {
        field: 'amount',
        headerName: 'Cantidad',
        headerAlign: 'center',
        flex: 1,
        editable: false,
      },
    ],
  },
  locations: {
    name: 'locations',
    labelName: 'Ubicaciones',
    fields: [
      {
        field: 'code',
        headerName: 'Código',
        headerAlign: 'center',
        type: 'string',
        editable: false,
        flex: 1,
      },
      {
        field: 'store',
        valueGetter: params => {
          return params.row.store.description;
        },
        headerName: 'Almacen',
        headerAlign: 'center',
        type: 'string',
        editable: false,
        flex: 1,
      },
      {
        field: 'width',
        headerName: 'Ancho',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
      {
        field: 'height',
        headerName: 'Alto',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
      {
        field: 'long',
        headerName: 'Largo',
        headerAlign: 'center',
        type: 'number',
        editable: true,
        flex: 1,
      },
    ],
  },
};
