import { useEffect, useMemo, useState } from 'react';

import CustomGrid from '../CustomGrid';
import { gridStructure } from 'constants/CustomGridStructure';

import { Box, Paper } from '@mui/material';

import { GridRowSelectionModel } from '@mui/x-data-grid';
import { EnhancedTableToolbar } from '../EnhancedToolbar';

import { User } from 'types';
import { USERS_URL as BASE_URL } from 'constants/urls';

import { ENTITY } from 'constants/entities';

import Loader from '../Loader';
import useModals from 'hooks/useModals';
import useCrud from 'hooks/useCrud';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import { useSession } from 'providers/SessionProvider';
import { STATUS_CODES } from 'constants/index';
import { useToast } from 'hooks/useToast';
import { useFilter } from 'hooks/useFilter';

export default function UserList() {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const {
    addModalOpen,
    setAddModalOpen,
    editModalOpen,
    setEditModalOpen,
    deleteModalOpen,
    setDeleteModalOpen,
  } = useModals();

  const { showSnackBar } = useToast();

  const {
    loading,
    firstLoad,
    updateData: updateUser,
    createData: createUser,
    showToast,
    toastMessage,
    toastSeverity,
    setShowToast,
  } = useCrud<User>(BASE_URL);

  const { users, clients, addSessionData, updateSessionData } = useSession();

  const { filterText, setFilterText, filteredData: filteredUsers } = useFilter<User>({
    data: users,
    fields: gridStructure[ENTITY.USERS].fields,
  });

  // const handleDeleteSelected = async () => {
  //   const failedDeletes: number[] = [];

  //   for (const id of selected) {
  //     const reponse = await deleteUser(id, true, true);
  //     if (reponse.status !== STATUS_CODES.OK) {
  //       failedDeletes.push(Number(id));
  //     }
  //   }
  //   deleteSessionData(
  //     ENTITY.USERS,
  //     selected
  //       .filter(item => !failedDeletes.includes(Number(item)))
  //       .map(item => item.toString())
  //   );

  //   setSelected([]);
  // };

  const handleCreate = async (newUser: User) => {
    const response = await createUser(newUser, true, true);
    if (response.status === STATUS_CODES.OK) {
      addSessionData(ENTITY.USERS, response.data as User);
    }
  };

  const handleEdit = async (userToEdit: User) => {
    const response = await updateUser(userToEdit.id, userToEdit, true, true);
    if (response.status === STATUS_CODES.OK) {
      updateSessionData(ENTITY.USERS, userToEdit);
    }
    setSelected([]);
  };

  useEffect(() => {
    if (showToast) {
      showSnackBar(toastMessage, toastSeverity);
      setShowToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  return (
    <>
      {firstLoad ? (
        <Loader isFirstLoad={firstLoad} isLoading={loading} />
      ) : (
        <>
          <Loader isFirstLoad={firstLoad} isLoading={loading} />
          <AddUserModal
            open={addModalOpen}
            handleOpen={() => setAddModalOpen(!addModalOpen)}
            onCreate={handleCreate}
            clients={clients}
            users={users}
          />
          {/* <DeleteConfirmationModal
            open={deleteModalOpen}
            handleOpen={() => setDeleteModalOpen(!deleteModalOpen)}
            entityName={ENTITY.USERS}
            values={selected.map(item => {
              const value = users.find(user => user.id === item)?.first_name;
              if (value) return value;
              return '';
            })}
            handleDelete={handleDeleteSelected}
          /> */}
          {selected.length > 0 ? (
            <EditUserModal
              open={editModalOpen}
              handleOpen={() => setEditModalOpen(!editModalOpen)}
              value={users.find(user => user.id === selected[0]) as User}
              onEdit={handleEdit}
              clients={clients}
            />
          ) : null}
          <Box sx={{ width: '99%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                handleAddModalOpen={() => setAddModalOpen(!addModalOpen)}
                handleEditModalOpen={() => setEditModalOpen(!editModalOpen)}
                handleDeleteModalOpen={() =>
                  setDeleteModalOpen(!deleteModalOpen)
                }
                onFilterChange={setFilterText}
              />
              <CustomGrid
                structure={gridStructure[ENTITY.USERS]}
                rows={filteredUsers}
                onEdit={handleEdit}
                selectionModel={selected}
                onRowSelection={setSelected}
              />
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}
