import { Dispatch, SetStateAction } from 'react';

import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { SETTLEMENT_TYPES_URL } from 'constants/urls';
import useCrud from 'hooks/useCrud';
import { Liquidation, SettlementType } from 'types';
import { SelectedLiquidationTypes } from './LiquidationCreate';
import CustomNumberInput from 'components/CustomNumberInput';
import { formatSettlementTypeDescription } from 'helpers';

type Props = {
  liquidation: Liquidation;
  liquidationTypesLoading: boolean;
  selectedLiquidationTypes: SelectedLiquidationTypes;
  setSelectedLiquidationTypes: Dispatch<
    SetStateAction<SelectedLiquidationTypes>
  >;
};

export default function LiquidationsTypesSelectors({
  liquidation,
  liquidationTypesLoading,
  selectedLiquidationTypes,
  setSelectedLiquidationTypes,
}: Props) {
  const { data: settlementTypes } =
    useCrud<SettlementType>(SETTLEMENT_TYPES_URL);

  return (
    <Grid container component={Paper} elevation={4} p={2}>
      <Grid item xs={12} height='3rem'>
        <Typography variant='h6'>
          Seleccione los tipos de liquidación
        </Typography>
        {liquidationTypesLoading && <LinearProgress />}
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item md={4} sm={6} xs={12}>
          <Grid container columnSpacing={1}>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel id='income-label'>Ingreso</InputLabel>
                <Select
                  labelId='income-label'
                  label='Ingreso'
                  disabled={!liquidation.client_id || liquidationTypesLoading}
                  onChange={(e: any) => {
                    setSelectedLiquidationTypes({
                      ...selectedLiquidationTypes,
                      income: {
                        description: e.target.value,
                        calculation: '',
                      },
                    });
                  }}
                  value={selectedLiquidationTypes.income.description}
                >
                  {settlementTypes.map((st, index) => (
                    <MenuItem key={index} value={st.description}>
                      {formatSettlementTypeDescription(st.description)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <CustomNumberInput
                label='Ingreso'
                disabled={
                  !liquidation.client_id ||
                  !selectedLiquidationTypes.income.description ||
                  liquidationTypesLoading
                }
                onChange={(e: any) => {
                  setSelectedLiquidationTypes({
                    ...selectedLiquidationTypes,
                    income: {
                      ...selectedLiquidationTypes.income,
                      calculation: e.target.value,
                    },
                  });
                }}
                value={selectedLiquidationTypes.income.calculation}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Grid container columnSpacing={1}>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel id='stay-label'>Estadía</InputLabel>
                <Select
                  labelId='stay-label'
                  label='Estadía'
                  disabled={!liquidation.client_id || liquidationTypesLoading}
                  onChange={(e: any) => {
                    setSelectedLiquidationTypes({
                      ...selectedLiquidationTypes,
                      stay: {
                        description: e.target.value,
                        calculation: '',
                      },
                    });
                  }}
                  value={selectedLiquidationTypes.stay.description}
                >
                  {settlementTypes.map((st, index) => (
                    <MenuItem key={index} value={st.description}>
                      {formatSettlementTypeDescription(st.description)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <CustomNumberInput
                label='Estadia'
                disabled={
                  !liquidation.client_id ||
                  !selectedLiquidationTypes.stay.description ||
                  liquidationTypesLoading
                }
                onChange={(e: any) => {
                  setSelectedLiquidationTypes({
                    ...selectedLiquidationTypes,
                    stay: {
                      ...selectedLiquidationTypes.stay,
                      calculation: e.target.value,
                    },
                  });
                }}
                value={selectedLiquidationTypes.stay.calculation}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Grid container columnSpacing={1}>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel id='discharge-label'>Egreso</InputLabel>
                <Select
                  labelId='discharge-label'
                  label='Egreso'
                  disabled={!liquidation.client_id || liquidationTypesLoading}
                  onChange={(e: any) => {
                    setSelectedLiquidationTypes({
                      ...selectedLiquidationTypes,
                      discharge: {
                        description: e.target.value,
                        calculation: '',
                      },
                    });
                  }}
                  value={selectedLiquidationTypes.discharge.description}
                >
                  {settlementTypes.map((st, index) => (
                    <MenuItem key={index} value={st.description}>
                      {formatSettlementTypeDescription(st.description)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <CustomNumberInput
                label='Egreso'
                disabled={
                  !liquidation.client_id ||
                  !selectedLiquidationTypes.discharge.description ||
                  liquidationTypesLoading
                }
                onChange={(e: any) => {
                  setSelectedLiquidationTypes({
                    ...selectedLiquidationTypes,
                    discharge: {
                      ...selectedLiquidationTypes.discharge,
                      calculation: e.target.value,
                    },
                  });
                }}
                value={selectedLiquidationTypes.discharge.calculation}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
