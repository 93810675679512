import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  AlertColor,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ROUTES } from "constants/index";
import { ImageContainer } from "../components/layout/DashboardLayout";

import { ColorModeContext } from "App";
import SHCLogo from "assets/imgs/shc-logo.png";
import { useUser } from "hooks/useUser";
import { useSession } from "providers/SessionProvider";
import { MdDarkMode, MdLightMode, MdLogin } from "react-icons/md";

type LoginUser = {
  email: string;
  password: string;
};

type LoginError = {
  open: boolean;
  severity: AlertColor | undefined;
  message: string | undefined;
};

export default function LoginPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLoggedIn } = useUser();
  const { logIn } = useSession();

  const colorModeContext = useContext(ColorModeContext);

  const errorDefaultValue = {
    open: false,
    severity: undefined,
    message: undefined,
  };

  const [user, setUser] = useState<LoginUser>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<LoginError>(errorDefaultValue);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (name: string, value: string) => {
    setError(errorDefaultValue);
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setError(errorDefaultValue);
    setLoading(true);
    if (user.email.length === 0 || user.password.length === 0) {
      setError({
        open: true,
        severity: "info",
        message: "Ingrese un usuario y una contraseña",
      });
    } else {
      const success = await logIn(user.email, user.password);
      if (success) {
        navigate(ROUTES.HOME);
      } else {
        setError({
          open: true,
          severity: "error",
          message: "Las credenciales son inválidas, intente nuevamente",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn() !== undefined && isLoggedIn()) {
      navigate(ROUTES.HOME);
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      <Box position="absolute" right={0} m={2}>
        <Tooltip
          title={`Cambiar a modo ${
            theme.palette.mode === "dark" ? "claro" : "oscuro"
          }`}
        >
          <IconButton
            onClick={() => colorModeContext.toggleColorMode()}
            size="small"
            sx={{
              boxShadow:
                "-2px -2px 14px rgba(0, 0, 0, .1), -2px -2px 10px rgba(0, 0, 0, .1), 2px 2px 8px rgba(0, 0, 0, .075),2px 2px 10px rgba(0, 0, 0, .15);",
            }}
          >
            {theme.palette.mode === "dark" ? <MdLightMode /> : <MdDarkMode />}
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: { xs: "90vw", sm: "30rem" },
            padding: "1rem",
            marginX: "1.5rem",
          }}
        >
          <Box>
            <Box py={2}>
              <ImageContainer>
                <img src={SHCLogo} alt="Logo SHC" />
              </ImageContainer>
              <Typography variant="h5" textAlign="center">
                Iniciar Sesión
              </Typography>
            </Box>
            <Box
              component={FormControl}
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                data-cy="username"
                type="email"
                value={user.email}
                placeholder="Email"
                disabled={loading}
                onChange={(e) => handleChange("email", e.target.value)}
                sx={{ width: { xs: "100%", sm: "75%" } }}
              />
              <TextField
                data-cy="password"
                type="password"
                value={user.password}
                placeholder="Contraseña"
                disabled={loading}
                onChange={(e) => handleChange("password", e.target.value)}
                sx={{ width: { xs: "100%", sm: "75%" } }}
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="5rem"
                width="100%"
              >
                {error.open && (
                  <Alert sx={{ width: "100%" }} severity={error.severity}>
                    {error.message}
                  </Alert>
                )}
                {loading && <CircularProgress />}
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width="100%"
            alignItems="center"
            paddingY="0.5rem"
            sx={{
              button: {
                width: { xs: "60%", sm: "12rem" },
              },
            }}
          >
            <Button
              data-cy="login-button"
              startIcon={<MdLogin />}
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
            >
              Iniciar sesión
            </Button>
            {/* <Button
              startIcon={<MdPersonAdd />}
              variant='contained'
              color='primary'
              disabled={loading}
              onClick={() => navigate(ROUTES.REGISTER)}
            >
              Registrarse
            </Button> */}
          </Box>
        </Paper>
      </Box>
      <Box position="absolute" right={0} bottom={0} m={2}>
        <Typography variant="body2">v1.0.7</Typography>
      </Box>
    </>
  );
}
