import { DateOrTimeView } from "@mui/x-date-pickers";
import { Article, Movement } from "types";

export const STATUS_CODES = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
};

export const USER_STORAGE_KEY = "shc-user";

export const ROUTES = {
  INDEX: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  HOME: "/home",
  INCOME: "/income",
  DISCHARGE: "/discharges",
  ADDITIONALS: "/additionals",
  TRANSFER: "/transfer",
  OPERATIONS: "/operations",
  SALES: "/sales",
  SALES_DETAIL: "/sales/:id",
  LISTS: "/lists",
  CLIENTS: "/lists/clients",
  ASSOCIADED_CLIENTS: "/lists/associated-clients",
  PRODUCTS: "/lists/products",
  STORES: "/lists/stores",
  LOCATIONS: "/lists/locations",
  ADDITIONAL_ITEMS: "/lists/additional-items",
  SETTLEMENT_TYPES: "/lists/settlement-types",
  UNITS: "/lists/units",
  CONTAINERS: "/lists/containers",
  USER_SETTINGS: "/user-settings",
  USERS: "/lists/users",
  CONFIGS: "/configs",
  CHANGE_PASSWORD: "/change-password",
};

export enum Roles {
  ADMIN = "ADMIN",
  SUPERVISOR = "SUPERVISOR",
  OPERATOR = "OPERATOR",
  CLIENT = "CLIENT",
}

export const ROUTES_PERMISSIONS: Record<string, string[]> = {
  [ROUTES.HOME]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR, Roles.CLIENT],
  [ROUTES.INCOME]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.DISCHARGE]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.ADDITIONALS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.TRANSFER]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.OPERATIONS]: [
    Roles.ADMIN,
    Roles.SUPERVISOR,
    Roles.OPERATOR,
    Roles.CLIENT,
  ],
  [ROUTES.SALES]: [Roles.ADMIN, Roles.SUPERVISOR],
  [ROUTES.SALES_DETAIL]: [Roles.ADMIN, Roles.SUPERVISOR],
  [ROUTES.LISTS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.CLIENTS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.ASSOCIADED_CLIENTS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.PRODUCTS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.STORES]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.LOCATIONS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.UNITS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.CONTAINERS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.ADDITIONAL_ITEMS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.SETTLEMENT_TYPES]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.USERS]: [Roles.ADMIN, Roles.SUPERVISOR],
  [ROUTES.USER_SETTINGS]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
  [ROUTES.CONFIGS]: [Roles.ADMIN, Roles.SUPERVISOR],
  [ROUTES.CHANGE_PASSWORD]: [Roles.ADMIN, Roles.SUPERVISOR, Roles.OPERATOR],
};

export const DATETIME_PICKERS_VIEWS: DateOrTimeView[] | undefined = [
  "year",
  "month",
  "day",
  "hours",
  "minutes",
  "seconds",
];

export enum MovementTypes {
  Default = 1,
  Income = 1,
  Discharge = 2,
  Additional = 3,
  Preparation = 4,
  Transfer = 5,
}

export enum Order {
  Ascending = "asc",
  Descending = "desc",
}

export const MOVEMENT_TYPES = {
  DEFAULT: { id: MovementTypes.Default, label: "" },
  INCOME: { id: MovementTypes.Income, label: "ingreso" },
  DISCHARGE: { id: MovementTypes.Discharge, label: "egreso" },
  ADDITIONAL: { id: MovementTypes.Additional, label: "adicional" },
  PREPARATION: { id: MovementTypes.Preparation, label: "preparación" },
  TRANSFER: { id: MovementTypes.Transfer, label: "transferencia" },
};

export const defaultMovement: Movement = {
  movement_type_id: MovementTypes.Default,
  date: "",
  client_id: 0,
  associated_client: "",
  client_document: "",
  numero_remito: "",
  pallets: 0,
  observation: "",
  created_by: 0,
  updated_by: 0,
  is_billable: false,
  store_id: null,
  weight: 0,
  articles: [],
  additional_items: [],
  containers: [],
};

export const DATE_VIEWS = ["year", "month", "day"] as const;

export const SETTLEMENT_TYPES = {
  ABONO: "abono",
  PALLETS: "pallets",
  PESO: "peso",
  PESO_VOLUMETRICO: "peso_volumetrico",
  VOLUMEN: "volumen",
  SUPERFICIE: "superficie",
};

export const SETTLEMENT_TYPE_CATEGORIES = {
  INCOME: "INGRESO",
  STAY: "ESTADIA",
  DISCHARGE: "EGRESO",
};

export const defaultEditArticleValue: Article = {
  id: 0,
  code: "",
  client: {
    id: 0,
    razon_social: "",
    code: "",
    address: "",
    cuit: "",
    contact: "",
    observations: "",
    is_active: false,
  },
  client_id: 0,
  container: {
    id: 0,
    description: "",
    width: 0,
    height: 0,
    long: 0,
    is_active: false,
  },
  container_id: 0,
  description: "",
  weight: 0,
  volume_weight: 0,
  amount: 0,
  amount_per_container: 0,
  unit: {
    id: 0,
    description: "",
    is_active: false,
  },
  unit_id: 0,
};

export const EXCEL_SHEETS = {
  CABECERA: "Cabecera",
  ARTICULOS: "Articulos",
  ADICIONALES: "Items Adicionales",
};

export const EXCEL_HEADERS = {
  CABECERA: {
    REMITO: "Remito",
    FECHA: "Fecha",
    CLIENTE: "Cliente",
    CLIENTE_ASOCIADO: "Cliente Asociado",
    CREATED_BY: "Usuario",
    UPDATED_BY: "Preparó",
    CODIGO_ALMACEN: "Almacen",
    OBSERVACIONES: "Observaciones",
    PALLETS: "Pallets",
    PESO: "Peso",
  },
  ARTICULOS: {
    REMITO: "Remito",
    CODIGO_ARTICULO: "Articulo",
    CANTIDAD: "Cantidad",
    LOTE: "Lote",
    FECHA_VENCIMIENTO: "Fecha de Vencimiento",
    ORDEN_DE_COMPRA: "Orden de Compra",
    CANTIDAD_POR_CONTENEDOR: "Cantidad por contenedor",
    UBICACION: "Ubicacion",
    SUBTOTAL: "Subtotal",
  },
  ADICIONALES: {
    REMITO: "Remito",
    DESCRIPCION: "Descripcion",
    CANTIDAD: "Cantidad",
  },
};

export const ARGENTINA_TIME_ZONE = "America/Argentina/Buenos_Aires";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT = "DD/MM/YYYY";
