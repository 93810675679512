import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Autocomplete, Button, FormControl, Grid, TextField } from "@mui/material";
import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import PageLayout from "components/layout/PageLayout";
import Loader from "components/lists/Loader";
import AdditionalItemsList from "components/Operations/AdditionalItemsList";
import useCrud from "hooks/useCrud";
import { useToast } from "hooks/useToast";
import { useSession } from "providers/SessionProvider";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { AdditionalItem, AssociatedClient, Movement } from "types";
import { MOVEMENTS_URL } from "constants/urls";
import OperationPrintDetails from "components/Prints/Operations/OperationPrintDetails";
import OperationPrintHeaderDetails from "components/Prints/Operations/OperationPrintHeaderDetails";
import { PrintLayout } from "components/Prints/PrintLayout";
import DateTimePickerActionBar from "components/utils/DatePickerActionBar";
import { dateISOFormat } from "helpers";
import { DATE_VIEWS, defaultMovement, MovementTypes, STATUS_CODES } from "../constants";
import CustomNumberInput from "components/CustomNumberInput";

export default function AdditionalsPage() {
  const printRef = useRef(null);
  const navigate = useNavigate();
  const { showSnackBar } = useToast();

  const [movement, setMovement] = useState<Movement>({
    ...defaultMovement,
    movement_type_id: MovementTypes.Additional,
  });
  const [movementToPrint, setMovementToPrint] = useState<Movement | undefined>();
  const [movementLoading, setMovementLoading] = useState<boolean>(false);

  const { createData: createMovement, firstLoad: onMovementFirstLoad, fetchData: getMovement } = useCrud(MOVEMENTS_URL, {
    disableAutoFetch: true,
  });

  const { userData, stores, clients, associatedClients, additionalItems, users } = useSession();

  const [selectedClient, setSelectedClient] = useState<{ id: number; label: string } | null>(null);
  const [clientAssociatedClients, setClientAssociatedClients] = useState<AssociatedClient[]>([]);
  const [clientAdditionalItems, setClientAdditionalItems] = useState<AdditionalItem[]>([]);

  const handleChange = useCallback((name: string, value: any) => {
    setMovement((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setMovementLoading(true);
      try {
        const response = await createMovement([movement], true);
        if (response.status === STATUS_CODES.OK) {
          const aggregatedMovement = await getMovement(`${response.data[0].id}`);
          setMovementToPrint(aggregatedMovement.data[0]);
        } else {
          showSnackBar(
            response.status === STATUS_CODES.BAD_REQUEST
              ? "El número de remito ya se encuentra registrado, por favor modifiquelo e intente nuevamente."
              : "Hubo un error con la solicitud, por favor intente nuevamente",
            "error"
          );
          setMovementLoading(false);
        }
      } catch (error) {
        showSnackBar("Hubo un error inesperado, por favor intente nuevamente", "error");
        setMovementLoading(false);
      }
    },
    [createMovement, getMovement, movement, showSnackBar]
  );

  useEffect(() => {
    if (userData) {
      handleChange("created_by", userData.id);
    }
  }, [userData, handleChange]);

  useEffect(() => {
    setClientAssociatedClients(
      associatedClients.filter((ac) => ac.client?.id === movement.client_id)
    );
    setClientAdditionalItems(
      additionalItems.filter((ai) => ai.client?.id === movement.client_id || !ai.client_id)
    );
  }, [movement.client_id, associatedClients, additionalItems]);

  useEffect(() => {
    if (movementToPrint) {
      handlePrint();
    }
  }, [movementToPrint]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      navigate("/operations");
      showSnackBar("Se ha creado el adicional correctamente", "success");
      setMovementLoading(false);
    },
  });

  const userOptions = useMemo(
    () => users.map((u) => ({ id: u.id, label: `${u.first_name} ${u.last_name}` })),
    [users]
  );

  const clientOptions = useMemo(
    () => clients.map((c) => ({ id: c.id, label: c.razon_social })),
    [clients]
  );

  const storeOptions = useMemo(
    () => stores.filter((s) => s.is_active).map((s) => ({ id: s.id, label: s.description })),
    [stores]
  );

  return (
    <PageLayout pageTitle="Adicionales" pageDesc="Orden de adicionales por cliente">
      <PrintLayout
        ref={printRef}
        headerInfo={<OperationPrintHeaderDetails movement={movement} stores={stores} />}
      >
        <OperationPrintDetails movement={movement} client={selectedClient?.label || "-"} />
      </PrintLayout>
      <form onSubmit={handleSubmit}>
        {movementLoading ? (
          <Loader isLoading={movementLoading} isFirstLoad={onMovementFirstLoad} />
        ) : (
          <Grid container columnSpacing={1}>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="user"
                  autoComplete
                  value={userOptions.find((u) => u.id === movement.created_by) || null}
                  options={userOptions}
                  onChange={(e, value) => handleChange("created_by", value?.id)}
                  renderInput={(params) => <TextField {...params} required label="Usuario" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="author"
                  autoComplete
                  value={userOptions.find((u) => u.id === movement.updated_by) || null}
                  options={userOptions}
                  onChange={(e, value) => handleChange("updated_by", value?.id)}
                  renderInput={(params) => <TextField {...params} required label="Preparó" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <DateTimePicker
                  disableFuture
                  label="Fecha"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slots={{
                    actionBar: DateTimePickerActionBar,
                  }}
                  views={DATE_VIEWS}
                  value={movement.date ? new Date(movement.date) : null}
                  onChange={(value) => {
                    const formattedDate = value ? dateISOFormat(value.toISOString()) : null;
                    handleChange("date", formattedDate);
                    handleChange("settlement_date", formattedDate);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="client"
                  autoComplete
                  value={clientOptions.find((c) => c.id === movement.client_id) || null}
                  options={clientOptions}
                  onChange={(e, value) => {
                    setSelectedClient(value);
                    handleChange("client_id", value?.id);
                    handleChange("additional_items", []);
                    handleChange("associated_client", "");
                  }}
                  renderInput={(params) => <TextField {...params} required label="Cliente" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="associated-client"
                  options={clientAssociatedClients.map((ac) => ({ label: ac.razon_social }))}
                  value={{ label: movement.associated_client }}
                  onChange={(e, value) => handleChange("associated_client", value?.label)}
                  renderInput={(params) => <TextField {...params} required label="Cliente Asociado" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <CustomNumberInput
                  label="Documento del Cliente"
                  value={movement.client_document}
                  onChange={(e) => handleChange("client_document", e.target.value)}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="warehouse"
                  autoComplete
                  value={storeOptions.find((s) => s.id === movement.store_id) || null}
                  options={storeOptions}
                  onChange={(e, value) => handleChange("store_id", value?.id)}
                  renderInput={(params) => <TextField {...params} required label="Almacen" />}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <CustomNumberInput
                  label="Número de Remito"
                  value={movement.numero_remito}
                  onChange={(e) => handleChange("numero_remito", e.target.value)}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="observations"
                label="Observaciones"
                type="text"
                value={movement.observation}
                onChange={(e) => handleChange("observation", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} my={2}>
              <AdditionalItemsList
                additionalItems={clientAdditionalItems}
                movement={movement}
                onMovementUpdate={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained">
                Enviar
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </PageLayout>
  );
}
