import { useState, useEffect } from 'react';
import { axiosGet, axiosPut, axiosDelete, axiosPost } from 'api';
import { GridRowId } from '@mui/x-data-grid';
import { STATUS_CODES, USER_STORAGE_KEY } from 'constants/index';
import { ENTITY_NAMES } from 'constants/urls';
import { Severity } from 'types';

type Config = {
  disableAutoFetch?: boolean;
  authAutoFetch?: boolean;
};

const useCrud = <T>(
  url: string,
  config: Config = {
    disableAutoFetch: false,
    authAutoFetch: true,
  }
) => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastSeverity, setToastSeverity] = useState<Severity>('success');
  const [count, setCount] = useState<number>(0);

  function reload() {
    localStorage.removeItem(USER_STORAGE_KEY);
    window.location.reload();
  }

  const fetchData = async (
    query?: string,
    auth: boolean = false,
    params = {},
    isPaged = false
  ) => {
    setLoading(true);
    const response = await axiosGet(url + (query || ''), auth, params);
    if (response.status === STATUS_CODES.UNAUTHORIZED) {
      reload();
      return {
        status: STATUS_CODES.UNAUTHORIZED,
        data: null,
      };
    }

    setData((isPaged ? response.data.result : response.data) || []);
    setCount((isPaged ? response.data.count : 0) || 0);
    setLoading(false);
    setFirstLoad(false);
    return response;
  };

  const deleteData = async (
    id: GridRowId,
    auth: boolean = false,
    hasToast: boolean = false
  ) => {
    setLoading(true);
    const response = await axiosDelete(url + `${id}`, auth);
    if (response.status === STATUS_CODES.UNAUTHORIZED) {
      reload();
      return {
        status: STATUS_CODES.UNAUTHORIZED,
        data: null,
      };
    }
    if (response.status === STATUS_CODES.OK) {
      setToastMessage(`${ENTITY_NAMES[url]} eliminado correctamente`);
      setToastSeverity('success');
    }
    if (response.status === STATUS_CODES.BAD_REQUEST) {
      setToastMessage(
        `Error al eliminar ${ENTITY_NAMES[url]}. ${response.data}`
      );
      setToastSeverity('error');
    }
    if (response.status === STATUS_CODES.SERVER_ERROR) {
      setToastMessage(`Error al eliminar ${ENTITY_NAMES[url]}`);
      setToastSeverity('error');
    }
    if (hasToast) {
      setShowToast(true);
    }
    setLoading(false);
    return response;
  };

  const updateData = async (
    id: GridRowId,
    dataToUpdate: T,
    auth: boolean = false,
    hasToast: boolean = false
  ) => {
    setLoading(true);
    const response = await axiosPut(url + `${id}`, dataToUpdate, auth);
    if (response.status === STATUS_CODES.UNAUTHORIZED) {
      reload();
      return {
        status: STATUS_CODES.UNAUTHORIZED,
        data: null,
      };
    }
    if (response.status === STATUS_CODES.OK) {
      setToastMessage(`${ENTITY_NAMES[url]} actualizado correctamente`);
      setToastSeverity('success');
    }
    if (response.status === STATUS_CODES.BAD_REQUEST) {
      setToastMessage(
        `Error al actualizar ${ENTITY_NAMES[url]}. ${response.data}`
      );
      setToastSeverity('error');
    }
    if (response.status === STATUS_CODES.SERVER_ERROR) {
      setToastMessage(`Error al actualizar ${ENTITY_NAMES[url]}`);
      setToastSeverity('error');
    }
    if (hasToast) {
      setShowToast(true);
    }
    setLoading(false);
    return response;
  };

  const createData = async (
    dataToCreate: T,
    auth: boolean = false,
    hasToast: boolean = false
  ) => {
    setLoading(true);
    const response = await axiosPost(url, dataToCreate, auth);
    if (response.status === STATUS_CODES.UNAUTHORIZED) {
      reload();
      return {
        status: STATUS_CODES.UNAUTHORIZED,
        data: null,
      };
    }
    if (response.status === STATUS_CODES.OK) {
      setToastMessage(`${ENTITY_NAMES[url]} creado correctamente`);
      setToastSeverity('success');
    }
    if (response.status === STATUS_CODES.BAD_REQUEST) {
      setToastMessage(
        `Error al crear ${ENTITY_NAMES[url]}. ${response.data}`
      );
      setToastSeverity('error');
    }
    if (response.status === STATUS_CODES.SERVER_ERROR) {
      setToastMessage(`Error al crear ${ENTITY_NAMES[url]}`);
      setToastSeverity('error');
    }
    if (hasToast) {
      setShowToast(true);
    }
    setLoading(false);
    return response;
  };

  useEffect(() => {
    !config?.disableAutoFetch && fetchData(undefined, config?.authAutoFetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    count,
    setData,
    setCount,
    loading,
    firstLoad,
    fetchData,
    updateData,
    deleteData,
    createData,
    showToast,
    toastMessage,
    toastSeverity,
    setShowToast,
    setToastMessage,
  };
};

export default useCrud;
