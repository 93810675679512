import { useEffect, useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { AssociatedClient } from 'types';

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: AssociatedClient | undefined;
  onEdit: (associated_client: AssociatedClient) => void;
};

export default function EditAssociatedClientModal({
  open = true,
  handleOpen,
  value,
  onEdit,
}: Props) {
  const [associated_client, setAssociatedClient] = useState<AssociatedClient>(
    value || {
      id: 0,
      code: '',
      client_id: 0,
      razon_social: '',
      address: '',
      cuit: '',
      contact: '',
      observations: '',
      is_active: true,
    }
  );

  useEffect(() => {
    if (open) {
      setAssociatedClient(
        value || {
          id: 0,
          code: '',
          client_id: 0,
          razon_social: '',
          address: '',
          cuit: '',
          contact: '',
          observations: '',
          is_active: true,
        }
      );
    }
  }, [open, value]);

  const handleChange = <K extends keyof Omit<AssociatedClient, 'id'>>(
    name: K,
    value: Omit<AssociatedClient, 'id'>[K]
  ) => {
    setAssociatedClient({
      ...associated_client,
      [name]: value,
    });
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '60rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Editar cliente asociado
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              id='outlined-required'
              label='Razón Social'
              fullWidth
              value={associated_client.razon_social}
              onChange={e => handleChange('razon_social', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id='outlined-required'
              label='Dirección'
              fullWidth
              value={associated_client.address}
              onChange={e => handleChange('address', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id='outlined-required'
              label='Contacto'
              fullWidth
              value={associated_client.contact}
              onChange={e => handleChange('contact', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='outlined-required'
              label='CUIT'
              fullWidth
              value={associated_client.cuit || ''}
              onChange={e => handleChange('cuit', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='outlined-required'
              label='Observaciones'
              fullWidth
              value={associated_client.observations}
              onChange={e => handleChange('observations', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={associated_client.is_active}
                  onChange={(e) => handleChange("is_active", e.target.checked)}
                  color="primary"
                />
              }
              label="Cliente asociado activo"
            />
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                handleOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              onClick={() => {
                onEdit(associated_client);
                handleOpen();
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}