import { Box, Paper, Typography } from '@mui/material';
import { MOVEMENT_TYPES, MovementTypes } from 'constants/index';
import { dateToArgentinianTime } from 'helpers/utcFormatter';
import { Movement, Store } from 'types';

type Props = {
  movement: Movement;
  stores: Store[];
};

export default function OperationPrintHeaderDetails({
  movement,
  stores,
}: Props) {
  const movementTypes = [
    MOVEMENT_TYPES.INCOME,
    MOVEMENT_TYPES.DISCHARGE,
    MOVEMENT_TYPES.ADDITIONAL,
    MOVEMENT_TYPES.PREPARATION,
    MOVEMENT_TYPES.TRANSFER,
  ];

  const movementTypeLabel: string | undefined = movementTypes.find(
    mt => mt.id === movement.movement_type_id
  )?.label;

  const storeLabel: string | undefined = stores.find(
    s => s.id === movement.store_id
  )?.description;

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        padding: '1rem',
        border: '1px solid grey',
        borderRadius: '0.5rem',
      }}
    >
      <Box>
        <Typography fontSize={20} variant='body1'>
          <b>Movimiento</b>:{' '}
          {movementTypeLabel &&
            movementTypeLabel.charAt(0).toUpperCase() +
              movementTypeLabel.slice(1)}
        </Typography>
        <Typography fontSize={14} variant='body1'>
          <b>Fecha:</b> {dateToArgentinianTime(movement.date)}
        </Typography>
        <Typography fontSize={14} variant='body1'>
          <b>Nº:</b> {movement.id?.toString() || '-'}
        </Typography>
      </Box>
      {movement.movement_type_id !== MovementTypes.Transfer && (
        <Box>
          <Typography fontSize={14} variant='body2'>
            <b>Almacén:</b> {storeLabel}
          </Typography>
        </Box>
      )}
    </Paper>
  );
}
