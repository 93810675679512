import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from "@mui/material";
import { MovementTypes } from "constants/index";
import { Movement } from "types";

export default function OperationPrintDetails({
  movement,
  client,
}: {
  movement: Movement;
  client?: string;
}) {
  const isDischargeOrPreparationMovement = 
  movement.movement_type_id === MovementTypes.Discharge || 
  movement.movement_type_id === MovementTypes.Preparation;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          padding={2}
          component={Paper}
          sx={{
            border: "1px solid grey",
            borderRadius: "0.5rem",
          }}
        >
          <Grid item xs={4} display="flex" flexDirection="column" gap={1}>
            <Typography variant="body2">
              <b>Cliente:</b> {client}
            </Typography>
            <Typography variant="body2">
              <b>Doc. del cliente:</b> {movement.client_document}
            </Typography>
          </Grid>
          <Grid item xs={4} display="flex" flexDirection="column" gap={1}>
            <Typography variant="body2">
              <b>Cliente asociado:</b> {movement.associated_client}
            </Typography>
            {movement.movement_type_id !== MovementTypes.Transfer && (
              <Typography variant="body2">
                <b>Pallets:</b> {movement.pallets}
              </Typography>
            )}
          </Grid>
          {movement.movement_type_id !== MovementTypes.Transfer && (
            <Grid item xs={4} display="flex" flexDirection="column" gap={1}>
              <Typography variant="body2">
                <b>Peso:</b> {movement.weight}
              </Typography>
            </Grid>
          )}
          {movement.observation && (
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              gap={1}
              mt={1}
            >
              <Typography variant="body2">
                <b>Observaciones:</b> {movement.observation}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column" gap={2}>
        {movement.articles && movement.articles.length > 0 && (
          <TableContainer
            aria-label="tabla-motrans-destino"
            component={Paper}
            sx={{
              border: "1px solid grey",
              borderRadius: "0.5rem",
              padding: "1rem",
              width: "100%",
            }}
          >
            <Typography variant="h6">Artículos</Typography>
            <Table size="small" padding="none">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="20%">Cod. del Artículo</TableCell>
                  <TableCell align="center" width="5%">Cant.</TableCell>
                  {isDischargeOrPreparationMovement && (
                    <TableCell align="center" width="10%">
                      Cant. Restante
                    </TableCell>
                  )}
                  <TableCell align="center" width="10%">Descripción</TableCell>
                  <TableCell align="center" width="10%">Ubicación</TableCell>
                  <TableCell align="center" width="5%">Cant. x cont.</TableCell>
                  <TableCell align="center" width="10%">UM</TableCell>
                  <TableCell align="center" width="10%">Contenedor</TableCell>
                  <TableCell align="center" width="10%">Lote</TableCell>
                  <TableCell align="center" width="5%">Subtotal (Kg)</TableCell>
                  <TableCell align="center" width="10%">Subtotal (Un)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movement.articles.map((article, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {article.article_locations.article.code}
                    </TableCell>
                    <TableCell align="center">{article.amount}</TableCell>
                    {isDischargeOrPreparationMovement && (
                      <TableCell align="center">
                        {article.article_locations.stock - article.article_locations.reserved}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {article.article_locations.article.description}
                    </TableCell>
                    <TableCell align="center">
                      {article.article_locations.location.code}
                    </TableCell>
                    <TableCell align="center">{article.amount_per_container}</TableCell>
                    <TableCell align="center">
                      {article.article_locations.article.unit.description}
                    </TableCell>
                    <TableCell align="center">
                      {article.article_locations.article.container.description}
                    </TableCell>
                    <TableCell align="center">{article.lote || "Sin lote asignado"}</TableCell>
                    <TableCell align="center">{article.subtotal_kg}</TableCell>
                    <TableCell align="center">{`${article.subtotal} ${article.article_locations.article.unit.description}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {movement.additional_items && movement.additional_items.length > 0 && (
          <TableContainer
            aria-label="tabla-adicionales"
            component={Paper}
            sx={{
              border: "1px solid grey",
              borderRadius: "0.5rem",
              padding: "1rem",
            }}
          >
            <Typography variant="h6">Adicionales</Typography>
            <Table size="small" padding="none">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="5%">Cant.</TableCell>
                  <TableCell align="center" width="40%">Descripción</TableCell>
                  <TableCell align="center" width="40%">Subtotal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movement.additional_items.map((additionalItem, index) => (
                  <TableRow key={index}>

                    <TableCell align="center">{additionalItem.amount}</TableCell>
                    <TableCell align="center">
                      {additionalItem.additional_item?.description ||
                        additionalItem.description}
                    </TableCell>
                    <TableCell align="center">
                      {additionalItem.additional_item?.price || " - "}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {Array.isArray(movement.transfer) && movement.transfer.length > 0 && (
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              border: "1px solid grey",
              borderRadius: "0.5rem",
              padding: "1rem",
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="h5">Transferencia</Typography>
              <TableContainer
                aria-label="tabla-trans-prod"
                component={Paper}
                sx={{
                  border: "1px solid grey",
                  borderRadius: "0.5rem",
                  padding: "1rem",
                }}
              >
                <Typography variant="h6">Producto</Typography>
                <Table size="small" padding="none">
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" variant="head" width="20%">
                        Cantidad
                      </TableCell>
                      <TableCell align="center" width="80%">
                        {movement.transfer[0]?.amount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" variant="head" width="20%">
                        Código
                      </TableCell>
                      <TableCell align="center" width="80%">
                        {movement.transfer[0]?.origin.article.code}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" variant="head" width="20%">
                        Descripción
                      </TableCell>
                      <TableCell align="center" width="80%">
                        {movement.transfer[0]?.origin.article.description}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" variant="head" width="20%">
                        Peso
                      </TableCell>
                      <TableCell align="center" width="80%">
                        {movement.transfer[0]?.origin.article.weight}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" variant="head" width="20%">
                        Contenedor
                      </TableCell>
                      <TableCell align="center" width="80%">
                        {
                          movement.transfer[0]?.origin.article.container
                            .description
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <TableContainer
              aria-label="tabla-trans-origen"
              component={Paper}
              sx={{
                border: "1px solid grey",
                borderRadius: "0.5rem",
                padding: "1rem",
              }}
            >
              <Typography variant="h6">Desde</Typography>
              <Table size="small" padding="none">
                <TableBody>
                  <TableRow>
                    <TableCell align="center" variant="head" width="20%">
                      Código
                    </TableCell>
                    <TableCell align="center" width="80%">
                      {movement.transfer[0]?.origin.location.code}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" variant="head" width="20%">
                      Descripción
                    </TableCell>
                    <TableCell align="center" width="80%">
                      {`${movement.transfer[0]?.origin.location.store.description} | ${movement.transfer[0]?.origin.location.store.code}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" variant="head" width="20%">
                      Ocupación
                    </TableCell>
                    <TableCell align="center" width="80%">
                      {movement.transfer[0]?.origin.location.occupied + " m3"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              aria-label="tabla-trans-destino"
              component={Paper}
              sx={{
                border: "1px solid grey",
                borderRadius: "0.5rem",
                padding: "1rem",
              }}
            >
              <Typography variant="h6">Hacia</Typography>
              <Table size="small" padding="none">
                <TableBody>
                  <TableRow>
                    <TableCell align="center" variant="head" width="20%">
                      Código
                    </TableCell>
                    <TableCell align="center" width="80%">
                      {movement.transfer[0]?.destination.location.code}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" variant="head" width="20%">
                      Descripción
                    </TableCell>
                    <TableCell align="center" width="80%">
                      {`${movement.transfer[0]?.destination.location.store.description} | ${movement.transfer[0]?.destination.location.store.code}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" variant="head" width="20%">
                      Ocupación
                    </TableCell>
                    <TableCell align="center" width="80%">
                      {movement.transfer[0]?.destination.location.occupied +
                        " m3"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
