import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { orderBy as helperOrderBy } from 'helpers/orderBy';
import { dateToArgentinianTime } from 'helpers/utcFormatter';
import React, { useCallback, useMemo, useState } from 'react';
import { ArticleLocation } from 'types';
import { Order } from '../../../constants/index';
import { ArticleLocationRow, EnhancedTableProps, Props, TransferTableHead } from './types';

const headCells: readonly TransferTableHead[] = [
  {
    id: 'location_id',
    numeric: false,
    disablePadding: true,
    label: 'Ubicación',
  },
  {
    id: 'article_id',
    numeric: false,
    disablePadding: true,
    label: 'Artículo (Cód/Desc)',
  },
  {
    id: 'fecha_ingreso',
    numeric: true,
    disablePadding: true,
    label: 'Ingreso',
  },
  {
    id: 'batch',
    numeric: false,
    disablePadding: true,
    label: 'Lote',
  },
  {
    id: 'stock',
    numeric: true,
    disablePadding: true,
    label: 'Unidades',
  },
];

const EnhancedTableHead = React.memo(({ order, orderBy, onRequestSort }: EnhancedTableProps) => {
  const createSortHandler = useCallback(
    (property: keyof ArticleLocationRow) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

const TransferLocationTable = ({
  tableType,
  articleLocations,
  transferStock,
  setTransferStock,
  movement,
  handleChangeMovement,
}: Props) => {
  const [order, setOrder] = useState<Order>(Order.Descending);
  const [orderBy, setOrderBy] = useState<keyof ArticleLocationRow>('fecha_ingreso');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof ArticleLocationRow) => {
      const isAsc = orderBy === property && order === Order.Ascending;
      setOrder(isAsc ? Order.Descending : Order.Ascending);
      setOrderBy(property);
    },
    [order, orderBy]
  ); 

  const handleClick = useCallback(
    (event: React.MouseEvent<unknown>, al: ArticleLocation | null) => {
      if (al) {
        if (tableType === 'from') {
          handleChangeMovement('transfer', {
            ...movement.transfer,
            from: {
              id: al.id,
              article_id: al.article_id,
              location_id: al.location_id,
              article: al.article,
              location: al.location,
              stock: al.stock,
              reserved: al.reserved,
              batch: al.batch,
              fecha_ingreso: al.fecha_ingreso,
            },
            to: null,
            amount: movement.transfer ? movement.transfer.amount : 0,
            new_location_id: movement.transfer ? movement.transfer.new_location_id : null,
          });
          setTransferStock({ isTotal: null, value: 0 });
        } else {
          if (!movement.transfer?.from) {
            return;
          }
          handleChangeMovement('transfer', {
            ...movement.transfer,
            to: {
              id: al.id,
              article_id: al.article_id,
              location_id: al.location_id,
              article: al.article,
              location: al.location,
              stock: al.stock,
              reserved: al.reserved,
              batch: al.batch,
              fecha_ingreso: al.fecha_ingreso,
            },
          });
        }
      } else {
        handleChangeMovement('transfer', {
          ...movement.transfer,
          from: null,
          to: null,
          amount: 0,
          new_location_id: null,
        });
      }
    },
    [tableType, handleChangeMovement, movement, setTransferStock]
  );

  // Filtering and sorting item locations
  const filteredArticleLocations = useMemo(() => {
    const searchLower = searchTerm.toLowerCase();
    if (!searchLower) {
      return helperOrderBy(articleLocations, orderBy, order);
    }
    return helperOrderBy(
      articleLocations.filter((al) => {
        const locationString = `${al.location?.code} (${al.location?.store.description} | ${al.location?.store.code})`.toLowerCase();
        const articleString = `${al.article?.code} - ${al.article?.description}`.toLowerCase();
        return locationString.includes(searchLower) || articleString.includes(searchLower);
      }),
      orderBy,
      order
    );
  }, [articleLocations, searchTerm, orderBy, order]);

  return (
    <>
      <Grid item xs={12}>
        <Box width="100%" pt={5}>
          <Typography variant="h6">
            {tableType === 'to' ? 'Hasta' : tableType === 'from' ? 'Desde' : ''}
          </Typography>
          <TextField
            label="Buscar ubicación o artículo"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <TableContainer component={Paper} sx={{ maxHeight: 240 }}>
          <Table stickyHeader>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={filteredArticleLocations.length} />
            <TableBody>
              {filteredArticleLocations.map((al, index) => {
                const isItemSelected = () => {
                  if (tableType === 'from') {
                    return al.id === movement.transfer?.from?.id;
                  } else {
                    return al.id === movement.transfer?.to?.id;
                  }
                };
                const labelId = `article-location-${index}`;
                const isSelectedInFrom = movement.transfer?.from?.id === al.id;
                return (
                  <TableRow
                    hover={!isSelectedInFrom}
                    onClick={(event: any) => {
                      if (isItemSelected()) {
                        handleClick(event, null);
                      }
                      if (!isSelectedInFrom) {
                        handleClick(event, al);
                      }
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected()}
                    tabIndex={-1}
                    key={al.id}
                    selected={isItemSelected()}
                    sx={(theme) => ({
                      cursor: 'pointer',
                      td: {
                        color:
                          tableType === 'to' &&
                          (isSelectedInFrom || !movement.transfer?.from?.id)
                            ? theme.palette.action.disabled
                            : null,
                      },
                    })}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected()}
                        disabled={
                          tableType === 'to' &&
                          (isSelectedInFrom || !movement.transfer?.from?.id)
                        }
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {al.location?.code +
                        ` (${al.location?.store.description} | ${al.location?.store.code})`}
                    </TableCell>
                    <TableCell>{`${al.article?.code} - ${al.article?.description}`}</TableCell>
                    <TableCell align="right">
                      {dateToArgentinianTime(al.fecha_ingreso ?? '')}
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell align="right">{al.stock}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {tableType === 'from' && (
          <Box width="100%" pt={5}>
            <Typography variant="h6">Cantidad</Typography>
            <FormControl sx={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
              <RadioGroup
                row
                onChange={(e: any) => {
                  setTransferStock({
                    value:
                      e.target.value === 'all' && movement.transfer?.from
                        ? movement.transfer?.from.stock
                        : 0,
                    isTotal: e.target.value === 'all' ? true : false,
                  });
                }}
              >
                <FormControlLabel
                  value={'all'}
                  control={
                    <Radio
                      checked={transferStock.isTotal === true}
                      size="small"
                      disabled={!movement.transfer?.from}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Todo
                      {movement.transfer?.from &&
                        ` (${movement.transfer?.from.stock})`}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={'custom'}
                  control={
                    <Radio
                      checked={transferStock.isTotal === false}
                      size="small"
                      disabled={!movement.transfer?.from}
                    />
                  }
                  label={<Typography variant="body2">Cantidad a elección</Typography>}
                />
              </RadioGroup>
              {transferStock.isTotal === false && (
                <TextField
                  type="number"
                  sx={{ width: '4rem', margin: 0 }}
                  value={transferStock.value}
                  onChange={(e: any) =>
                    setTransferStock((prev) => ({
                      ...prev,
                      value: parseInt(e.target.value),
                    }))
                  }
                  inputProps={{
                    min: 0,
                    max: movement.transfer?.from?.stock ?? 0,
                  }}
                />
              )}
            </FormControl>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default React.memo(TransferLocationTable);