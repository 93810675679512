import { Box, Skeleton, CircularProgress, Backdrop } from '@mui/material';

export default function Loader({
  isFirstLoad,
  isLoading,
}: {
  isFirstLoad: boolean;
  isLoading: boolean;
}) {
  return (
    <>
      {isFirstLoad ? (
        <Box
          sx={{
            width: '100%',
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Skeleton height={40} variant='rounded' />
          <Skeleton height={'50%'} variant='rectangular' />
          <Skeleton height={40} variant='rounded' />
        </Box>
      ) : (
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </>
  );
}
