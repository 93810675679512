import {
  Tooltip,
  Box,
  Button,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { isValidPositiveFloatNumber, toFloat } from 'helpers/formatNumber';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { RxPencil1, RxCross1 } from 'react-icons/rx';

export default function AdditionalItemRow({
  additional_item,
  onChange,
}: {
  additional_item: {
    id: number;
    description: string;
    price: number;
  };
  onChange: (additional_item: {
    id: number;
    description: string;
    price: number;
  }) => void;
}) {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(
    additional_item.description
  );
  const [price, setPrice] = useState<number>(additional_item.price);
  return (
    <TableRow>
      <TableCell>
        <TextField
          label={'Descripcion'}
          type='text'
          value={description}
          error={description.length === 0}
          helperText={
            description.length === 0 ? 'Por favor, completar este campo' : ''
          }
          onChange={e => {
            setDescription(e.target.value);
          }}
          disabled={!isEditable}
        />
      </TableCell>
      <TableCell>
        <TextField
          label={'Precio'}
          type='number'
          value={price}
          error={
            price.toString().length > 0 &&
            price.toString() !== '0' &&
            !isValidPositiveFloatNumber(price.toString())
          }
          helperText={
            price.toString().length > 0 &&
            price.toString() !== '0' &&
            !isValidPositiveFloatNumber(price.toString())
              ? 'El precio debe ser un número positivo'
              : ''
          }
          onChange={e => {
            setPrice(toFloat(e.target.value));
          }}
          disabled={!isEditable}
        />
      </TableCell>
      <TableCell>
        <div style={{ display: 'inline-flex', gap: 4 }}>
          <Tooltip title='Editar valor'>
            <Box>
              <Button
                variant='contained'
                type='submit'
                disabled={isEditable}
                onClick={() => setIsEditable(!isEditable)}
              >
                <RxPencil1 size={'1.2rem'} />
              </Button>
            </Box>
          </Tooltip>
          <Tooltip title='Guadar valor'>
            <Box>
              <Button
                variant='contained'
                type='submit'
                disabled={!isEditable}
                onClick={() => {
                  setIsEditable(!isEditable);
                  onChange({
                    id: additional_item.id,
                    description,
                    price,
                  });
                  setIsEditable(!isEditable);
                }}
              >
                <FaSave size={'1.2rem'} />
              </Button>
            </Box>
          </Tooltip>
          <Tooltip title='Cancelar'>
            <Box>
              <Button
                variant='contained'
                type='submit'
                disabled={!isEditable}
                onClick={() => {
                  setDescription(additional_item.description);
                  setPrice(additional_item.price);
                  setIsEditable(!isEditable);
                }}
              >
                <RxCross1 size={'1.2rem'} />
              </Button>
            </Box>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
}
