import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, FormControl, Grid, TextField } from "@mui/material";
import LiquidationsHistoryTable from "./LiquidationsHistoryTable";
import { Client, LiquidationAggregation } from "types";
import useCrud from "hooks/useCrud";
import { LIQUIDATIONS_URL } from "constants/urls";
import PaginationBar from "components/utils/PaginationBar";
import { useSearchParams } from "react-router-dom";

export default function LiquidationsHistory({
  clients,
}: {
  clients: Client[];
}) {
  const {
    data: liquidations,
    count,
    loading: liquidationsLoading,
    fetchData: getLiquidations,
    setData: updateLiquidations,
    setCount: setTotal,
  } = useCrud<LiquidationAggregation>(LIQUIDATIONS_URL, {
    disableAutoFetch: true,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedClient, setSelectedClient] = React.useState<{
    id: number;
    label: string;
  } | null>(null);

  const onPageChange = (page: number) => {
    const params = new URLSearchParams(searchParams.toString());

    if (selectedClient) {
      params.set("clientId", selectedClient.id.toString());
    }
    params.set("page", page.toString());
    setSearchParams(params);
    setCurrentPage(page);
  };

  const onClientChange = (
    value: {
      id: number;
      label: string;
    } | null
  ) => {
    const params = new URLSearchParams(searchParams.toString());
    if (value) {
      params.set("clientId", value.id.toString());
    } else {
      params.delete("clientId");
    }
    setCurrentPage(1);
    params.set("page", "1");
    setSearchParams(params);
    setSelectedClient(value);
  };

  const onSearchParamsUpdate = useCallback(() => {
    const page = searchParams.get("page");
    const clientId = searchParams.get("clientId");

    if (page) setCurrentPage(Number(page));
    if (clientId) {
      const client = clients.find((c) => c.id === Number(clientId));
      if (client) {
        setSelectedClient({
          id: client.id,
          label: client.razon_social,
        });
      }
      getLiquidations("", true, searchParams, true);
    } else {
      setSelectedClient(null);
      updateLiquidations([]);
      setTotal(0);
    }
  }, [searchParams, updateLiquidations]);

  useEffect(() => {
    onSearchParamsUpdate();
  }, [onSearchParamsUpdate]);

  return (
    <Grid container gap={4} ml={2}>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="client"
            autoComplete
            value={selectedClient}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={clients.map((c) => ({
              id: c.id,
              label: c.razon_social,
            }))}
            onChange={(_, value) => onClientChange(value)}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} required label="Cliente" />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <LiquidationsHistoryTable
          loading={liquidationsLoading}
          liquidations={liquidations}
        />
      </Grid>
      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
        <PaginationBar
          pageSize={10}
          currentPage={currentPage}
          totalCount={count}
          isDisabled={liquidationsLoading || liquidations.length === 0}
          onPageChange={onPageChange}
        />
      </Grid>
    </Grid>
  );
}
