import { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Autocomplete,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import useCrud from 'hooks/useCrud';
import { Location, IMovementsQuery, ArticleLocation } from 'types';
import {
  ARTICLE_LOCATIONS_URL,
} from 'constants/urls';
import StockCard from './StockCard';
import { queryBuilder } from 'helpers/query';
import { useSession } from 'providers/SessionProvider';

export default function StockList() {
  const {
    data: articleLocations,
    fetchData: getArticleLocations,
    loading: articleLocationsLoading,
  } = useCrud<ArticleLocation>(ARTICLE_LOCATIONS_URL);

  const { stores, clients, locations, articles } = useSession();

  const [selectedStoreLocations, setSelectedStoreLocations] = useState<
    Location[]
  >([]);
  const [selectedArticleId, setSelectedArticleId] = useState<
    number | undefined
  >(undefined);
  const [selectedStoreId, setSelectedStoreId] = useState<number | undefined>(
    undefined
  );
  const [selectedLocationId, setSelectedLocationId] = useState<
    number | undefined
  >(undefined);
  const [selectedClientId, setSelectedClientId] = useState<number | undefined>(
    undefined
  );

  const getFilteredStock = async () => {
    setSelectedStoreLocations(
      locations.filter(l => l.store_id === selectedStoreId)
    );
    const query = queryBuilder({
      articleId: selectedArticleId,
      locationId: selectedLocationId,
      clientId: selectedClientId,
      storeId: selectedStoreId,
    } as IMovementsQuery);
    await getArticleLocations(query, true);
  };

  useEffect(() => {
    getFilteredStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedStoreId,
    selectedLocationId,
    selectedClientId,
    selectedArticleId,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={4} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <Typography variant='h6'>Seleccione un filtro</Typography>
          </Grid>
          <Grid container columnSpacing={2} my={1}>
            <Grid item md={3} sm={6} xs={12}>
              <Autocomplete
                loading={stores.length === 0}
                disabled={stores.length === 0}
                loadingText={'Cargando...'}
                fullWidth
                filterOptions={options => options.filter(s => s.is_active)}
                options={stores.map(s => ({
                  id: s.id,
                  label: s.description,
                  is_active: s.is_active,
                }))}
                renderInput={params => (
                  <TextField {...params} required label='Almacen' />
                )}
                onChange={(_, value) =>
                  setSelectedStoreId(value?.id ?? undefined)
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Autocomplete
                loading={selectedStoreLocations.length === 0}
                disabled={selectedStoreLocations.length === 0 || !selectedStoreId}
                loadingText={'Cargando...'}
                fullWidth
                options={selectedStoreLocations
                  .filter(l => l.store_id === selectedStoreId)
                  .map(s => ({
                    id: s.id,
                    label: s.code,
                  }))}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label={
                      !selectedStoreId ? 'Seleccione un Almacén' : 'Ubicación'
                    }
                  />
                )}
                onChange={(_, value) =>
                  setSelectedLocationId(value?.id ?? undefined)
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Autocomplete
                loading={clients.length === 0}
                disabled={clients.length === 0}
                loadingText={'Cargando...'}
                fullWidth
                options={clients.map(c => ({
                  id: c.id,
                  label: c.razon_social,
                  document: c.cuit,
                }))}
                renderInput={params => (
                  <TextField {...params} required label='Cliente' />
                )}
                onChange={(_, value) =>
                  setSelectedClientId(value?.id ?? undefined)
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Autocomplete
                loading={articles.length === 0}
                disabled={articles.length === 0}
                loadingText={'Cargando...'}
                fullWidth
                options={articles.map(c => ({
                  id: c.id,
                  label: c.description,
                }))}
                renderInput={params => (
                  <TextField {...params} required label='Producto' />
                )}
                onChange={(_, value) =>
                  setSelectedArticleId(value?.id ?? undefined)
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {articleLocationsLoading ? (
            <Grid item xs={12} display='flex' justifyContent='center' my={6}>
              <CircularProgress />
            </Grid>
          ) : (
            articleLocations.map((al, index) => (
              <StockCard key={index} articleData={al} />
            ))
          )}
          {!articleLocationsLoading && articleLocations.length === 0 && (
            <Grid item xs={12}>
              <Alert severity='info'>
                <Typography variant='body2'>
                  No hay resultados disponibles
                </Typography>
              </Alert>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
