import { useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { AssociatedClient, Client } from 'types';
import CustomNumberInput from 'components/CustomNumberInput';

type Props = {
  open: boolean;
  handleOpen: () => void;
  onCreate: (newAssociatedClient: AssociatedClient) => void;
  clients: Client[];
};

export default function AddAssociatedClientModal({
  open = true,
  handleOpen,
  onCreate,
  clients,
}: Props) {
  const defaultValues: Omit<AssociatedClient, 'id' | 'code'> = {
    razon_social: '',
    client_id: 0,
    address: '',
    cuit: '',
    contact: '',
    observations: '',
    is_active: true,
  };

  const [data, setData] =
    useState<Omit<AssociatedClient, 'id' | 'code'>>(defaultValues);

  const [selectedClient, setSelectedClient] = useState<{
    id: number;
    label: string;
    document: string;
  } | null>(null);

  const handleChange = <K extends keyof Omit<AssociatedClient, 'id' | 'code'>>(
    name: K,
    value: Omit<AssociatedClient, 'id' | 'code'>[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = (
    newAssociatedClient: Omit<AssociatedClient, 'id' | 'code'>
  ) => {
    onCreate(newAssociatedClient as AssociatedClient);
    setData(defaultValues);
    handleOpen();
    setSelectedClient(null);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setSelectedClient(null);
        setData(defaultValues);
        handleOpen();
      }}
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '60rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Nuevo cliente asociado
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label='Razón Social'
              required
              type='text'
              fullWidth
              onChange={e => handleChange('razon_social', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin='none'>
              <Autocomplete
                id='client'
                autoComplete
                value={selectedClient}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                clearIcon
                options={clients.map(c => ({
                  id: c.id,
                  label: c.razon_social,
                  document: c.cuit,
                }))}
                onChange={(
                  e,
                  value: {
                    id: number;
                    label: string;
                    document: string;
                  } | null
                ) => {
                  if (value) {
                    handleChange('client_id', value.id);
                    setSelectedClient(value || null);
                  }
                }}
                fullWidth
                renderInput={params => (
                  <TextField {...params} required label='Cliente' />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Dirección'
              required
              type='text'
              fullWidth
              onChange={e => handleChange('address', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Contacto'
              required
              type='text'
              fullWidth
              onChange={e => handleChange('contact', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomNumberInput
              label="CUIT"
              value={data.cuit ?? ''}
              onChange={e => handleChange('cuit', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.is_active}
                  onChange={(e) => handleChange("is_active", e.target.checked)}
                  color="primary"
                />
              }
              label="Cliente asociado activo"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Observaciones'
              type='text'
              fullWidth
              onChange={e => handleChange('observations', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                setData(defaultValues);
                handleOpen();
                setSelectedClient(null);
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={
                !data.razon_social ||
                !data.address ||
                !data.contact ||
                !data.client_id
              }
              onClick={() => {
                onSubmit(data);
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
