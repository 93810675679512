import { Button, TableCell, TableRow, TextField } from '@mui/material';
import CustomNumberInput from 'components/CustomNumberInput';
import { useState } from 'react';
import { RxPlus } from 'react-icons/rx';
import { AdditionalItem, MovementAdditionalItem } from 'types';

type Props = {
  additionalItem: AdditionalItem;
  rowIndex: number;
  onMovementAdditionalItemAdd: (
    newMovementAdditionalItem: MovementAdditionalItem
  ) => void;
  disabled: boolean;
};

export default function AddMovementAdditionalItemsRow({
  additionalItem,
  rowIndex,
  onMovementAdditionalItemAdd,
  disabled = false
}: Props) {
  const [amount, setAmount] = useState<number>(1);

  return (
    <TableRow key={rowIndex}>
      <TableCell>{additionalItem.id}</TableCell>
      <TableCell>{additionalItem.description}</TableCell>
      <TableCell>
        <CustomNumberInput
          label="Cantidad"
          value={amount}
          onChange={e => setAmount(parseInt(e.target.value))}
          required
        />
      </TableCell>
      <TableCell>
        <Button
          variant='contained'
          onClick={() => {
            onMovementAdditionalItemAdd({
              id: rowIndex,
              additional_item_id: additionalItem.id,
              description: additionalItem.description,
              amount: amount,
              client_id: additionalItem.client_id,
              additional_item: additionalItem,
            });
          }}
          disabled={disabled}
        >
          <RxPlus size={'1.2rem'} />
        </Button>
      </TableCell>
    </TableRow>
  );
}
