import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ARGENTINA_TIME_ZONE, DATETIME_FORMAT, DATE_FORMAT } from 'constants/index';

export const utcFormatter = {
  parse: (date: string) => {
    dayjs.extend(utc);
    return dayjs(date).utc().format(DATETIME_FORMAT);
  },
  parseToStartOfDay: (date: string) => {
    dayjs.extend(utc);
    return dayjs(date).startOf('day').format(DATETIME_FORMAT);
  },
  parseToEndOfDay: (date: string) => {
    dayjs.extend(utc);
    return dayjs(date).endOf('day').format(DATETIME_FORMAT);
  },
};

export const dateToArgentinianTime = (date: string) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs(date).tz(ARGENTINA_TIME_ZONE).format(DATE_FORMAT);
};
