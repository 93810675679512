import { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { BiEdit } from 'react-icons/bi';
import { GoFilter } from 'react-icons/go';
import { RxPlus, RxTrash } from 'react-icons/rx';

type EnhancedTableToolbarProps = {
  numSelected: number;
  disabled?: boolean;
  handleAddModalOpen: () => void;
  handleEditModalOpen?: () => void;
  handleDeleteModalOpen: () => void;
  onFilterChange?: (filterValue: string) => void;
};

export function EnhancedTableToolbar({
  numSelected,
  disabled,
  handleAddModalOpen,
  handleEditModalOpen,
  handleDeleteModalOpen,
  onFilterChange,
}: EnhancedTableToolbarProps) {
  const [filterVisible, setFilterVisible] = useState(true);
  const [filterValue, setFilterValue] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilterValue(value);
    if (onFilterChange) {
      onFilterChange(value);
    }
  };

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {numSelected > 0 ? (
          <Typography color='inherit' variant='body2'>
            {numSelected} seleccionados
          </Typography>
        ) : (
          <Button
            data-cy='add-mov-entity button'
            variant='contained'
            disabled={disabled}
            onClick={handleAddModalOpen}
          >
            <RxPlus size={'1.2rem'} />
          </Button>
        )}
      </Box>
      {numSelected > 0 ? (
        <Box>
          {numSelected === 1 ? (
            <Tooltip title='Edit'>
              <IconButton onClick={handleEditModalOpen}>
                <BiEdit />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title='Delete'>
            <IconButton onClick={handleDeleteModalOpen}>
              <RxTrash />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          {onFilterChange && (
            <>
              {filterVisible && (
                <TextField
                  variant="outlined"
                  size="small"
                  value={filterValue}
                  onChange={handleFilterChange}
                  placeholder="Filtrar..."
                  sx={{ marginRight: 1, minWidth: 200 }}
                />
              )}
              <Tooltip title="Filter list">
                <IconButton onClick={() => setFilterVisible(!filterVisible)}>
                  <GoFilter />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      )}
    </Toolbar>
  );
}
