import { Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { FC, ReactNode, createContext, useCallback, useState } from 'react';
import { ModalContent, ModalContextData } from 'types';

export const ModalContext = createContext<ModalContextData | undefined>(
  undefined
);

export const ConfirmationModalProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [content, setContent] = useState<ModalContent>({
    title: 'Confirmación',
    message: '¿Estás seguro?',
    action: undefined,
    cancelAction: undefined,
  });

  const showModal = useCallback((modalContent: ModalContent) => {
    setContent(modalContent);
    setOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <Modal open={open}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { sm: '90%', md: '30rem' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid container spacing={2}>
            <Typography variant='h5' gutterBottom>
              {content.title}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body2' gutterBottom>
                {content.message}
              </Typography>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
              <Button
                type='button'
                variant='outlined'
                onClick={() => {
                  hideModal();
                  content.cancelAction?.();
                }}
              >
                Cancelar
              </Button>
              <Button
                data-cy='confirm-modal-submit-button'
                type='button'
                variant='contained'
                onClick={() => {
                  hideModal();
                  content.action?.();
                }}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </ModalContext.Provider>
  );
};
