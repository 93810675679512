export function decimalFormat(number: number): number {
  return Number(number.toFixed(2));
}

export function isValidPositiveFloatNumber(input: string): boolean {
  const number = toFloat(input);
  return !isNaN(number) && number > 0;
}

export function toFloat(input: string): number {
  return parseFloat(input.replace(',', '.'));
}