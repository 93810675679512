import React, { useState, useMemo } from "react";
import {
  Modal,
  Paper,
  TextField,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Button,
} from "@mui/material";

import { AdditionalItem, Movement, MovementAdditionalItem } from "types";
import AddMovementAdditionalItemsRow from "./AddMovementAdditionalItemsRow";

type Props = {
  open: boolean;
  handleOpen: () => void;
  additionalItems: AdditionalItem[];
  rowIndex: number;
  movement: Movement;
  handleAddMovementAdditionalItem: (
    newMovementAdditionalItem: MovementAdditionalItem
  ) => void;
};

export default function AddMovementAdditionalItemModal({
  open = true,
  handleOpen,
  additionalItems,
  movement,
  rowIndex,
  handleAddMovementAdditionalItem,
}: Props) {
  const [search, setSearch] = useState<string>("");

  const filteredItems = useMemo(() => {
    if (!search) {
      return additionalItems;
    }
    return additionalItems.filter(item =>
      item.description.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, additionalItems]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="product-search"
              label="Buscar artículo"
              fullWidth
              value={search}
              onChange={handleChange}
              aria-label="Buscar artículo por descripción"
            />
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxHeight: "20rem" }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width={"10%"}>#</TableCell>
                    <TableCell width={"50%"}>Descripción</TableCell>
                    <TableCell width={"30%"}>Cantidad</TableCell>
                    <TableCell width={"10%"}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredItems.map((item: AdditionalItem, index) => {
                    return (
                      <AddMovementAdditionalItemsRow
                        key={index}
                        additionalItem={item}
                        rowIndex={rowIndex}
                        onMovementAdditionalItemAdd={
                          handleAddMovementAdditionalItem
                        }
                        disabled={
                          !!movement.additional_items.find(
                            (mai) => mai.additional_item_id === item.id
                          )
                        }
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} display={"flex"} justifyContent={"end"} gap={2}>
            <Button
              variant="outlined"
              onClick={handleOpen}
              data-cy={"finish-adding-button"}
              aria-label="Cerrar el modal de agregar artículo adicional"
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
