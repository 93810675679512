import { Box, CircularProgress } from '@mui/material';
import DashboardLayout from 'components/layout/DashboardLayout';
import Loader from 'components/Loader';
import { ROUTES, ROUTES_PERMISSIONS } from 'constants/index';
import AdditionalItemsPage from 'pages/AdditionalItemsPage';
import AdditionalsPage from 'pages/AdditionalsPage';
import ArticlesPage from 'pages/ArticlesPage';
import AssociatedClientsPage from 'pages/AssociatedClientsPage';
import ChangePasswordPage from 'pages/ChangePassword';
import ClientsPage from 'pages/ClientsPage';
import ConfigsPage from 'pages/ConfigsPage';
import ContainersPage from 'pages/ContainersPage';
import DischargesPage from 'pages/DischargesPage';
import HomePage from 'pages/HomePage';
import IncomePage from 'pages/IncomePage';
import { LiquidationDetailsPage } from 'pages/LiquidationDetailsPage';
import LiquidationsPage from 'pages/LiquidationsPage';
import LocationsPage from 'pages/LocationsPage';
import LoginPage from 'pages/LoginPage';
import OperationsPage from 'pages/OperationsPage';
import RouteErrorPage from 'pages/RouteErrorPage';
import StoresPage from 'pages/StoresPage';
import TransferPage from 'pages/TransferPage';
import UnitsPage from 'pages/UnitsPage';
import UserSettingsPage from 'pages/UserSettingsPage';
import UsersPage from 'pages/UsersPage';
import { useSession } from 'providers/SessionProvider';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

export const Router = () => {
  const { userData, isLoggedIn, isLoadingUser } = useSession();
  const userRole = userData?.role || '';

  if (isLoadingUser) {
    return (
      <Loader />
    )
  }

  const routes: RouteObject[] = [
    {
      path: ROUTES.INDEX,
      element: isLoggedIn() ? (
        <Navigate to={ROUTES.HOME} replace />
      ) : (
        <Navigate to={ROUTES.LOGIN} replace />
      ),
      errorElement: <RouteErrorPage />,
    },
    {
      path: ROUTES.LOGIN,
      element: isLoggedIn() ? (
        <Navigate to={ROUTES.HOME} replace />
      ) : (
        <LoginPage />
      ),
      errorElement: <RouteErrorPage />,
    },
    {
      element: (
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      ),
      errorElement: <RouteErrorPage />,
      children: [
        {
          path: ROUTES.HOME,
          element: isLoggedIn() ? (
            <HomePage />
          ) : (
            <Navigate to={ROUTES.LOGIN} replace />
          ),
        },
        {
          path: ROUTES.INCOME,
          element: ROUTES_PERMISSIONS[ROUTES.INCOME].includes(userRole) ? (
            <IncomePage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
        {
          path: ROUTES.DISCHARGE,
          element: ROUTES_PERMISSIONS[ROUTES.DISCHARGE].includes(userRole) ? (
            <DischargesPage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
        {
          path: ROUTES.ADDITIONALS,
          element: ROUTES_PERMISSIONS[ROUTES.ADDITIONALS].includes(userRole) ? (
            <AdditionalsPage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
        {
          path: ROUTES.TRANSFER,
          element: ROUTES_PERMISSIONS[ROUTES.TRANSFER].includes(userRole) ? (
            <TransferPage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
        {
          path: ROUTES.OPERATIONS,
          element: ROUTES_PERMISSIONS[ROUTES.OPERATIONS].includes(userRole) ? (
            <OperationsPage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
        {
          path: ROUTES.SALES,
          element: ROUTES_PERMISSIONS[ROUTES.SALES].includes(userRole) ? (
            <LiquidationsPage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
        {
          path: ROUTES.SALES_DETAIL,
          element: ROUTES_PERMISSIONS[ROUTES.SALES_DETAIL].includes(
            userRole
          ) ? (
            <LiquidationDetailsPage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
        {
          path: ROUTES.LISTS,
          errorElement: <RouteErrorPage />,
          children: [
            {
              path: ROUTES.CLIENTS,
              element: ROUTES_PERMISSIONS[ROUTES.CLIENTS].includes(userRole) ? (
                <ClientsPage />
              ) : (
                <Navigate to={ROUTES.INDEX} replace />
              ),
            },
            {
              path: ROUTES.USERS,
              element: ROUTES_PERMISSIONS[ROUTES.USERS].includes(userRole) ? (
                <UsersPage />
              ) : (
                <Navigate to={ROUTES.INDEX} replace />
              ),
            },
            {
              path: ROUTES.ASSOCIADED_CLIENTS,
              element: ROUTES_PERMISSIONS[ROUTES.ASSOCIADED_CLIENTS].includes(
                userRole
              ) ? (
                <AssociatedClientsPage />
              ) : (
                <Navigate to={ROUTES.INDEX} replace />
              ),
            },
            {
              path: ROUTES.PRODUCTS,
              element: ROUTES_PERMISSIONS[ROUTES.PRODUCTS].includes(
                userRole
              ) ? (
                <ArticlesPage />
              ) : (
                <Navigate to={ROUTES.INDEX} replace />
              ),
            },
            {
              path: ROUTES.STORES,
              element: ROUTES_PERMISSIONS[ROUTES.STORES].includes(userRole) ? (
                <StoresPage />
              ) : (
                <Navigate to={ROUTES.INDEX} replace />
              ),
            },
            {
              path: ROUTES.LOCATIONS,
              element: ROUTES_PERMISSIONS[ROUTES.LOCATIONS].includes(
                userRole
              ) ? (
                <LocationsPage />
              ) : (
                <Navigate to={ROUTES.INDEX} replace />
              ),
            },
            {
              path: ROUTES.UNITS,
              element: ROUTES_PERMISSIONS[ROUTES.UNITS].includes(userRole) ? (
                <UnitsPage />
              ) : (
                <Navigate to={ROUTES.INDEX} replace />
              ),
            },
            {
              path: ROUTES.CONTAINERS,
              element: ROUTES_PERMISSIONS[ROUTES.CONTAINERS].includes(
                userRole
              ) ? (
                <ContainersPage />
              ) : (
                <Navigate to={ROUTES.INDEX} replace />
              ),
            },
            {
              path: ROUTES.ADDITIONAL_ITEMS,
              element: ROUTES_PERMISSIONS[ROUTES.ADDITIONAL_ITEMS].includes(
                userRole
              ) ? (
                <AdditionalItemsPage />
              ) : (
                <Navigate to={ROUTES.INDEX} replace />
              ),
            },
          ],
        },
        {
          path: ROUTES.CHANGE_PASSWORD,
          element: ROUTES_PERMISSIONS[ROUTES.CHANGE_PASSWORD].includes(
            userRole
          ) ? (
            <ChangePasswordPage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
        {
          path: ROUTES.USER_SETTINGS,
          element: ROUTES_PERMISSIONS[ROUTES.USER_SETTINGS].includes(
            userRole
          ) ? (
            <UserSettingsPage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
        {
          path: ROUTES.CONFIGS,
          element: ROUTES_PERMISSIONS[ROUTES.CONFIGS].includes(userRole) ? (
            <ConfigsPage />
          ) : (
            <Navigate to={ROUTES.INDEX} replace />
          ),
        },
      ],
    },
  ];

  const router = createBrowserRouter(routes);

  return (
    <RouterProvider router={router} fallbackElement={<CircularProgress />} />
  );
};