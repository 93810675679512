import { TableCell, TableCellProps, styled } from '@mui/material';
import { SETTLEMENT_TYPES } from 'constants/index';
import { floatFormatter, surfaceCalc, volumeCalc } from 'helpers';
import { useMemo } from 'react';
import { BsBoxFill } from 'react-icons/bs';
import { GiWeight } from 'react-icons/gi';
import { MdOutlineMonetizationOn, MdPallet } from 'react-icons/md';
import { TbRulerMeasure } from 'react-icons/tb';
import {
  ArticleLiquidation,
  ArticleLocationLog,
  Container,
  ContainerLiquidation,
  CustomChip,
  LiquidationAggregation,
  MovementArticleLiquidation,
} from 'types';

export const CustomHeadCell = styled(TableCell)<TableCellProps>(
  ({ theme }) => ({
    color: theme.palette.common.white,
  })
);

type SettlementType = (typeof SETTLEMENT_TYPES)[keyof typeof SETTLEMENT_TYPES];

export const UnitHeaderCell = (settlementType: SettlementType) => {
  let headerText: string = '';

  switch (settlementType) {
    case SETTLEMENT_TYPES.ABONO:
      return null;
    case SETTLEMENT_TYPES.PALLETS:
      headerText = 'Pallets';
      break;
    case SETTLEMENT_TYPES.PESO:
      headerText = 'Peso';
      break;
    case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
        headerText = 'Peso Volumétrico';
        break;
    case SETTLEMENT_TYPES.SUPERFICIE:
      headerText = 'M2';
      break;
    case SETTLEMENT_TYPES.VOLUMEN:
      headerText = 'M3';
      break;
    default:
      break;
  }

  return <CustomHeadCell>{headerText}</CustomHeadCell>;
};

export const UnitValueHelperText = (settlementType: SettlementType) => {
  switch (settlementType) {
    case SETTLEMENT_TYPES.ABONO:
      return 'Valor';
    case SETTLEMENT_TYPES.PALLETS:
      return 'Valor (Pallet)';
    case SETTLEMENT_TYPES.PESO:
      return 'Valor (Cant. * Peso)';
    case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
        return 'Valor (Cant. * Peso Volumétrico)';
    case SETTLEMENT_TYPES.SUPERFICIE:
      return 'Valor (Cant. * M2)';
    case SETTLEMENT_TYPES.VOLUMEN:
      return 'Valor (Cant. * M3)';
    default:
      return;
  }
};

export const UnitValueCell = (
  settlementType: SettlementType,
  liquidation: LiquidationAggregation,
  movArtLiq?: MovementArticleLiquidation,
  log?: ArticleLocationLog
) => {
  const article = useMemo(() => {
    const articleId = log ? log.article_id : movArtLiq?.article_id;
    return liquidation.liquidation_articles.find(a => a.article_id === articleId);
  }, [liquidation.liquidation_articles, log, movArtLiq]);

  const container = useMemo(() => {
    return article?.container || liquidation.liquidation_containers.find(
      c => c.container_id === article?.container_id
    );
  }, [article, liquidation.liquidation_containers]);

  const unitsPerPallet = article?.units_per_pallet || 1;
  const quantity = log ? log.quantity : movArtLiq?.amount || 0;

  if (settlementType === SETTLEMENT_TYPES.PALLETS) {
    return <TableCell>{floatFormatter(quantity / unitsPerPallet)}</TableCell>;
  }

  switch (settlementType) {
    case SETTLEMENT_TYPES.ABONO:
      return null;

    case SETTLEMENT_TYPES.PESO:
      return (
        <TableCell>
          {floatFormatter(article?.weight || 0)}
        </TableCell>
      );

    case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
      return (
        <TableCell>
          {floatFormatter(article?.volume_weight || 0)}
        </TableCell>
      );

    case SETTLEMENT_TYPES.SUPERFICIE:
      return (
        <TableCell>
          {floatFormatter(
            (container?.width || 0) * (container?.long || 0)
          )}
        </TableCell>
      );

    case SETTLEMENT_TYPES.VOLUMEN:
      return (
        <TableCell>
          {floatFormatter(
            (container?.width || 0) * (container?.long || 0) * (container?.height || 0)
          )}
        </TableCell>
      );

    default:
      return null;
  }
};

export const MovementTypeHeaderCell = (movementType: number) => {
  switch (movementType) {
    case 1:
      return 'ING';
    case 2:
      return 'EGR';
    case 3:
      return 'ADI';
  }
};

export function chipHandler(settlementType: SettlementType): CustomChip {
  switch (settlementType) {
    case SETTLEMENT_TYPES.ABONO:
      return {
        color: 'primary',
        label: 'Abono',
        icon: <MdOutlineMonetizationOn />,
      };
    case SETTLEMENT_TYPES.PALLETS:
      return {
        color: 'secondary',
        label: 'Pallets',
        icon: <MdPallet />,
      };
    case SETTLEMENT_TYPES.PESO:
      return {
        color: 'info',
        label: 'Peso (Kg)',
        icon: <GiWeight />,
      };
    case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
        return {
          color: 'info',
          label: 'Peso Volumétrico (Pv)',
          icon: <GiWeight />,
        };
    case SETTLEMENT_TYPES.SUPERFICIE:
      return {
        color: 'success',
        label: 'Superficie (M2)',
        icon: <TbRulerMeasure />,
      };
    case SETTLEMENT_TYPES.VOLUMEN:
      return {
        color: 'warning',
        label: 'Volumen (M3)',
        icon: <BsBoxFill />,
      };
    default:
      return {
        color: 'info',
        label: '',
        icon: <></>,
      };
  }
}

export const SettlementHeaderCells = (settlementType: SettlementType) => {
  switch (settlementType) {
    case SETTLEMENT_TYPES.ABONO:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.PALLETS:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Pallets</TableCell>
          <TableCell>Total Pallets</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.PESO:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Peso (Kg)</TableCell>
          <TableCell>Total Peso</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Peso Volumétrico (Pv)</TableCell>
          <TableCell>Total Peso Volumétrico</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.SUPERFICIE:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Superficie (M2)</TableCell>
          <TableCell>Total Superficie</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.VOLUMEN:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Volumen (M3)</TableCell>
          <TableCell>Total Volumen</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    default:
      return null;
  }
};

export const MovementSettlementHeaderCells = (settlementType: SettlementType) => {
  switch (settlementType) {
    case SETTLEMENT_TYPES.ABONO:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.PALLETS:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Pallets</TableCell>
          <TableCell>Total Pallets</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.PESO:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Peso (Kg)</TableCell>
          <TableCell>{UnitValueHelperText(SETTLEMENT_TYPES.PESO)}</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Peso Volumétrico (Pv)</TableCell>
          <TableCell>{UnitValueHelperText(SETTLEMENT_TYPES.PESO_VOLUMETRICO)}</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.SUPERFICIE:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Superficie (M2)</TableCell>
          <TableCell>{UnitValueHelperText(SETTLEMENT_TYPES.SUPERFICIE)}</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    case SETTLEMENT_TYPES.VOLUMEN:
      return (
        <>
          <TableCell>Código</TableCell>
          <TableCell>Descripción</TableCell>
          <TableCell>Cantidad (Unidades)</TableCell>
          <TableCell>Volumen (M3)</TableCell>
          <TableCell>{UnitValueHelperText(SETTLEMENT_TYPES.VOLUMEN)}</TableCell>
          <TableCell>Precio</TableCell>
          <TableCell>Subtotal</TableCell>
        </>
      );

    default:
      return null;
  }
};