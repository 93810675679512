export const dateISOFormat = (date: string | null) => {
  if (date) {
    try {
      return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
    } catch (dateExcep) {
      return null;
    }
  } else {
    return null;
  }
};

export const volumeCalc = (
  width: number | undefined,
  long: number | undefined,
  height: number | undefined
) => {
  if (!width || !long || !height) {
    return 0;
  }
  return width * long * height;
};

export const surfaceCalc = (
  width: number | undefined,
  long: number | undefined
) => {
  if (!width || !long) {
    return 0;
  }
  return width * long;
};

export const arsFormatter = (amount: number | bigint) =>
  Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(
    amount
  );

export const floatFormatter = (amount: number | bigint) => amount.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export const formatSettlementTypeDescription = (str: string): string => {
  return str.replace(/_/g, ' ');
}