export function searchFilter<T, K extends keyof T>(
  search: string,
  data: T[],
  keys: K[] = [],
  nestedKeys?: (keyof T[K])[]
): T[] {
  if (!search) {
    return data;
  }

  const regex = new RegExp(search, 'i');

  return data.filter(item => {
    if (nestedKeys) {
      return nestedKeys.some(nestedKey => {
        const nestedValue = item[keys[0]][nestedKey];
        if (Array.isArray(nestedValue)) {
          return nestedValue.some(value => regex.test(value as string));
        } else {
          return regex.test(nestedValue as string);
        }
      });
    } else {
      return keys.some(key => {
        const value = item[key];
        if (Array.isArray(value)) {
          return value.some(v => regex.test(v as string));
        } else {
          return regex.test(value as string);
        }
      });
    }
  });
}

export function recursiveSearchFilter<T>(
  search: string,
  data: T[],
  keys: (keyof T)[],
  nestedKeysList: string[][] = []
): T[] {
  if (!search) {
    return data;
  }

  const regex = new RegExp(search, 'i');

  const searchInNestedObject = (obj: any, keys: string[]): boolean => {
    if (!obj || keys.length === 0) {
      return false;
    }

    const [currentKey, ...restKeys] = keys;
    const value = obj[currentKey];

    if (Array.isArray(value)) {
      return value.some(item => searchInNestedObject(item, restKeys));
    } else if (restKeys.length === 0) {
      return regex.test(value as string);
    } else if (typeof value === 'object' && value !== null) {
      return searchInNestedObject(value, restKeys);
    }

    return false;
  };

  return data.filter(item =>
    keys.some(key => {
      const value = item[key];
      if (Array.isArray(value)) {
        return value.some(subItem => nestedKeysList.some(nestedKeys => searchInNestedObject(subItem, nestedKeys)));
      } else if (nestedKeysList.length > 0 && typeof value === 'object' && value !== null) {
        return nestedKeysList.some(nestedKeys => searchInNestedObject(value, nestedKeys));
      } else {
        return regex.test(value as string);
      }
    })
  );
}