import {
  Modal,
  Paper,
  Grid,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import Alert from './Alert';

type Props = {
  open: boolean;
  handleOpen: () => void;
  entityName: string;
  values: string[];
  handleDelete: () => void;
  canDelete?: boolean;
};

export default function DeleteConfirmationModaL({
  open = true,
  handleOpen,
  entityName,
  values,
  handleDelete,
  canDelete,
}: Props) {
  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Confirmar eliminación de {entityName}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>
              Se eliminarán los siguientes registros permanentemente:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 300,
              }}
            >
              {values.map((value, idx) => (
                <ListItem key={idx}>
                  <ListItemText primary={value} />
                </ListItem>
              ))}
            </List>
          </Grid>
          {canDelete === false && (
            <Grid item xs={12}>
              <Alert
                severity='warning'
                text='No puedes eliminar artículos que tengan stock'
              />
            </Grid>
          )}
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => handleOpen()}
            >
              Cancelar
            </Button>
            <Button
              type='button'
              variant='contained'
              disabled={canDelete === false}
              onClick={() => {
                handleDelete();
                handleOpen();
              }}
            >
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
