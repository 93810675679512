import {
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { SETTLEMENT_TYPES } from 'constants/index';
import { arsFormatter } from 'helpers';
import { dateToArgentinianTime } from 'helpers/utcFormatter';
import { CustomChip, LiquidationAggregation, LiquidationSettlementType } from 'types';


type Props = {
  liquidation: LiquidationAggregation;
  id: string | undefined;
  incomeChip?: CustomChip;
  stayChip?: CustomChip;
  dischargeChip?: CustomChip;
};

function checkSettlementType(
  liquidation_settlement_type: LiquidationSettlementType
) {
  switch (liquidation_settlement_type.settlement_type) {
    case SETTLEMENT_TYPES.ABONO:
      return `${arsFormatter(liquidation_settlement_type.calculation)}`;
    case SETTLEMENT_TYPES.PALLETS:
      return `${arsFormatter(
        liquidation_settlement_type.calculation
      )} (x Pallet)`;
    case SETTLEMENT_TYPES.PESO:
      return `${arsFormatter(liquidation_settlement_type.calculation)} (x Kg)`;
    case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
      return `${arsFormatter(liquidation_settlement_type.calculation)} (x Pv)`;
    case SETTLEMENT_TYPES.VOLUMEN:
      return `${arsFormatter(liquidation_settlement_type.calculation)} (x M3)`;
    case SETTLEMENT_TYPES.SUPERFICIE:
      return `${arsFormatter(liquidation_settlement_type.calculation)} (x M2)`;
  }
}

export default function LiquidationDetailsCard({
  liquidation,
  incomeChip,
  stayChip,
  dischargeChip,
  id,
}: Props) {
  return (
    <Card
      sx={{
        width: '100%',
      }}
    >
      <CardContent
        sx={{
          '@media print': {
            root: { boxShadow: 'none' },
            '> *': { '*': { fontSize: 12 } },
          },
        }}
      >
        <Grid item xs={12}>
          <Typography variant='body2'>Liquidación Nº {id}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>
            Cliente: {liquidation.liquidation_clients[0].razon_social}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>
            Período: {dateToArgentinianTime(liquidation.date_from.toString())} a{' '}
            {dateToArgentinianTime(liquidation.date_to.toString())}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>Almacén: {liquidation.store_description}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            my: 1,
            '@media print': {
              my: 0,
            },
          }}
        >
          <Paper elevation={4}>
            <Grid
              container
              sx={{
                padding: 2,
                gap: 2,
                '@media print': {
                  padding: 0,
                  gap: 0,
                },
              }}
            >
              <Grid item xs={12} display='flex' alignItems='center' gap={1}>
                <Typography variant='body2'>
                  Valor de Recepción:{' '}
                  {checkSettlementType(
                    liquidation.liquidation_settlement_types[0]
                  )}
                </Typography>
                {incomeChip && (
                  <Chip
                    color={incomeChip.color}
                    size='small'
                    label={incomeChip.label}
                    icon={incomeChip.icon}
                  />
                )}
              </Grid>
              <Grid item xs={12} display='flex' alignItems='center' gap={1}>
                <Typography variant='body2'>
                  Valor estadía por día:{' '}
                  {checkSettlementType(
                    liquidation.liquidation_settlement_types[1]
                  )}
                </Typography>
                {stayChip && (
                  <Chip
                    color={stayChip.color}
                    size='small'
                    label={stayChip.label}
                    icon={stayChip.icon}
                  />
                )}
              </Grid>
              <Grid item xs={12} display='flex' alignItems='center' gap={1}>
                <Typography variant='body2'>
                  Valor picking + Despacho:{' '}
                  {checkSettlementType(
                    liquidation.liquidation_settlement_types[2]
                  )}
                </Typography>
                {dischargeChip && (
                  <Chip
                    color={dischargeChip.color}
                    size='small'
                    label={dischargeChip.label}
                    icon={dischargeChip.icon}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </CardContent>
    </Card>
  );
}