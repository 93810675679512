import { useEffect, useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  Autocomplete,
} from '@mui/material';

import { Article, Client, Container, Unit } from 'types';
import { defaultEditArticleValue } from '../../../constants';
import { isValidPositiveFloatNumber, toFloat } from 'helpers/formatNumber';

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: Article | undefined;
  onEdit: (article: Article, container?: Container) => void;
  clients: Client[];
  units: Unit[]; 
};

export default function EditArticleModal({
  open = true,
  handleOpen,
  value = defaultEditArticleValue,
  onEdit,
  clients,
  units,
}: Props) {
  const [data, setData] = useState<Article>(value);
  const [selectedClient, setSelectedClient] = useState(data.client);
  const [selectedUnit, setSelectedUnit] = useState(data.unit);
  const [containerEdited, setContainerEdited] = useState(false);
  
  useEffect(() => {
    if (open) {
      setData(value);
      setSelectedClient(value!.client);
      setSelectedUnit(value!.unit);
      setContainerEdited(false);
    }
  }, [open, value]);

  const handleChange = <
    K extends keyof Omit<Article, 'id' | 'client' | 'unit'>
  >(
    name: K,
    value: Omit<Article, 'id'>[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid
          container
          spacing={2}
          component='form'
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onEdit(data, containerEdited ? data.container : undefined);
            handleOpen();
          }}
        >
          <Grid item xs={12}>
            <Typography variant='h6'>
              Editar Producto
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Descripción'
              fullWidth
              required
              value={data.description}
              onChange={e => handleChange('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Codigo'
              fullWidth
              required
              value={data.code}
              onChange={e => handleChange('code', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Cantidad por Contenedor'
              fullWidth
              required
              type='text'
              value={data.amount_per_container.toString()}
              error={
                data.amount_per_container.toString() === '0' ||
                !isValidPositiveFloatNumber(data.amount_per_container.toString())
              }
              helperText={
                data.amount_per_container.toString() === '0' ||
                !isValidPositiveFloatNumber(data.amount_per_container.toString())
                  ? 'La cantidad debe ser un número positivo mayor a cero'
                  : ''
              }
              onChange={e => handleChange('amount_per_container', toFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Unidades por pallet'
              fullWidth
              required
              type='text'
              value={data.units_per_pallet?.toString()}
              error={
                data.units_per_pallet?.toString() === '0' ||
                !isValidPositiveFloatNumber(data.units_per_pallet?.toString() || '0')
              }
              helperText={
                data.units_per_pallet?.toString() === '0' ||
                !isValidPositiveFloatNumber(data.units_per_pallet?.toString() || '0')
                  ? 'La cantidad debe ser un número positivo mayor a cero'
                  : ''
              }
              onChange={e => handleChange('units_per_pallet', toFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Peso'
              fullWidth
              required
              type='text'
              value={data.weight.toString()}
              error={
                data.weight.toString() === '0' ||
                !isValidPositiveFloatNumber(data.weight.toString())
              }
              helperText={
                data.weight.toString() === '0' ||
                !isValidPositiveFloatNumber(data.weight.toString())
                  ? 'El peso debe ser un número positivo mayor a cero'
                  : ''
              }
              onChange={e => handleChange('weight', toFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Peso Volumétrico'
              fullWidth
              required
              type='text'
              value={data.volume_weight.toString()}
              error={
                data.volume_weight.toString() === '0' ||
                !isValidPositiveFloatNumber(data.volume_weight.toString())
              }
              helperText={
                data.volume_weight.toString() === '0' ||
                !isValidPositiveFloatNumber(data.volume_weight.toString())
                  ? 'El peso debe ser un número positivo mayor a cero'
                  : ''
              }
              onChange={e => handleChange('volume_weight', toFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              Editar Contenedor
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Descripción'
              fullWidth
              value={data.container.description}
              onChange={e => {
                setContainerEdited(true);
                handleChange('container', {
                  ...data.container,
                  description: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Ancho'
              fullWidth
              required
              type='text'
              value={data.container.width.toString()}
              error={
                data.container.width.toString() === '0' ||
                !isValidPositiveFloatNumber(data.container.width.toString())
              }
              helperText={
                data.container.width.toString() === '0' ||
                !isValidPositiveFloatNumber(data.container.width.toString())
                  ? 'El ancho debe ser un número positivo mayor a cero'
                  : ''
              }
              onChange={e => {
                setContainerEdited(true);
                handleChange('container', {
                  ...data.container,
                  width: toFloat(e.target.value),
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Alto'
              fullWidth
              type='text'
              required
              value={data.container.height.toString()}
              error={
                data.container.height.toString() === '0' ||
                !isValidPositiveFloatNumber(data.container.height.toString())
              }
              helperText={
                data.container.height.toString() === '0' ||
                !isValidPositiveFloatNumber(data.container.height.toString())
                  ? 'El alto debe ser un número positivo mayor a cero'
                  : ''
              }
              onChange={e => {
                setContainerEdited(true);
                handleChange('container', {
                  ...data.container,
                  height: toFloat(e.target.value),
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Largo'
              fullWidth
              type='text'
              required
              value={data.container.long.toString()}
              error={
                data.container.long.toString() === '0' ||
                !isValidPositiveFloatNumber(data.container.long.toString())
              }
              helperText={
                data.container.long.toString() === '0' ||
                !isValidPositiveFloatNumber(data.container.long.toString())
                  ? 'El largo debe ser un número positivo mayor a cero'
                  : ''
              }
              onChange={e => {
                setContainerEdited(true);
                handleChange('container', {
                  ...data.container,
                  long: toFloat(e.target.value),
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="none">
              <Autocomplete
                id="unit"
                value={selectedUnit}
                options={units}
                getOptionLabel={(option) => option.description}
                onChange={(e, value) => {
                  if (!value) return;
                  setSelectedUnit(value);
                  setData((prevData) => ({
                    ...prevData,
                    unit_id: value.id,
                    unit: value,
                  }));
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} required label="Unidad Operativa" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="none">
              <Autocomplete
                id="client"
                value={selectedClient}
                options={clients}
                getOptionLabel={(option) => option.razon_social}
                onChange={(e, value) => {
                  if (!value) return;
                  setSelectedClient(value);
                  setData((prevData) => ({
                    ...prevData,
                    client_id: value.id,
                    client: value,
                  }));
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} required label="Cliente" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                handleOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={
                !data.description ||
                !data.code ||
                !data.weight ||
                !data.container.description ||
                !data.container.height ||
                !data.container.long ||
                !data.container.width ||
                !isValidPositiveFloatNumber(data.weight.toString()) ||
                !isValidPositiveFloatNumber(data.container.long.toString()) ||
                !isValidPositiveFloatNumber(data.container.height.toString()) ||
                !isValidPositiveFloatNumber(data.container.width.toString()) ||
                !isValidPositiveFloatNumber(data.amount_per_container.toString())
              }
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}