import { useState } from "react";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { Client } from "types";
import ClientPreferences from "../settlement_types/ClientPreferences";
import CustomNumberInput from "components/CustomNumberInput";

type Props = {
  open: boolean;
  handleOpen: () => void;
  onCreate: (newClient: Client) => void;
};

export default function AddClientModal({
  open = true,
  handleOpen,
  onCreate,
}: Props) {
  const defaultValues: Omit<Client, "id"> = {
    razon_social: "",
    code: "",
    address: "",
    cuit: "",
    contact: "",
    observations: "",
    is_active: true,
    client_settlement_types: [],
  };

  const [data, setData] = useState<Omit<Client, "id">>(defaultValues);

  const handleChange = <K extends keyof Omit<Client, "id">>(
    name: K,
    value: Omit<Client, "id">[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = () => {
    const clientPreferencesList = data.client_settlement_types?.filter(
      (clientSettlementType) => clientSettlementType.price > 0
    );
    handleChange("client_settlement_types", clientPreferencesList);
    onCreate(data as Client);
  };

  const handleClose = () => {
    setData(defaultValues);
    handleOpen();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sm: "90%", md: "60rem" },
          height: "90%",
          overflow: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container columnSpacing={1}>
          <Typography variant="h5" gutterBottom>
            Nuevo cliente
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Razón Social"
              required
              type="text"
              fullWidth
              onChange={(e) => handleChange("razon_social", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomNumberInput
              label="CUIT"
              value={data.cuit}
              onChange={(e) => handleChange("cuit", e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Dirección"
              type="text"
              required
              fullWidth
              onChange={(e) => handleChange("address", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Contacto"
              type="text"
              required
              fullWidth
              onChange={(e) => handleChange("contact", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Codigo"
              type="text"
              required
              fullWidth
              onChange={(e) => handleChange("code", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.is_active}
                  onChange={(e) => handleChange("is_active", e.target.checked)}
                  color="primary"
                />
              }
              label="Cliente activo"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Observaciones"
              required
              type="text"
              fullWidth
              onChange={(e) => handleChange("observations", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ClientPreferences
              client={data as Client}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end" gap={1}>
            <Button type="button" variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={
                !data.razon_social ||
                !data.cuit ||
                !data.address ||
                !data.contact ||
                !data.code ||
                !data.observations ||
                !(
                  data.client_settlement_types?.filter(
                    (preference) => preference.is_default
                  ).length === 3
                )
              }
              onClick={() => {
                onSubmit();
                handleClose();
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
