import {
  Box,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from '@mui/material';
import { ReactNode, forwardRef } from 'react';
import SHCLogo from 'assets/imgs/shc-logo.png';

type Props = {
  children?: ReactNode;
  headerInfo?: ReactNode;
};

export const ImageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  img: {
    width: '10rem',
    height: 'auto',
    filter: 'grayscale(1)',
  },
}));

export const PrintLayout = forwardRef(function PrintLayout(
  { children, headerInfo }: Props,
  ref
) {
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#9e9e9e',
      },
      secondary: {
        main: '#616161',
      },
    },
    typography: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 10,
    },
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiFilledInput: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
      },
      MuiListItem: {
        defaultProps: {
          dense: true,
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFab: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
          color: 'secondary',
        },
      },
      MuiToolbar: {
        defaultProps: {
          variant: 'dense',
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        ref={ref}
        sx={{
          display: 'none',
          displayPrint: 'block',
          padding: 2,
          filter: 'grayscale(1)',
        }}
      >
        <Grid
          container
          p={1}
          sx={{
            border: '1px solid grey',
            borderRadius: '0.5rem',
            height: '11.5rem',
            // height: 'auto',
          }}
        >
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <ImageContainer>
                  <img src={SHCLogo} alt='Logo SHC' />
                </ImageContainer>
              </Grid>
              <Grid item xs={12} sx={{ color: 'black' }}>
                <Typography variant='body2'>Logística SHC</Typography>
                <Typography variant='body2'>
                  Domicilio: Calle 1234 - CABA{' '}
                </Typography>
                <Typography variant='body2'>Teléfono: 1234567890</Typography>
                <Typography variant='body2'>
                  https://www.logisticashc.com.ar
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {headerInfo}
          </Grid>
        </Grid>
        <Box py={2}>{children}</Box>
      </Box>
    </ThemeProvider>
  );
});
