import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

interface CustomNumberInputProps {
  label: string;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  allowOnlyPositive?: boolean;
  dataCy?: string;
}

const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
  label,
  value,
  onChange,
  required = false,
  disabled = false,
  allowOnlyPositive = false,
  dataCy,
}) => {
  const [error, setError] = React.useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
  
    if (newValue === "") {
      setError(null);
      onChange(event);
      return;
    }
  
    const decimalRegex = /^-?\d*\.?\d*$/;
  
    if (!decimalRegex.test(newValue)) {
      setError("Solo se permiten números y un punto decimal.");
      return;
    }
  
    const numericValue = parseFloat(newValue);
  
    if (isNaN(numericValue)) {
      setError("Solo se permiten números.");
    } else if (allowOnlyPositive && numericValue < 0) {
      setError("Solo se permiten números positivos.");
    } else {
      setError(null);
      onChange(event);
    }
  };
  

  return (
    <FormControl fullWidth margin="none">
      <TextField
        error={Boolean(error)}
        label={label}
        value={isNaN(Number(value)) ? "" : value}
        type="text"
        required={required}
        onChange={handleChange}
        disabled={disabled}
        helperText={error}
        inputProps={{ 'data-cy': dataCy }}
      />
    </FormControl>
  );
};

export default CustomNumberInput;
