import { useState } from "react";

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  Autocomplete,
  FormControl,
} from "@mui/material";

import { isValidPositiveFloatNumber, toFloat } from "helpers/formatNumber";
import { Location, Store } from "types";
import CustomNumberInput from "components/CustomNumberInput";

type Props = {
  open: boolean;
  handleOpen: () => void;
  onCreate: (newLocation: Location) => void;
  stores: Store[];
};

export default function AddLocationModal({
  open = true,
  handleOpen,
  onCreate,
  stores,
}: Props) {
  const defaultValues: Omit<Location, "id"> = {
    code: "",
    store_id: 0,
    width: 1.1,
    height: 1.2,
    long: 1.2,
    occupied: 0,
    store: stores[0],
  };

  const [data, setData] = useState<Omit<Location, "id">>(defaultValues);

  const [selectedStore, setSelectedStore] = useState<Pick<
    Store,
    "id" | "description"
  > | null>(null);

  const handleChange = <K extends keyof Omit<Location, "id" | "store">>(
    name: K,
    value: Omit<Location, "id">[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleClose = () => {
    setSelectedStore(null);
    setData(defaultValues);
    handleOpen();
  };

  const onSubmit = (newLocation: Omit<Location, "id">) => {
    const store = stores.find((store) => store.id === newLocation.store_id);
    if (!store) return;
    newLocation.store = store;
    onCreate(newLocation as Location);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sm: "90%", md: "30rem" },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant="h5" gutterBottom>
            Nueva Ubicación
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth required margin="none">
              <TextField
                label="Código"
                fullWidth
                onChange={(e) => handleChange("code", e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required margin="none">
              <Autocomplete
                id="store"
                autoComplete
                value={selectedStore}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={stores.map((store) => {
                  return {
                    id: store.id,
                    description: store.description,
                  };
                })}
                defaultValue={selectedStore}
                getOptionLabel={(option) => option.description}
                onChange={(
                  e,
                  value: Pick<Store, "id" | "description"> | null
                ) => {
                  if (!value) return;
                  handleChange("store_id", value.id);
                  setSelectedStore(value);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} required label="Almacen" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={3.5}>
                <FormControl fullWidth required margin="none">
                  <CustomNumberInput
                    label="Ancho"
                    value={data.width}
                    allowOnlyPositive
                    onChange={(e) =>
                      handleChange("width", toFloat(e.target.value))
                    }
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl fullWidth required margin="none">
                  <CustomNumberInput
                    label="Alto"
                    value={data.height}
                    allowOnlyPositive
                    onChange={(e) =>
                      handleChange("height", toFloat(e.target.value))
                    }
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl fullWidth required margin="none">
                  <CustomNumberInput
                    label="Largo"
                    value={data.long}
                    allowOnlyPositive
                    onChange={(e) =>
                      handleChange("long", toFloat(e.target.value))
                    }
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end" gap={1}>
            <Button type="button" variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={
                !isValidPositiveFloatNumber(data.width.toString()) ||
                !isValidPositiveFloatNumber(data.height.toString()) ||
                !isValidPositiveFloatNumber(data.long.toString()) ||
                data.code === "" ||
                !selectedStore
              }
              onClick={() => {
                onSubmit(data);
                handleClose();
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
