export type Order = 'asc' | 'desc';

export function orderBy<T, K extends keyof T>(
  data: T[],
  key: K,
  order: Order
): T[] {
  return data.sort((a, b) => {
    if (order === 'asc') {
      return a[key] > b[key] ? 1 : -1;
    } else {
      return a[key] < b[key] ? 1 : -1;
    }
  });
}
