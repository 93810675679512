import { ExcelCellProps, Point } from 'types';

export type SchemaValidationResult = {
  isValid: boolean;
  errors: {
    message: string;
    coordinates: Point;
  }[];
};

export const validateSchema = <
  T extends Record<string, unknown>,
  S extends Record<string, ExcelCellProps>
>(
  values: T[],
  schema: S
): SchemaValidationResult => {
  const result: SchemaValidationResult = {
    isValid: true,
    errors: [],
  };
  values.forEach((value, index_Y) => {
    const clonedValue: Record<string, any> = { ...value };

    Object.keys(schema).forEach((key, index_X) => {
      if (schema[key].required && !clonedValue[key]) {
        result.isValid = false;
        result.errors.push({
          message: `El campo "${key}" es requerido, (Celda: ${index_X}, ${index_Y})`,
          coordinates: {
            x: index_X,
            y: index_Y,
          },
        });
      }

      if (clonedValue[key] && schema[key].type === 'string' && typeof clonedValue[key] !== 'string') {
        clonedValue[key] = String(clonedValue[key]);
      }

      if (clonedValue[key] && typeof clonedValue[key] !== schema[key].type) {
        result.isValid = false;
        result.errors.push({
          message: `El campo "${key}" debe ser de tipo ${schema[key].type} y es de tipo ${typeof clonedValue[key]}, (Celda: ${index_X}, ${index_Y})`,
          coordinates: {
            x: index_X,
            y: index_Y,
          },
        });
      }

      if (schema[key].associations && clonedValue[key]) {
        const association = schema[key].associations?.find(
          associated_values => associated_values === clonedValue[key]
        );
        if (!association) {
          result.isValid = false;
          result.errors.push({
            message: `No existe "${clonedValue[key]}", (Celda: ${index_X}, ${index_Y})`,
            coordinates: {
              x: index_X,
              y: index_Y,
            },
          });
        }
      }
    });
  });
  return result;
};
