import PageLayout from 'components/layout/PageLayout';
import StockList from 'components/Home/StockList';

export default function HomePage() {
  return (
    <PageLayout
      pageTitle='Inicio'
      pageDesc='Bienvenido a SHC Logística! Consulte el stock aquí'
    >
      <StockList />
    </PageLayout>
  );
}
