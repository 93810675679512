import { ModalContext } from "providers/ConfirmationModalProvider";
import { useContext } from "react";
import { ModalContextData } from "types";

export const useConfirmationModal = (): ModalContextData => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useConfirmationModal must be used within a ModalProvider');
    }
    return context;
};
