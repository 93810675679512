import { axiosGet, axiosPost } from 'api';
import { USER_STORAGE_KEY, STATUS_CODES } from 'constants/index';
import { AUTH_LOGIN_URL, USERS_URL } from 'constants/urls';
import { useState } from 'react';
import { User } from 'types';
import useCrud from './useCrud';

export const useUser = () => {
  const [token, setToken] = useState(localStorage.getItem(USER_STORAGE_KEY));
  const {
    createData: updatePassword,
    loading: updateLoading,
    showToast,
    setShowToast,
    toastMessage,
    toastSeverity,
    setToastMessage,
  } = useCrud<{
    old_password: string;
    new_password: string;
  }>(USERS_URL + 'password', { disableAutoFetch: true });

  const getCurrentUser = async () => {
    const user = await axiosGet(USERS_URL + 'me');
    if (user.status !== STATUS_CODES.OK) {
      return null;
    }
    return user.data as User;
  };

  const setData = (tokens: { access_token: string; refresh_token: string }) => {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify({ ...tokens }));
    setToken(JSON.stringify({ ...tokens }));
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const response = await updatePassword(
        {
          old_password: oldPassword,
          new_password: newPassword,
        },
        true,
        true
      );
      if (response.status === STATUS_CODES.OK) {
        setToastMessage('Contraseña actualizada correctamente');
        return true;
      } else {
        setToastMessage('Error al actualizar la contraseña');
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const logIn = async (email: string, password: string) => {
    try {
      const response = await axiosPost(AUTH_LOGIN_URL, {
        email: email,
        password: password,
      });
      if (response.status === STATUS_CODES.OK) {
        setData(response.data);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const logOut = async () => {
    localStorage.removeItem(USER_STORAGE_KEY);
    setToken(null);
  };

  const isLoggedIn = () => {
    return !!token;
  };

  return {
    logIn,
    logOut,
    isLoggedIn,
    getCurrentUser,
    changePassword,
    updateLoading,
    token,
    showToast,
    setShowToast,
    toastMessage,
    toastSeverity,
  };
};
