export const ENTITY = {
  USERS: 'users',
  CLIENTS: 'clients',
  ASSOCIATED_CLIENTS: 'associated_clients',
  ARTICLES: 'articles',
  STORES: 'stores',
  ADDITIONAL_ITEMS: 'additional_items',
  CONTAINERS: 'containers',
  UNITS: 'units',
  SETTLEMENT_TYPES: 'settlement_types',
  CLIENT_SETTLEMENT_TYPES: 'client_settlement_types',
  MOVEMENTS: 'movements',
  MOVEMENT_ARTICLES: 'movement_articles',
  MOVEMENT_ADDITIONAL_ITEMS: 'movement_additional_items',
  MOVEMENT_TYPES: 'movement_types',
  LOCATIONS: 'locations',
  ARTICLE_LOCATIONS: 'article_locations',
  LIQUIDATIONS: 'liquidations',
};
