import { useState, useCallback, useMemo, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";

import CustomGrid from "components/lists/CustomGrid";
import { gridStructure } from "constants/CustomGridStructure";
import DeleteConfirmationModaL from "components/utils/DeleteConfirmationModal";
import { EnhancedTableToolbar } from "components/lists/EnhancedToolbar";
import AddMovementAdditionalItemModal from "./AddMovementAdditionalItemModal";
import EditAdditionalItemModal from "./EditAdditionalItemModal";

import { MovementAdditionalItem, AdditionalItem, Movement } from "types";
import { ENTITY } from "constants/entities";
import useModals from "hooks/useModals";
import { useToast } from "hooks/useToast";

type AdditionalItemsListProps = {
  additionalItems: AdditionalItem[];
  movement: Movement;
  onMovementUpdate: <K extends keyof Movement>(
    name: K,
    value: Movement[K]
  ) => void;
  rowIndex?: number;
  setRowIndex?: (index: number) => void;
};

export default function AdditionalItemsList({
  additionalItems,
  movement,
  onMovementUpdate,
  rowIndex = 0,
  setRowIndex,
}: AdditionalItemsListProps) {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const { addModalOpen, setAddModalOpen, deleteModalOpen, setDeleteModalOpen, editModalOpen, setEditModalOpen } = useModals();
  const { showSnackBar } = useToast();
  const [itemToEdit, setItemToEdit] = useState<MovementAdditionalItem | null>(null);

  const handleSetMovements = useCallback(
    (newMovement: MovementAdditionalItem) => {
      if (setRowIndex) {
        setRowIndex(rowIndex + 1);
      }
      onMovementUpdate("additional_items", [newMovement, ...movement.additional_items]);
      showSnackBar("Se ha agregado el ítem adicional correctamente", "success");
    },
    [rowIndex, setRowIndex, onMovementUpdate, movement.additional_items, showSnackBar]
  );

  const handleDeleteSelected = useCallback(() => {
    onMovementUpdate(
      "additional_items",
      movement.additional_items.filter(
        (item) => !selected.includes(item.id)
      )
    );
    setSelected([]);
  }, [movement.additional_items, selected, onMovementUpdate]);

  const handleEdit = useCallback(
    (movementAdditionalItemToEdit: MovementAdditionalItem) => {
      onMovementUpdate(
        "additional_items",
        movement.additional_items.map((item) =>
          item.id === movementAdditionalItemToEdit.id ? movementAdditionalItemToEdit : item
        )
      );
      setSelected([]);
    },
    [movement.additional_items, onMovementUpdate]
  );

  const deleteValues = useMemo(
    () => movement.additional_items
      .filter((item) => selected.includes(item.id))
      .map((item) => item.description),
    [movement.additional_items, selected]
  );

  const isToolbarDisabled = useMemo(
    () =>
      !movement.client_id ||
      movement.client_id === 0 ||
      !movement.associated_client ||
      !movement.date ||
      !movement.created_by ||
      !movement.updated_by ||
      !movement.client_document ||
      !movement.numero_remito,
    [movement]
  );

  useEffect(() => {
    if (itemToEdit) {
      setEditModalOpen(true);
    }
  }, [itemToEdit, setEditModalOpen]);

  return (
    <>
      <AddMovementAdditionalItemModal
        open={addModalOpen}
        handleOpen={() => setAddModalOpen(!addModalOpen)}
        additionalItems={additionalItems}
        rowIndex={rowIndex}
        movement={movement}
        handleAddMovementAdditionalItem={handleSetMovements}
      />
      <DeleteConfirmationModaL
        open={deleteModalOpen}
        handleOpen={() => setDeleteModalOpen(!deleteModalOpen)}
        entityName={ENTITY.ADDITIONAL_ITEMS}
        values={deleteValues}
        handleDelete={handleDeleteSelected}
      />
      {itemToEdit && (
        <EditAdditionalItemModal
          open={editModalOpen}
          handleOpen={() => setEditModalOpen(!editModalOpen)}
          value={itemToEdit}
          onEdit={handleEdit}
        />
      )}
      <Box width="100%">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            disabled={isToolbarDisabled}
            handleAddModalOpen={() => setAddModalOpen(!addModalOpen)}
            handleEditModalOpen={() => {
              if (selected.length === 0) {
                showSnackBar('Selecciona un ítem para editar', 'warning');
                return;
              }
              const item = movement.additional_items.find((c) => c.id === selected[0]);
              if (item) {
                setItemToEdit(item);
              }
            }}
            handleDeleteModalOpen={() => {
              if (selected.length === 0) {
                showSnackBar('Selecciona un ítem para eliminar', 'warning');
                return;
              }
              setDeleteModalOpen(true);
            }}
          />
          <CustomGrid<MovementAdditionalItem>
            structure={gridStructure[ENTITY.MOVEMENT_ADDITIONAL_ITEMS]}
            rows={movement.additional_items}
            onEdit={() => {
              const item = movement.additional_items.find((c) => c.id === selected[0]);
              if (item) {
                setItemToEdit(item);
              }
            }}
            selectionModel={selected}
            onRowSelection={setSelected}
          />
        </Paper>
      </Box>
    </>
  );
}
