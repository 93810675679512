/* eslint-disable react-hooks/exhaustive-deps */
import { ENTITY } from 'constants/entities';
import {
  ADDITIONAL_ITEMS_URL,
  ARTICLES_URL,
  ASSOCIATED_CLIENTS_URL,
  CLIENTS_URL,
  CONTAINERS_URL,
  LOCATIONS_URL,
  STORES_URL,
  UNITS_URL,
  USERS_URL,
} from 'constants/urls';
import useCrud from 'hooks/useCrud';
import { useUser } from 'hooks/useUser';
import { STATUS_CODES } from 'constants/index';

import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  FC,
  useEffect,
} from 'react';
import {
  AdditionalItem,
  AssociatedClient,
  Client,
  Container,
  Store,
  Unit,
  User,
  Location,
  Article,
} from 'types';

type UserContext = {
  userData: User | null;
  isLoadingUser: boolean;
  stores: Store[];
  additionalItems: AdditionalItem[];
  clients: Client[];
  units: Unit[];
  associatedClients: AssociatedClient[];
  containers: Container[];
  locations: Location[];
  articles: Article[];
  users: User[];
  addSessionData: (entity: string, data: SessionEntity) => void;
  addMultipleSessionData: (keys: string[], values: SessionEntity[]) => void;
  updateSessionData: (entity: string, data: SessionEntity) => void;
  updateMultipleSessionData: (entity: string[], data: SessionEntity[]) => void;
  deleteSessionData: (entity: string, ids: string[]) => void;
  logIn: (email: string, password: string) => Promise<boolean>;
  logOut: () => void;
  isLoggedIn: () => boolean;
};

export const SessionContext = createContext<UserContext>({
  userData: null,
  isLoadingUser: true,
  stores: [],
  additionalItems: [],
  clients: [],
  units: [],
  associatedClients: [],
  containers: [],
  locations: [],
  articles: [],
  users: [],
  addSessionData: (entity: string, data: SessionEntity) => {},
  addMultipleSessionData: (keys: string[], values: SessionEntity[]) => {},
  updateSessionData: (entity: string, data: SessionEntity) => {},
  updateMultipleSessionData: (keys: string[], values: SessionEntity[]) => {},
  deleteSessionData: (entity: string, ids: string[]) => {},
  logIn: (email: string, password: string) => Promise.resolve(false),
  logOut: () => {},
  isLoggedIn: () => false,
} as UserContext);

type SessionEntity =
  | Store
  | Client
  | AdditionalItem
  | Unit
  | AssociatedClient
  | Container
  | Location
  | Article
  | User;

type SessionEntities = SessionEntity[];

export const useSession = () => useContext(SessionContext);

export const SessionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<User | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true); // Estado de carga
  const [sessionData, setSessionData] = useState<Record<string, SessionEntities>>({
    [ENTITY.STORES]: [],
    [ENTITY.CLIENTS]: [],
    [ENTITY.ADDITIONAL_ITEMS]: [],
    [ENTITY.UNITS]: [],
    [ENTITY.ASSOCIATED_CLIENTS]: [],
    [ENTITY.CONTAINERS]: [],
    [ENTITY.LOCATIONS]: [],
    [ENTITY.ARTICLES]: [],
    [ENTITY.USERS]: [],
  });

  const { fetchData: getStores } = useCrud<Store>(STORES_URL, {
    disableAutoFetch: true,
  });
  const { fetchData: getAdditionalItems } = useCrud<AdditionalItem>(
    ADDITIONAL_ITEMS_URL,
    { disableAutoFetch: true }
  );
  const { fetchData: getClients } = useCrud<Client>(CLIENTS_URL, {
    disableAutoFetch: true,
  });
  const { fetchData: getUnits } = useCrud<Unit>(UNITS_URL, {
    disableAutoFetch: true,
  });
  const { fetchData: getAssociatedClients } = useCrud<AssociatedClient>(
    ASSOCIATED_CLIENTS_URL,
    { disableAutoFetch: true }
  );
  const { fetchData: getContainers } = useCrud<Container>(CONTAINERS_URL, {
    disableAutoFetch: true,
  });
  const { fetchData: getLocations } = useCrud<Location>(LOCATIONS_URL, {
    disableAutoFetch: true,
  });
  const { fetchData: getArticles } = useCrud<Article>(ARTICLES_URL, {
    disableAutoFetch: true
  });
  const { fetchData: getUsers } = useCrud<User>(USERS_URL, {
    disableAutoFetch: true,
  });

  const addSessionData = (entity: string, data: SessionEntity) => {
    setSessionData({
      ...sessionData,
      [entity]: sessionData[entity].concat(data),
    });
  };

  const addMultipleSessionData = (keys: string[], values: SessionEntity[]) => {
    const newSessionData = { ...sessionData };
    keys.forEach((key, index) => {
      newSessionData[key] = newSessionData[key].concat(values[index]);
    });
    setSessionData(newSessionData);
  };

  const updateSessionData = (entity: string, data: SessionEntity) => {
    setSessionData({
      ...sessionData,
      [entity]: sessionData[entity].map((item: SessionEntity) =>
        item.id === data.id ? data : item
      ),
    });
  };

  const updateMultipleSessionData = (keys: string[], values: SessionEntity[]) => {
    const newSessionData = { ...sessionData };
    keys.forEach((key, index) => {
      newSessionData[key] = newSessionData[key].map((item: SessionEntity) =>
        item.id === values[index].id ? values[index] : item
      );
    });
    setSessionData(newSessionData);
  };

  const deleteSessionData = (entity: string, ids: string[]) => {
    setSessionData({
      ...sessionData,
      [entity]: sessionData[entity].filter(
        (item: SessionEntity) => !ids.includes(item.id.toString())
      ),
    });
  };

  const { getCurrentUser, token, logIn, logOut, isLoggedIn } = useUser();

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoadingUser(true);
      const user = await getCurrentUser();
      if (user) {
        setUserData(user);
      } else {
        setUserData(null);
      }
      setIsLoadingUser(false);
    };

    if (token) {
      fetchUserData();
    } else {
      setUserData(null);
      setIsLoadingUser(false);
    }
  }, [token]);

  const initSessionData = async () => {
    const responses = await Promise.all([
      getStores(),
      getAdditionalItems(),
      getClients(),
      getUnits(),
      getAssociatedClients(),
      getContainers(),
      getLocations(),
      getArticles(),
      getUsers(),
    ]);

    const [
      stores,
      additionalItems,
      clients,
      units,
      associatedClients,
      containers,
      locations,
      articles,
      users,
    ] = responses.map(response => {
      if (response.status === STATUS_CODES.OK) {
        return response.data;
      }
      return [];
    });

    setSessionData({
      [ENTITY.STORES]: stores,
      [ENTITY.CLIENTS]: clients,
      [ENTITY.ADDITIONAL_ITEMS]: additionalItems,
      [ENTITY.UNITS]: units,
      [ENTITY.ASSOCIATED_CLIENTS]: associatedClients,
      [ENTITY.CONTAINERS]: containers,
      [ENTITY.LOCATIONS]: locations,
      [ENTITY.ARTICLES]: articles,
      [ENTITY.USERS]: users,
    });
  };

  useEffect(() => {
    if (token) {
      initSessionData();
    }
  }, [token]);

  return (
    <SessionContext.Provider
      value={{
        userData,
        isLoadingUser,
        stores: sessionData[ENTITY.STORES] as Store[],
        additionalItems: sessionData[ENTITY.ADDITIONAL_ITEMS] as AdditionalItem[],
        clients: sessionData[ENTITY.CLIENTS] as Client[],
        units: sessionData[ENTITY.UNITS] as Unit[],
        associatedClients: sessionData[ENTITY.ASSOCIATED_CLIENTS] as AssociatedClient[],
        containers: sessionData[ENTITY.CONTAINERS] as Container[],
        locations: sessionData[ENTITY.LOCATIONS] as Location[],
        articles: sessionData[ENTITY.ARTICLES] as Article[],
        users: sessionData[ENTITY.USERS] as User[],
        addSessionData,
        addMultipleSessionData,
        updateSessionData,
        updateMultipleSessionData,
        deleteSessionData,
        logIn,
        logOut,
        isLoggedIn,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
