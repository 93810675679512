import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import OperationPrintDetails from 'components/Prints/Operations/OperationPrintDetails';
import OperationPrintHeaderDetails from 'components/Prints/Operations/OperationPrintHeaderDetails';
import { PrintLayout } from 'components/Prints/PrintLayout';
import { MovementTypes } from 'constants/index';
import { dateToArgentinianTime } from 'helpers/utcFormatter';
import React, { createRef } from 'react';
import { MdPrint } from 'react-icons/md';
import ReactToPrint from 'react-to-print';
import { Movement, Store } from 'types';

type Props = {
  movement: Movement;
  stores: Store[];
};

const operationTypeName = (operationType: number) => {
  switch (operationType) {
    case MovementTypes.Income:
      return 'Ingreso';
    case MovementTypes.Discharge:
      return 'Egreso';
    case MovementTypes.Additional:
      return 'Adicionales';
    case MovementTypes.Preparation:
      return 'Pedido a preparar';
    case MovementTypes.Transfer:
      return 'Transferencia';
    default:
      return '-';
  }
};

const operationTypeChipColor = (operationType: number) => {
  switch (operationType) {
    case MovementTypes.Income:
      return 'success';
    case MovementTypes.Discharge:
      return 'error';
    case MovementTypes.Additional:
      return 'info';
    case MovementTypes.Preparation:
      return 'warning';
    case MovementTypes.Transfer:
      return 'secondary';
    default:
      return 'default';
  }
};

export default function OperationsLogsCard({ movement, stores }: Props) {
  const printRef: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  return (
    <React.Fragment key={movement.id}>
      <PrintLayout
        ref={printRef}
        headerInfo={
          <OperationPrintHeaderDetails movement={movement} stores={stores} />
        }
      >
        <OperationPrintDetails movement={movement} />
      </PrintLayout>
      <Grid item xs={12}>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Chip
              label={operationTypeName(movement.movement_type_id)}
              color={operationTypeChipColor(movement.movement_type_id)}
            />
            <ReactToPrint
              content={() => printRef.current}
              copyStyles={true}
              trigger={() => (
                <IconButton size='small'>
                  <MdPrint />
                </IconButton>
              )}
            />
          </Box>
          <Typography variant='body2' fontWeight={600}>
            Datos de la Operación
          </Typography>
          <TableContainer component={Paper} elevation={4} sx={{ p: 1 }}>
            <Table>
              <TableHead
                sx={{
                  '*': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                  },
                }}
              >
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Numero de Remito</TableCell>
                  <TableCell>Operación</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Cliente Asociado</TableCell>
                  <TableCell>Almacén</TableCell>
                  <TableCell>Peso Total</TableCell>
                  <TableCell>Pallets Totales</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover>
                  <TableCell>{dateToArgentinianTime(movement.date)}</TableCell>
                  <TableCell>{movement.numero_remito}</TableCell>
                  <TableCell>
                    {operationTypeName(movement.movement_type_id)}
                  </TableCell>
                  <TableCell>{movement.client?.razon_social}</TableCell>
                  <TableCell>{movement.associated_client}</TableCell>
                  <TableCell>
                    {stores.find(store => movement.store_id === store.id)
                      ?.description || '-'}
                  </TableCell>
                  <TableCell>
                    {movement.movement_type_id === MovementTypes.Income ||
                    movement.movement_type_id === MovementTypes.Discharge ||
                    movement.movement_type_id === MovementTypes.Preparation
                      ? movement.weight
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {movement.movement_type_id === MovementTypes.Income ||
                    movement.movement_type_id === MovementTypes.Discharge ||
                    movement.movement_type_id === MovementTypes.Preparation
                      ? movement.pallets
                      : 'N/A'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {movement.articles.length > 0 && (
            <>
              <Typography variant='body2' fontWeight={600}>
                Listado de Productos
              </Typography>
              <TableContainer component={Paper} elevation={4} sx={{ p: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        '*': {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textWrap: 'nowrap',
                        },
                      }}
                    >
                      <TableCell>Código de Articulo</TableCell>
                      <TableCell>Cantidad</TableCell>
                      <TableCell>Descripción</TableCell>
                      <TableCell>Cantidad por Contenedor</TableCell>
                      <TableCell>UM</TableCell>
                      <TableCell>Contenedor</TableCell>
                      <TableCell>Código de Cliente</TableCell>
                      <TableCell>Lote</TableCell>
                      <TableCell>Subtotal (Kg)</TableCell>
                      <TableCell>Subtotal (Un)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movement.articles.map((movementArticle, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{movementArticle.code}</TableCell>
                        <TableCell>{movementArticle.amount}</TableCell>
                        <TableCell>{movementArticle.description}</TableCell>
                        <TableCell>
                          {movementArticle.amount_per_container}
                        </TableCell>
                        <TableCell>
                          {
                            movementArticle.article_locations.article.container
                              .description
                          }
                        </TableCell>
                        <TableCell>
                          {
                            movementArticle.article_locations.article.unit
                              .description
                          }
                        </TableCell>
                        <TableCell>{movement.client?.razon_social}</TableCell>
                        <TableCell>{movementArticle.lote}</TableCell>
                        <TableCell>{movementArticle.subtotal_kg}</TableCell>
                        <TableCell>{movementArticle.subtotal}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {movement.additional_items.length > 0 && (
            <>
              <Typography variant='body2' fontWeight={600}>
                Listado de Adicionales
              </Typography>
              <TableContainer component={Paper} elevation={4} sx={{ p: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Descripción</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Subtotal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movement.additional_items.map(movementAdditionalItem => (
                      <TableRow hover>
                        <TableCell>
                          {movementAdditionalItem.additional_item?.description}
                        </TableCell>
                        <TableCell>
                          {movementAdditionalItem.additional_item?.client_id ||
                            'Todos'}
                        </TableCell>
                        <TableCell>{movementAdditionalItem.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
