import { Grid, Typography } from '@mui/material';
import { MdConstruction } from 'react-icons/md';

export default function SoonPlaceholder() {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap={3}
      >
        <MdConstruction size={40} />
        <Typography variant='h4' textAlign='center'>
          Próximamente
        </Typography>
        <MdConstruction size={40} />
      </Grid>
    </Grid>
  );
}
