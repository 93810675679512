import { useEffect, useState } from 'react';

import PageLayout from '../components/layout/PageLayout';

import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';

import OperationsLogs from 'components/OperationsPage/OperationsLogs';
import { DATE_VIEWS, MovementTypes } from 'constants/index';
import { MOVEMENTS_URL } from 'constants/urls';
import { queryBuilder, urlParamsToObject } from 'helpers/query';
import useCrud from 'hooks/useCrud';
import { useSession } from 'providers/SessionProvider';
import { BsFillFilterCircleFill } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { Article, AssociatedClient, IMovementsQuery, Movement } from 'types';
import DateTimePickerActionBar from 'components/utils/DatePickerActionBar';
import Alert from 'components/utils/Alert';
import PaginationBar from 'components/utils/PaginationBar';

const FiltersIcon = styled(BsFillFilterCircleFill)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export default function OperationsPage() {
  //@search-params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = urlParamsToObject(searchParams);
  const [currentPage, setCurrentPage] = useState<number>(1);

  //@context
  const { stores, clients, associatedClients, articles } = useSession();

  //@filters
  const [client, setClient] = useState<{ id: number; label: string } | null>(
    null
  );
  const [associatedClient, setAssociatedClient] = useState<{
    id: number;
    label: string;
  } | null>(null);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [warehouse, setWarehouse] = useState<{
    id: number;
    label: string;
  } | null>(null);
  const [article, setArticle] = useState<{
    id: number;
    label: string;
  } | null>(null);
  const [operationType, setOperationType] = useState<number | null>(null);
  const [includeNullOperations, setIncludeNullOperations] =
    useState<boolean>(false);
  // const [group, setGroup] = useState<{ id: number; label: string } | null>(
  //   null
  // );

  const [clientAssociatedClients, setClientAssociatedClients] = useState<
    AssociatedClient[]
  >([]);
  const [clientArticles, setClientArticles] = useState<Article[]>([]);

  const {
    data: movements,
    count: totalCount,
    fetchData: getMovements,
    loading: loadingMovements,
  } = useCrud<Movement>(MOVEMENTS_URL, {
    disableAutoFetch: true,
  });
  const onPageChange = (page: number) => {
    setCurrentPage(page);
    getFilteredMovements(page);
    setSearchParams(prevParams => ({
      ...prevParams,
      ...(client && { ...prevParams, client: client.id }),
      ...(associatedClient && {
        ...prevParams,
        as_client: associatedClient.label,
      }),
      //...(group && { ...prevParams, group: group.id }),
      ...(dateFrom && { ...prevParams, from: dateFrom.toISOString() }),
      ...(dateTo && { ...prevParams, to: dateTo.toISOString() }),
      ...(warehouse && { ...prevParams, warehouse: warehouse.id }),
      ...(article && { ...prevParams, article: article.id }),
      ...(operationType && { ...prevParams, mov_type: operationType }),
      ...(includeNullOperations && {
        ...prevParams,
        null_ops: includeNullOperations,
      }),
      ...{ ...prevParams, page: page },
    }));
  };

  const getFilteredMovements = async (page: number) => {
    const query = queryBuilder({
      clientId: client?.id,
      associatedClient: associatedClient?.label,
      fromDate: dateFrom?.toISOString(),
      toDate: dateTo?.toISOString(),
      storeId: warehouse?.id,
      articleId: article?.id,
      movementTypeId: operationType,
      page: page,
    } as IMovementsQuery);
    getMovements(query, true, {}, true);
  };

  useEffect(() => {
    if (client?.id) {
      setClientAssociatedClients(
        associatedClients.filter(ac => ac.client?.id === client.id)
      );
      setClientArticles(articles.filter(a => a.client.id === client.id));
    } else {
      setArticle(null);
    }
    setCurrentPage(1);
    setAssociatedClient(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (params.client) {
      setClient({
        id: parseInt(params.client),
        label:
          clients.find(c => c.id === parseInt(params.client))?.razon_social ||
          '',
      });
    }
    if (params.as_client) {
      setAssociatedClient({
        id:
          associatedClients.find(ac => ac.razon_social === params.as_client)
            ?.id || 0,
        label: params.as_client,
      });
    }
    if (params.article) {
      setArticle({
        id: parseInt(params.article),
        label:
          articles.find(article => article.id === parseInt(params.article))
            ?.description || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    getFilteredMovements(1);
    setSearchParams(prevParams => ({
      ...prevParams,
      ...(client && { ...prevParams, client: client.id }),
      ...(associatedClient && {
        ...prevParams,
        as_client: associatedClient.label,
      }),
      //...(group && { ...prevParams, group: group.id }),
      ...(dateFrom && { ...prevParams, from: dateFrom.toISOString() }),
      ...(dateTo && { ...prevParams, to: dateTo.toISOString() }),
      ...(warehouse && { ...prevParams, warehouse: warehouse.id }),
      ...(article && { ...prevParams, article: article.id }),
      ...(operationType && { ...prevParams, mov_type: operationType }),
      ...(includeNullOperations && {
        ...prevParams,
        null_ops: includeNullOperations,
      }),
      ...{ ...prevParams, page: 1 },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client,
    associatedClient,
    //group,
    dateFrom,
    dateTo,
    warehouse,
    article,
    operationType,
    includeNullOperations,
    //currentPage
  ]);

  return (
    <PageLayout
      pageTitle='Movimientos'
      pageDesc='Movimientos de ingresos, egresos y adicionales'
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          p: 2,
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiltersIcon />
          <Typography variant='h6'>Filtros</Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Autocomplete
              id='client'
              autoComplete
              disabled={loadingMovements}
              value={client}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={clients.map(c => ({ id: c.id, label: c.razon_social }))}
              onChange={(e, value: { id: number; label: string } | null) => {
                setAssociatedClient(null);
                setClient(value);
              }}
              fullWidth
              renderInput={params => (
                <TextField {...params} required label='Cliente' />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              id='associated-client'
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              options={clientAssociatedClients.map(ac => ({
                id: ac.id,
                label: ac.razon_social,
              }))}
              disabled={!client || loadingMovements}
              value={associatedClient}
              onChange={(e, value: any) => setAssociatedClient(value)}
              fullWidth
              renderInput={params => (
                <TextField {...params} label='Cliente Asociado' />
              )}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <Autocomplete
              id='group'
              options={[
                { id: 1, label: 'Grupo 1' },
                { id: 2, label: 'Grupo 2' },
                { id: 3, label: 'Grupo 3' },
              ]}
              disabled={!client || loadingMovements}
              value={group}
              onChange={(e, value: any) => setGroup(value)}
              fullWidth
              renderInput={params => <TextField {...params} label='Grupo' />}
            />
          </Grid> */}
          <Grid item xs={3}>
            <DateTimePicker
              sx={{ width: '100%' }}
              disableFuture
              label='Desde'
              value={dateFrom}
              disabled={loadingMovements}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              slots={{
                actionBar: DateTimePickerActionBar,
              }}
              onChange={(value: Date | null) => setDateFrom(value)}
              views={DATE_VIEWS}
            />
          </Grid>
          <Grid item xs={3}>
            <DateTimePicker
              sx={{ width: '100%' }}
              disableFuture
              label='Hasta'
              value={dateTo}
              disabled={loadingMovements}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              slots={{
                actionBar: DateTimePickerActionBar,
              }}
              onChange={(value: any) => setDateTo(value)}
              views={DATE_VIEWS}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              id='warehouse'
              autoComplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={stores.map(s => ({ id: s.id, label: s.description }))}
              value={warehouse}
              disabled={loadingMovements}
              onChange={(e, value: any) => {
                setWarehouse(value);
              }}
              fullWidth
              renderInput={params => <TextField {...params} label='Almacen' />}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              id='article'
              autoComplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={clientArticles.map(a => ({
                id: a.id,
                label: a.description,
              }))}
              value={article}
              disabled={loadingMovements || !client}
              onChange={(e, value: any) => {
                setArticle(value);
              }}
              fullWidth
              renderInput={params => <TextField {...params} label='Artículo' />}
            />
          </Grid>
          <Grid item xs={8}>
            <FormControl>
              <FormLabel
                id='operation-type-label'
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: '0.8rem',
                  },
                }}
              >
                Tipo de movimiento
              </FormLabel>
              <RadioGroup
                aria-labelledby='operation-type-label'
                value={operationType}
                name='operation-type'
                row
              >
                <FormControlLabel
                  value={MovementTypes.Income}
                  control={<Radio disabled={loadingMovements} size='small' />}
                  label='Ingreso'
                  onClick={() => setOperationType(MovementTypes.Income)}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.8rem',
                    },
                  }}
                />
                <FormControlLabel
                  value={MovementTypes.Discharge}
                  control={<Radio disabled={loadingMovements} size='small' />}
                  label='Egreso'
                  onClick={() => setOperationType(MovementTypes.Discharge)}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.8rem',
                    },
                  }}
                />
                <FormControlLabel
                  value={MovementTypes.Additional}
                  control={<Radio disabled={loadingMovements} size='small' />}
                  label='Adicionales'
                  onClick={() => setOperationType(MovementTypes.Additional)}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.8rem',
                    },
                  }}
                />
                <FormControlLabel
                  value={MovementTypes.Preparation}
                  control={<Radio disabled={loadingMovements} size='small' />}
                  label='Preparación'
                  onClick={() => setOperationType(MovementTypes.Preparation)}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.8rem',
                    },
                  }}
                />
                <FormControlLabel
                  value={MovementTypes.Transfer}
                  control={<Radio disabled={loadingMovements} size='small' />}
                  label='Transferencias'
                  onClick={() => setOperationType(MovementTypes.Transfer)}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.8rem',
                    },
                  }}
                />
                <FormControlLabel
                  value={null}
                  control={<Radio disabled={loadingMovements} size='small' />}
                  label='Todos'
                  onClick={() => setOperationType(null)}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.8rem',
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <FormLabel id='operation-type-label'>Estado</FormLabel>
              <FormControlLabel
                value={includeNullOperations}
                onChange={(e, checked) => setIncludeNullOperations(checked)}
                control={
                  <Checkbox
                    disabled={loadingMovements}
                    checked={includeNullOperations}
                    size='small'
                  />
                }
                label='Incluir movimientos anulados'
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: '0.8rem',
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Grid container my={2}>
        {loadingMovements && (
          <Grid display='flex' justifyContent='center' item xs={12}>
            <CircularProgress />
          </Grid>
        )}
        <Grid item xs={12}>
          {client && movements ? (
            movements.length !== 0 ? (
              <Grid container gap={4}>
                <Grid item xs={12}>
                  <OperationsLogs
                    movements={movements}
                    filters={{
                      client,
                      associatedClient,
                      //group,
                      dateFrom,
                      dateTo,
                      warehouse,
                      operationType,
                      includeNullOperations,
                    }}
                  />
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  <PaginationBar
                    pageSize={10}
                    currentPage={currentPage}
                    totalCount={totalCount}
                    isDisabled={loadingMovements || movements.length === 0}
                    onPageChange={onPageChange}
                  />
                </Grid>
              </Grid>
            ) : (
              <Alert
                severity={'warning'}
                text={'No hay resultados para los filtros seleccionados'}
              />
            )
          ) : (
            <Alert
              severity={'info'}
              text={'Seleccione un cliente para visualizar sus movimientos'}
            />
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
}
