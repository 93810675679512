import { useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { Unit } from 'types';

type Props = {
  open: boolean;
  handleOpen: () => void;
  onCreate: (newUnit: Unit) => void;
};

export default function AddUnitModal({
  open = true,
  handleOpen,
  onCreate,
}: Props) {
  const defaultValues: Omit<Unit, 'id'> = {
    description: '',
    is_active: true,
  };

  const [data, setData] = useState<Omit<Unit, 'id'>>(defaultValues);

  const handleChange = <K extends keyof Omit<Unit, 'id'>>(
    name: K,
    value: Omit<Unit, 'id'>[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = (newUnit: Omit<Unit, 'id'>) => {
    onCreate(newUnit as Unit);
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Nueva Unidad de Medida
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.is_active}
                  onChange={(e) => handleChange("is_active", e.target.checked)}
                  color="primary"
                />
              }
              label="Unidad de medida activa"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Descripción'
              type='text'
              fullWidth
              required
              onChange={e => handleChange('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                setData(defaultValues);
                handleOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              onClick={() => {
                onSubmit(data);
                setData(defaultValues);
                handleOpen();
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
