import { useState, useEffect } from 'react';
import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
} from '@mui/material';

import { MovementAdditionalItem } from 'types';

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: MovementAdditionalItem;
  onEdit: (movement: MovementAdditionalItem) => void;
};

export default function EditAdditionalItemModal({
  open = true,
  handleOpen,
  value,
  onEdit,
}: Props) {
  const [data, setData] = useState<MovementAdditionalItem>(value);

  useEffect(() => {
    setData(value);
  }, [value]);

  const handleChange = (name: keyof MovementAdditionalItem, value: string | number) => {
    setData({
      ...data,
      [name]: name === 'amount' ? Number(value) : value,
    });
  };

  const onSubmit = () => {
    if (data.amount > 0) {
      onEdit(data);
      handleOpen();
    } else {
      alert('La cantidad debe ser mayor que 0');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOpen}
      aria-labelledby="edit-additional-item-modal-title"
      aria-describedby="edit-additional-item-modal-description"
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography id="edit-additional-item-modal-title" variant="h5" gutterBottom>
            Editar Ítem Adicional
          </Typography>
          <Grid item xs={12}>
            <TextField
              required
              value={data?.additional_item_id}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Descripción"
              fullWidth
              value={data?.description}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Cantidad"
              fullWidth
              value={data?.amount}
              onChange={(e) => handleChange("amount", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end" gap={1}>
            <Button
              type="button"
              variant="outlined"
              onClick={handleOpen}
              aria-label="Cancelar edición del ítem adicional"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={onSubmit}
              aria-label="Guardar cambios del ítem adicional"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
