import { useEffect, useState } from 'react';
import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
} from '@mui/material';

import { AdditionalItem } from 'types';
import { isValidPositiveFloatNumber, toFloat } from 'helpers/formatNumber';

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: AdditionalItem;
  onEdit: (additionalItem: AdditionalItem) => void;
};

export default function EditAdditionalItemModal({
  open = true,
  handleOpen,
  value,
  onEdit,
}: Props) {
  const [data, setData] = useState<AdditionalItem>(value);

  useEffect(() => {
    if (open) {
      setData(value);
    }
  }, [open, value]);

  const handleChange = <K extends keyof Omit<AdditionalItem, 'id'>>(
    name: K,
    value: Omit<AdditionalItem, 'id'>[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = (updatedAdditionalItem: AdditionalItem) => {
    onEdit(updatedAdditionalItem);
    handleOpen();
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Editar ítem adicional
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id='outlined-required'
              label='Descripción'
              fullWidth
              value={data.description}
              onChange={e => handleChange('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Precio'
              fullWidth
              type='text'
              required
              value={data.price.toString()}
              error={!isValidPositiveFloatNumber(data.price.toString())}
              helperText={
                !isValidPositiveFloatNumber(data.price.toString())
                  ? 'El precio debe ser un número positivo'
                  : ''
              }
              onChange={e => handleChange('price', toFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                handleOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              onClick={() => {
                onSubmit(data);
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}