import PageLayout from '../components/layout/PageLayout';
import AdditionalItemsList from 'components/lists/additional_items/AdditionalItemsList';

export default function AdditionalItemsPage() {
  return (
    <PageLayout
      pageTitle='Ítems Adicionales'
      pageDesc='Altas, bajas y modificaciones.'
    >
      <AdditionalItemsList />
    </PageLayout>
  );
}
