import { useEffect, useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
} from '@mui/material';

import { Location } from 'types';

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: Location;
  onEdit: (location: Location) => void;
};

export default function EditLocationModal({
  open = true,
  handleOpen,
  value,
  onEdit,
}: Props) {
  const [data, setData] = useState<Location>(value);

  useEffect(() => {
    if (open) {
      setData(value);
    }
  }, [open, value]);

  const handleChange = (name: string, value: any) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = (updatedLocation: Location) => {
    onEdit(updatedLocation);
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Editar ubicación
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id='outlined-required'
              label='Código'
              fullWidth
              value={data.code}
              onChange={e => handleChange('code', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item xs={3.5}>
                <TextField
                  required
                  id='outlined-required'
                  label='Ancho'
                  fullWidth
                  type='number'
                  value={data.width}
                  onChange={e => handleChange('width', e.target.value)}
                />
              </Grid>
              <Grid item xs={3.5}>
                <TextField
                  required
                  id='outlined-required'
                  label='Alto'
                  fullWidth
                  type='number'
                  value={data.height}
                  onChange={e => handleChange('height', e.target.value)}
                />
              </Grid>
              <Grid item xs={3.5}>
                <TextField
                  required
                  id='outlined-required'
                  label='Largo'
                  fullWidth
                  type='number'
                  value={data.long}
                  onChange={e => handleChange('long', e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                handleOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              onClick={() => {
                onSubmit(data);
                handleOpen();
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}