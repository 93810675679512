import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import { useNavigate, useRouteError } from 'react-router-dom';
import SHCLogo from 'assets/imgs/shc-logo.png';

import { TbFaceIdError } from 'react-icons/tb';
import { ROUTES } from 'constants/index';

export const ImageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  img: {
    width: '15rem',
    height: 'auto',
  },
  textAlign: 'center',
}));

export default function RouteErrorPage() {
  const routeError: any = useRouteError();
  const navigate = useNavigate();

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      height='100vh'
    >
      <Paper
        elevation={8}
        sx={{
          marginTop: '4rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1rem',
        }}
      >
        <ImageContainer>
          <img src={SHCLogo} alt='Logo SHC' />
        </ImageContainer>
        <Box display='flex' justifyContent='center' py={2}>
          <TbFaceIdError size={75} />
        </Box>
        <Card>
          <CardContent
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Typography variant='h5' textAlign='center'>
              Error en la ruta ingresada
            </Typography>
            <Alert severity='error'>
              <Typography variant='body1'>{`${routeError.status}: ${routeError.statusText}`}</Typography>
              <Typography variant='body2'>
                Mensaje: "{routeError.error?.message}"
              </Typography>
            </Alert>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant='outlined' onClick={() => navigate(ROUTES.HOME)}>
              Volver al Inicio
            </Button>
          </CardActions>
        </Card>
      </Paper>
    </Box>
  );
}
