import { Alert, Snackbar } from '@mui/material';
import { FC, ReactNode, createContext, useCallback, useState } from 'react';
import { Severity, ToastContextData } from 'types';

export const ToastContext = createContext<ToastContextData | undefined>(
  undefined
);

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<Severity>('success');

  const showSnackBar = useCallback((message: string, severity: Severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }, []);

  const hideSnackBar = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ToastContext.Provider value={{ showSnackBar, hideSnackBar }}>
      {children}
      <Snackbar
        open={open}
        onClose={hideSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={4000}
      >
        <Alert
          onClose={hideSnackBar}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
