import { useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  Autocomplete,
  Divider,
} from '@mui/material';

import { Article, Client, Container, Unit } from 'types';
import { isValidPositiveFloatNumber, toFloat } from 'helpers/formatNumber';
import { STATUS_CODES } from 'constants/index';
import CustomNumberInput from 'components/CustomNumberInput';

type Props = {
  open: boolean;
  handleOpen: () => void;
  onCreate: (newArticle: Article, hasNewContainer?: boolean) => void;
  containers: Container[];
  clients: Client[];
  units: Unit[];
  onNewContainerCreate: (
    newContainer: Container
  ) => Promise<{ status: any; data: Container }>;
};

export default function AddArticleModal({
  open = true,
  handleOpen,
  onCreate,
  containers,
  clients,
  units,
  onNewContainerCreate,
}: Props) {
  const defaultArticleValues: Omit<Article, 'id'> = {
    client_id: 0,
    client: clients[0],
    code: '',
    container_id: 0,
    container: containers[0],
    unit_id: 0,
    unit: units[0],
    description: '',
    weight: 0,
    volume_weight: 0,
    amount: 0,
    amount_per_container: 0,
  };

  const defaultContainerValues: Omit<Container, 'id'> = {
    description: '',
    width: 0,
    height: 0,
    long: 0,
    is_active: true,
  };

  const [articleData, setArticleData] =
    useState<Omit<Article, 'id'>>(defaultArticleValues);
  const [containerData, setContainerData] = useState<Omit<Container, 'id'>>(
    defaultContainerValues
  );

  const [selectedClient, setSelectedClient] = useState<Pick<
    Client,
    'id' | 'razon_social' | 'cuit'
  > | null>(null);
  const [selectedContainer, setSelectedContainer] = useState<Pick<
    Container,
    'id' | 'description'
  > | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<Pick<
    Unit,
    'id' | 'description'
  > | null>(null);

  const handleChangeArticle = <
    K extends keyof Omit<Article, 'id' | 'container' | 'client' | 'unit'>
  >(
    name: K,
    value: string | Omit<Article, 'id' | 'container' | 'client' | 'unit'>[K]
  ) => {
    setArticleData({
      ...articleData,
      [name]: value,
    });
  };

  const handleChangeContainer = <K extends keyof Omit<Container, 'id'>>(
    name: K,
    value: Omit<Container, 'id'>[K]
  ) => {
    setContainerData({
      ...containerData,
      [name]: value,
    });
  };

  const handleClose = () => {
    setSelectedClient(null);
    setSelectedContainer(null);
    setSelectedUnit(null);
    setArticleData(defaultArticleValues);
    setContainerData(defaultContainerValues);
    handleOpen();
  };

  const onSubmit = async (newArticle: Omit<Article, 'id'>) => {
    let container = containers.find(
      container => container.id === articleData.container_id
    );
    const client = clients.find(client => client.id === newArticle.client_id);
    const unit = units.find(unit => unit.id === newArticle.unit_id);

    if (selectedContainer?.id === 0) {
      const response = await onNewContainerCreate(containerData as Container);
      if (response.status === STATUS_CODES.OK) {
        container = response.data;
        newArticle.container_id = response.data.id;
      }
    }
    if (!container || !client || !unit) return;

    onCreate(
      {
        ...articleData,
        container,
        client,
        unit,
      } as Article,
      selectedContainer?.id === 0 ? true : false
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            sm: '90%',
            md: selectedContainer?.id === 0 ? '60rem' : '30rem',
          },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
        }}
      >
        <Grid
          container
          columnSpacing={2}
          component='form'
          onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit(articleData);
            handleClose();
          }}
        >
          <Grid
            item
            xs={selectedContainer?.id === 0 ? 6 : 12}
            width={selectedContainer?.id === 0 ? '15rem' : '100%'}
          >
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <Typography variant='h5' gutterBottom>
                  Nuevo Producto
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin='none'>
                  <TextField
                    required
                    label='Descripción'
                    fullWidth
                    onChange={e =>
                      handleChangeArticle('description', e.target.value)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin='none'>
                  <TextField
                    required
                    label='Código'
                    fullWidth
                    onChange={e => handleChangeArticle('code', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} py={1}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin='none'>
                  <CustomNumberInput
                    label='Peso'
                    required
                    value={articleData.weight}
                    onChange={e =>
                      handleChangeArticle('weight', e.target.value)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin='none'>
                  <CustomNumberInput
                    label='Peso Volumétrico'
                    required
                    value={articleData.volume_weight}
                    onChange={e =>
                      handleChangeArticle('volume_weight', e.target.value)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} py={1}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin='none'>
                  <CustomNumberInput
                    label='Cantidad por contenedor'
                    required
                    value={articleData.amount_per_container}
                    onChange={e =>
                      handleChangeArticle('amount_per_container', toFloat(e.target.value))
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin='none'>
                  <CustomNumberInput
                    label='Unidades por pallet'
                    required
                    value={articleData.units_per_pallet || ''}
                    onChange={e =>
                      handleChangeArticle('units_per_pallet', toFloat(e.target.value))
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} display={'flex'} columnGap={1}>
                <FormControl fullWidth margin='none'>
                  <Autocomplete
                    id='unit'
                    autoComplete
                    value={selectedUnit}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={units.map(unit => {
                      return {
                        id: unit.id,
                        description: unit.description,
                      };
                    })}
                    getOptionLabel={option => option.description}
                    onChange={(
                      e,
                      value: Pick<Unit, 'id' | 'description'> | null
                    ) => {
                      if (!value) return;
                      handleChangeArticle('unit_id', value.id);
                      setSelectedUnit(value);
                    }}
                    fullWidth
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label='Unidad Operativa'
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin='none'>
                  <Autocomplete
                    id='container'
                    autoComplete
                    value={selectedContainer}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option => option.description}
                    options={containers
                      .map(c => {
                        return {
                          id: c.id,
                          description: c.description,
                        };
                      })
                      .concat({ id: 0, description: 'Nuevo Contenedor' })}
                    onChange={(
                      e,
                      value: Pick<Container, 'id' | 'description'> | null
                    ) => {
                      if (!value) return;
                      handleChangeArticle('container_id', value.id);
                      setSelectedContainer(value);
                    }}
                    fullWidth
                    renderInput={params => (
                      <TextField {...params} required label='Contenedor' />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin='none'>
                  <Autocomplete
                    id='client'
                    autoComplete
                    value={selectedClient}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option => option.razon_social}
                    options={clients.map(c => {
                      return {
                        id: c.id,
                        razon_social: c.razon_social,
                        cuit: c.cuit,
                      };
                    })}
                    onChange={(
                      e,
                      value: Pick<Client, 'id' | 'razon_social' | 'cuit'> | null
                    ) => {
                      if (!value) return;
                      handleChangeArticle('client_id', value.id);
                      setSelectedClient(value);
                    }}
                    fullWidth
                    renderInput={params => (
                      <TextField {...params} required label='Cliente' />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {selectedContainer?.id === 0 && (
            <Grid item xs={6} width='15rem'>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Nuevo Contenedor
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Descripción'
                    fullWidth
                    required
                    onChange={e =>
                      handleChangeContainer('description', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Ancho'
                    fullWidth
                    type='text'
                    required
                    error={
                      containerData.width.toString() !== '0' &&
                      !isValidPositiveFloatNumber(
                        containerData.width.toString()
                      )
                    }
                    helperText={
                      containerData.width.toString() !== '0' &&
                      !isValidPositiveFloatNumber(
                        containerData.width.toString()
                      )
                        ? 'El ancho debe ser un número positivo'
                        : ''
                    }
                    onChange={e =>
                      handleChangeContainer('width', toFloat(e.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Alto'
                    fullWidth
                    type='text'
                    required
                    error={
                      containerData.height.toString() !== '0' &&
                      !isValidPositiveFloatNumber(
                        containerData.height.toString()
                      )
                    }
                    helperText={
                      containerData.height.toString() !== '0' &&
                      !isValidPositiveFloatNumber(
                        containerData.height.toString()
                      )
                        ? 'El alto debe ser un número positivo'
                        : ''
                    }
                    onChange={e =>
                      handleChangeContainer('height', toFloat(e.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Largo'
                    fullWidth
                    type='text'
                    required
                    error={
                      containerData.long.toString() !== '0' &&
                      !isValidPositiveFloatNumber(containerData.long.toString())
                    }
                    helperText={
                      containerData.long.toString() !== '0' &&
                      !isValidPositiveFloatNumber(containerData.long.toString())
                        ? 'El peso debe ser un número positivo'
                        : ''
                    }
                    onChange={e =>
                      handleChangeContainer('long', toFloat(e.target.value))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} display='flex' justifyContent='end' gap={2} pt={2}>
            <Button type='button' variant='outlined' onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={
                !articleData.client_id ||
                (selectedContainer?.id !== 0 && !articleData.container_id) ||
                !articleData.unit_id ||
                !articleData.description ||
                !articleData.code ||
                !isValidPositiveFloatNumber(articleData.weight.toString()) ||
                !isValidPositiveFloatNumber(
                  articleData.volume_weight.toString()
                ) ||
                !isValidPositiveFloatNumber(
                  articleData.amount_per_container.toString()
                ) ||
                (selectedContainer?.id === 0 &&
                  (!containerData.description ||
                    !isValidPositiveFloatNumber(
                      containerData.width.toString()
                    ) ||
                    !isValidPositiveFloatNumber(
                      containerData.height.toString()
                    ) ||
                    !isValidPositiveFloatNumber(containerData.long.toString())))
              }
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
