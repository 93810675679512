import { useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { Client, User } from 'types';
import { Roles } from 'constants/index';

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: User;
  onEdit: (user: User) => void;
  clients: Client[];
};

export default function EditUserModal({
  open = true,
  handleOpen,
  value,
  onEdit,
  clients,
}: Props) {
  const [data, setData] = useState<User>(value);
  const [selectedClient, setSelectedClient] = useState<Pick<
    Client,
    'id' | 'razon_social'
  > | null>(
    value.role === Roles.CLIENT && value.client && value.client_id
      ? {
          id: value.client_id,
          razon_social: value.client.razon_social,
        }
      : null
  );
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handleChange = <K extends keyof Omit<User, 'id' | 'email'>>(
    name: K,
    value: Omit<User, 'id' | 'email'>[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = (updatedUser: User) => {
    onEdit(updatedUser);
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Editar Usuario
          </Typography>
        </Grid>
        <form
          onSubmit={e => {
            e.preventDefault();
            if (changePasswordOpen) {
              onSubmit({ ...data, password: newPassword });
            } else {
              onSubmit(data);
            }
            setChangePasswordOpen(false);
            handleOpen();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl required margin='none'>
                <TextField
                  label='Nombre'
                  required
                  fullWidth
                  defaultValue={value.first_name}
                  onChange={e => handleChange('first_name', e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl required margin='none'>
                <TextField
                  label='Apellido'
                  required
                  fullWidth
                  defaultValue={value.last_name}
                  onChange={e => handleChange('last_name', e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl required fullWidth margin='none'>
                <Autocomplete
                  id='role'
                  autoComplete
                  value={data.role}
                  options={[
                    Roles.ADMIN,
                    Roles.SUPERVISOR,
                    Roles.OPERATOR,
                    Roles.CLIENT,
                  ]}
                  onChange={(e, value) => {
                    handleChange('role', value as Roles);
                  }}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} required label='Rol' />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} >
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={data.is_active}
                    onChange={(e) =>
                      handleChange('is_active', e.target.checked)
                    }
                  />
                }
                label='Usuario activo'
              />
            </Grid>
            {data.role === Roles.CLIENT && (
              <Grid item xs={12}>
                <FormControl required fullWidth margin='none'>
                  <Autocomplete
                    id='client'
                    autoComplete
                    value={selectedClient}
                    options={clients.map(client => {
                      return {
                        id: client.id,
                        razon_social: client.razon_social,
                      };
                    })}
                    defaultValue={selectedClient}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option => option.razon_social}
                    onChange={(e, value) => {
                      setSelectedClient(value);
                      handleChange('client_id', value?.id || null);
                    }}
                    fullWidth
                    renderInput={params => (
                      <TextField {...params} required label='Cliente' />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl required fullWidth margin='none'>
                <TextField
                  label='Email'
                  disabled
                  required
                  fullWidth
                  type='email'
                  value={value.email}
                />
              </FormControl>
            </Grid>
            {changePasswordOpen ? (
              <>
                <Grid item xs={12}>
                  <FormControl required fullWidth margin='none'>
                    <TextField
                      label='Nueva Contraseña'
                      required
                      fullWidth
                      type='password'
                      inputProps={{ minLength: 8 }}
                      error={newPassword !== '' && newPassword.length < 8}
                      helperText={
                        newPassword.length < 8
                          ? 'La contraseña debe tener al menos 8 caracteres.'
                          : ''
                      }
                      onChange={e => setNewPassword(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required fullWidth margin='none'>
                    <TextField
                      label='Confirmar contraseña'
                      required
                      fullWidth
                      type='password'
                      error={
                        newPassword !== '' && newPassword !== confirmPassword
                      }
                      helperText={
                        newPassword !== '' && newPassword !== confirmPassword
                          ? 'Las contraseñas no coinciden.'
                          : ''
                      }
                      onChange={e => setConfirmPassword(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Button
                  type='button'
                  variant='outlined'
                  color='warning'
                  onClick={() => setChangePasswordOpen(true)}
                >
                  Cambiar contraseña
                </Button>
              </Grid>
            )}
            <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
              <Button
                type='button'
                variant='outlined'
                onClick={() => {
                  setData(value);
                  handleOpen();
                  setChangePasswordOpen(false);
                  setNewPassword('');
                  setConfirmPassword('');
                  setSelectedClient(
                    value.role === Roles.CLIENT &&
                      value.client &&
                      value.client_id
                      ? {
                          id: value.client_id,
                          razon_social: value.client.razon_social,
                        }
                      : null
                  );
                }}
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                variant='contained'
                disabled={
                  changePasswordOpen &&
                  (newPassword !== confirmPassword || newPassword.length < 8)
                }
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
}
