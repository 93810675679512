import { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { Client } from "types";
import ClientPreferences from "../settlement_types/ClientPreferences";

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: Client;
  onEdit: (client: Client) => void;
};

export default function EditClientModal({
  open = true,
  handleOpen,
  value,
  onEdit,
}: Props) {
  const [client, setClient] = useState<Client>(value);

  useEffect(() => {
    if (open) {
      setClient(value);
    }
  }, [open, value]);

  const handleChange = <K extends keyof Omit<Client, "id">>(
    name: K,
    value: Omit<Client, "id">[K]
  ) => {
    setClient({
      ...client,
      [name]: value,
    });
  };

  const onSubmit = (updatedClient: Client) => {
    const clientPreferencesList = client.client_settlement_types?.filter(
      (clientSettlementType) => clientSettlementType.price > 0
    );
    handleChange("client_settlement_types", clientPreferencesList);
    onEdit(updatedClient);
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sm: "90%", md: "60rem" },
          height: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
        }}
      >
        <Grid container columnSpacing={1}>
          <Typography variant="h5" gutterBottom>
            Editar cliente
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              required
              id="outlined-required"
              label="Razón Social"
              type="text"
              fullWidth
              value={client.razon_social}
              onChange={(e) => handleChange("razon_social", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="outlined-required"
              label="Dirección"
              type="text"
              fullWidth
              value={client.address}
              onChange={(e) => handleChange("address", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="outlined-required"
              label="CUIT"
              type="text"
              fullWidth
              value={client.cuit}
              onChange={(e) => handleChange("cuit", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="outlined-required"
              label="Contacto"
              type="text"
              fullWidth
              value={client.contact}
              onChange={(e) => handleChange("contact", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="outlined-required"
              label="Codigo"
              type="text"
              fullWidth
              value={client.code}
              onChange={(e) => handleChange("code", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={client.is_active}
                  onChange={(e) => handleChange("is_active", e.target.checked)}
                  color="primary"
                />
              }
              label="Cliente activo"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="outlined-required"
              label="Observaciones"
              type="text"
              fullWidth
              value={client.observations}
              onChange={(e) => handleChange("observations", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ClientPreferences client={client} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end" gap={1}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                handleOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={
                !client.razon_social ||
                !client.cuit ||
                !client.address ||
                !client.contact ||
                !client.code ||
                !client.observations ||
                !(
                  client.client_settlement_types?.filter(
                    (preference) => preference.is_default
                  ).length === 3
                )
              }
              onClick={() => {
                onSubmit(client);
                handleOpen();
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}