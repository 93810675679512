type GroupedData<T> = {
  [key: string]: T[];
};

export function groupBy<T>(arr: T[], keyPath: string[]): GroupedData<T> {
  return arr.reduce((acc, obj) => {
    const groupKey = keyPath.reduce(
      (value, key) => (value as any)[key],
      obj
    ) as string;

    acc[groupKey] = acc[groupKey] || [];
    acc[groupKey].push(obj);
    return acc;
  }, {} as GroupedData<T>);
}
