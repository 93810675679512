import { useEffect, useState } from 'react';

import CustomGrid from '../CustomGrid';
import { gridStructure } from 'constants/CustomGridStructure';

import { Box, Paper } from '@mui/material';

import { GridRowSelectionModel } from '@mui/x-data-grid';

import AddAssociatedClientModal from './AddAssociatedClientModal';
import DeleteConfirmationModaL from 'components/utils/DeleteConfirmationModal';
import { EnhancedTableToolbar } from '../EnhancedToolbar';

import { AssociatedClient } from 'types';
import { ASSOCIATED_CLIENTS_URL as BASE_URL } from 'constants/urls';

import { ENTITY } from 'constants/entities';
import EditAssociatedClientModal from './EditAssociatedClientModal';
import Loader from '../Loader';
import useModals from 'hooks/useModals';
import useCrud from 'hooks/useCrud';
import { useToast } from 'hooks/useToast';
import { useSession } from 'providers/SessionProvider';
import { STATUS_CODES } from 'constants/index';
import { useFilter } from 'hooks/useFilter';

export default function AssociatedClientList() {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const {
    addModalOpen,
    setAddModalOpen,
    editModalOpen,
    setEditModalOpen,
    deleteModalOpen,
    setDeleteModalOpen,
  } = useModals();

  const {
    loading,
    firstLoad,
    createData: createAssociatedClient,
    updateData: updateAssociatedClient,
    deleteData: deleteAssociatedClient,
    showToast,
    setShowToast,
    toastMessage,
    toastSeverity,
  } = useCrud<AssociatedClient>(BASE_URL);

  const {
    associatedClients,
    clients,
    addSessionData,
    updateSessionData,
    deleteSessionData,
  } = useSession();

  const { filterText, setFilterText, filteredData: filteredAssociatedClients } = useFilter<AssociatedClient>({
    data: associatedClients,
    fields: gridStructure[ENTITY.ASSOCIATED_CLIENTS].fields,
  });

  const { showSnackBar } = useToast();

  const handleDeleteSelected = async () => {
    const failedDeletes: number[] = [];

    for (const id of selected) {
      const reponse = await deleteAssociatedClient(id, true, true);
      if (reponse.status !== STATUS_CODES.OK) {
        failedDeletes.push(Number(id));
      }
    }
    deleteSessionData(
      ENTITY.ASSOCIATED_CLIENTS,
      selected
        .filter(item => !failedDeletes.includes(Number(item)))
        .map(item => item.toString())
    );

    setSelected([]);
  };

  const handleCreate = async (newAssociatedClient: AssociatedClient) => {
    const response = await createAssociatedClient(
      newAssociatedClient,
      true,
      true
    );
    if (response.status === STATUS_CODES.OK) {
      addSessionData(
        ENTITY.ASSOCIATED_CLIENTS,
        response.data as AssociatedClient
      );
    }
  };

  const handleEdit = async (associatedClientToEdit: AssociatedClient) => {
    const response = await updateAssociatedClient(
      associatedClientToEdit.id,
      associatedClientToEdit,
      true,
      true
    );
    if (response.status === STATUS_CODES.OK) {
      updateSessionData(ENTITY.ASSOCIATED_CLIENTS, associatedClientToEdit);
    }
    setSelected([]);
  };

  useEffect(() => {
    if (showToast) {
      showSnackBar(toastMessage, toastSeverity);
      setShowToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  return (
    <>
      {firstLoad ? (
        <Loader isFirstLoad={firstLoad} isLoading={loading} />
      ) : (
        <>
          <Loader isFirstLoad={firstLoad} isLoading={loading} />
          <AddAssociatedClientModal
            open={addModalOpen}
            handleOpen={() => setAddModalOpen(!addModalOpen)}
            onCreate={handleCreate}
            clients={clients}
          />
          <DeleteConfirmationModaL
            open={deleteModalOpen}
            handleOpen={() => setDeleteModalOpen(!deleteModalOpen)}
            entityName={ENTITY.CLIENTS}
            values={selected.map(item => {
              const value = associatedClients.find(
                ac => ac.id === item
              )?.razon_social;
              if (value) return value;
              return '';
            })}
            handleDelete={handleDeleteSelected}
          />
          {selected.length > 0 ? (
            <EditAssociatedClientModal
              open={editModalOpen}
              handleOpen={() => setEditModalOpen(!editModalOpen)}
              value={associatedClients.find(ac => ac.id === selected[0])}
              onEdit={handleEdit}
            />
          ) : null}
          <Box sx={{ width: '99%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                handleAddModalOpen={() => setAddModalOpen(!addModalOpen)}
                handleEditModalOpen={() => setEditModalOpen(!editModalOpen)}
                handleDeleteModalOpen={() =>
                  setDeleteModalOpen(!deleteModalOpen)
                }
                onFilterChange={setFilterText}
              />
              <CustomGrid<AssociatedClient>
                structure={gridStructure[ENTITY.ASSOCIATED_CLIENTS]}
                rows={filteredAssociatedClients}
                onEdit={handleEdit}
                selectionModel={selected}
                onRowSelection={setSelected}
              />
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}
