import { Grid, Divider, Card, CardContent, Typography } from '@mui/material';
import { arsFormatter } from 'helpers';
import { LiquidationAggregation } from 'types';

type Props = {
  print?: boolean;
  liquidation: LiquidationAggregation;
};

const TotalCard = (props: {
  print: boolean | undefined;
  title: string;
  cost: number | bigint;
}) => {
  return (
    <Card
      sx={{
        display: 'flex',
        minHeight: props.print ? 'auto' : '8rem',
        alignItems: 'center',
      }}
    >
      <CardContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <Typography variant='body2'>
          Total
          <br />
          {props.title}
        </Typography>
        <Divider sx={{ my: 1, width: '100%' }} />
        <Typography variant='body2'>{arsFormatter(props.cost)}</Typography>
      </CardContent>
    </Card>
  );
};

export default function TotalCards({ print, liquidation }: Props) {
  return (
    <Grid container spacing={print ? 1 : 2} columnSpacing={2} maxWidth={'100%'}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={2.4}>
        <Card>
          <TotalCard
            print={print}
            title={'Recepción'}
            cost={liquidation.liquidation_settlement_types[0].subtotal}
          />
        </Card>
      </Grid>
      <Grid item xs={2.4}>
        <TotalCard
          print={print}
          title={'Estadías'}
          cost={liquidation.liquidation_settlement_types[1].subtotal}
        />
      </Grid>
      <Grid item xs={2.4}>
        <TotalCard
          print={print}
          title={'Despacho'}
          cost={liquidation.liquidation_settlement_types[2].subtotal}
        />
      </Grid>
      <Grid item xs={2.4}>
        <TotalCard
          print={print}
          title={'Adicionales'}
          cost={liquidation.subtotal_adicionales}
        />
      </Grid>
      <Grid item xs={2.4}>
        <TotalCard print={print} title={'(Suma)'} cost={liquidation.total} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
