import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import DateTimePickerActionBar from 'components/utils/DatePickerActionBar';
import { LIQUIDATIONS_URL } from 'constants/urls';
import { dateISOFormat } from 'helpers';
import { utcFormatter } from 'helpers/utcFormatter';
import useCrud from 'hooks/useCrud';
import { useToast } from 'hooks/useToast';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Client, ClientSettlementType, Liquidation, Store } from 'types';
import { DATE_VIEWS, ROUTES, STATUS_CODES } from '../../../constants';
import LiquidationsTypesSelectors from './LiquidationsTypesSelectors';
import AdditionalsModal from './AdditionalsModal';

export type SelectedLiquidationTypes = {
  income: {
    calculation: string;
    description: string;
  };
  stay: {
    calculation: string;
    description: string;
  };
  discharge: {
    calculation: string;
    description: string;
  };
};

const selectedLiquidationTypesDefaultValue: SelectedLiquidationTypes = {
  income: {
    calculation: '',
    description: '',
  },
  stay: {
    calculation: '',
    description: '',
  },
  discharge: {
    calculation: '',
    description: '',
  },
};

type Props = {
  clients: Client[];
  stores: Store[];
  liquidation: Liquidation;
  handleChange: <K extends keyof Liquidation>(
    name: K,
    value: Liquidation[K]
  ) => void;
  resetLiquidation: () => void;
};

export default function LiquidationCreate({
  clients,
  stores,
  liquidation,
  handleChange,
  resetLiquidation,
}: Props) {
  const {
    loading: liquidationLoading,
    createData: createLiquidation,
    showToast,
    toastMessage,
    toastSeverity,
    setShowToast,
  } = useCrud(LIQUIDATIONS_URL, { disableAutoFetch: true });

  const { showSnackBar } = useToast();
  const navigate = useNavigate();

  const [selectedLiquidationTypes, setSelectedLiquidationTypes] =
    useState<SelectedLiquidationTypes>(selectedLiquidationTypesDefaultValue);

  const [liquidationTypesLoading, setLiquidationTypesLoading] =
    useState<boolean>(false);

  const resetLiquidationTypes = useCallback(() => {
    setSelectedLiquidationTypes(selectedLiquidationTypesDefaultValue);
  }, []);

  const [additionalsModalOpen, setAdditionalsModalOpen] =
    useState<boolean>(false);

  const clientLiquidationTypes = useMemo(() => {
    if (liquidation.client_id) {
      const client = clients.find(c => c.id === liquidation.client_id);
      return client?.client_settlement_types as ClientSettlementType[];
    }
    return null;
  }, [clients, liquidation.client_id]);

  const clientDefaultLiquidationTypes = () => {
    if (clientLiquidationTypes) {
      const defaultTypes = clientLiquidationTypes.filter(
        liqType => liqType.is_default
      );
      if (defaultTypes.length === 3) {
        //esta validacion en un futuro se puede volar, ahora lo pongo ya que hay algunos clientes deprecados que no tienen los 3 defaults
        const [egreso, estadia, ingreso] = defaultTypes;
        setLiquidationTypesLoading(true);
        setSelectedLiquidationTypes({
          income: {
            calculation: ingreso.price.toString(),
            description: ingreso.settlement_type.description,
          },
          stay: {
            calculation: estadia.price.toString(),
            description: estadia.settlement_type.description,
          },
          discharge: {
            calculation: egreso.price.toString(),
            description: egreso.settlement_type.description,
          },
        });
        setLiquidationTypesLoading(false);
      } else {
        resetLiquidationTypes(); //en un futuro volarlo como el if de arriba
      }
    } else {
      resetLiquidationTypes();
    }
  };

  useEffect(() => {
    clientDefaultLiquidationTypes();
  }, [clientLiquidationTypes, resetLiquidationTypes]);

  useEffect(() => {
    if (showToast) {
      showSnackBar(toastMessage, toastSeverity);
      setShowToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  const onAdditionalItemChange = (additional_item: {
    id: number;
    description: string;
    price: number;
  }) => {
    if (
      !liquidation.additional_items?.some(ai => ai.id === additional_item.id)
    ) {
      liquidation.additional_items?.push(additional_item);
    } else {
      liquidation.additional_items?.forEach((ai, index) => {
        if (ai.id === additional_item.id) {
          ai.description = additional_item.description;
          ai.price = additional_item.price;
        }
      });
    }
  };

  const handleCreateLiquidation = async () => {
    const response = await createLiquidation(
      {
        ...liquidation,
        liquidation_settlement_types: [
          {
            settlement_type: selectedLiquidationTypes.income.description,
            calculation: parseFloat(
              selectedLiquidationTypes.income.calculation
            ),
          },
          {
            settlement_type: selectedLiquidationTypes.stay.description,
            calculation: parseFloat(selectedLiquidationTypes.stay.calculation),
          },
          {
            settlement_type: selectedLiquidationTypes.discharge.description,
            calculation: parseFloat(
              selectedLiquidationTypes.discharge.calculation
            ),
          },
        ],
      },
      true,
      true
    );

    if (response.status === STATUS_CODES.OK) {
      resetLiquidation();
      resetLiquidationTypes();
      navigate(`${ROUTES.SALES}/${response.data.id}`);
    }
  };

  if (liquidationLoading) {
    return (
      <Grid item xs={12} display='flex' justifyContent='center' my={4}>
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <>
        {liquidationLoading && (
          <Grid item xs={12} display='flex' justifyContent='center' my={4}>
            <CircularProgress />
          </Grid>
        )}
        <AdditionalsModal
          open={additionalsModalOpen}
          onClose={() => setAdditionalsModalOpen(!additionalsModalOpen)}
          liquidation={liquidation}
          onAdditionalItemChange={onAdditionalItemChange}
          onLiquidationCreate={handleCreateLiquidation}
        />
        <Grid item xs={3}>
          <FormControl fullWidth margin='none'>
            <Autocomplete
              id='user'
              autoComplete
              options={clients?.map(c => ({
                id: c.id,
                label: c.razon_social,
              }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(
                e,
                value: {
                  id: number;
                  label: string;
                } | null
              ) => {
                handleChange('client_id', value?.id || null);
              }}
              renderInput={params => (
                <TextField {...params} required label='Cliente' />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth margin='none'>
            <Autocomplete
              id='warehouse'
              autoComplete
              options={stores
                .filter(s => s.is_active)
                .map(s => ({
                  id: s.id,
                  label: s.description,
                }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, value: { id: number; label: string } | null) => {
                handleChange('store_id', value?.id || null);
              }}
              fullWidth
              disabled={!liquidation.client_id}
              renderInput={params => <TextField {...params} label='Almacen' />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth margin='none'>
            <DateTimePicker
              disableFuture
              label='Desde'
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              slots={{
                actionBar: DateTimePickerActionBar,
              }}
              slotProps={{
                textField: {
                  required: true,
                },
              }}
              onChange={value => {
                handleChange(
                  'date_from',
                  utcFormatter.parseToStartOfDay(
                    dateISOFormat(value as unknown as string) as string
                  )
                );
              }}
              views={DATE_VIEWS}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth margin='none'>
            <DateTimePicker
              minDate={liquidation.date_from && new Date(liquidation.date_from)}
              label='Hasta'
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              slots={{
                actionBar: DateTimePickerActionBar,
              }}
              slotProps={{
                textField: {
                  required: true,
                },
              }}
              views={DATE_VIEWS}
              onChange={value =>
                handleChange(
                  'date_to',
                  utcFormatter.parseToEndOfDay(
                    dateISOFormat(value as unknown as string) as string
                  )
                )
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label='Observaciones'
              multiline
              minRows={3}
              value={liquidation.observations}
              onChange={e => handleChange('observations', e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LiquidationsTypesSelectors
            liquidation={liquidation}
            liquidationTypesLoading={liquidationTypesLoading}
            selectedLiquidationTypes={selectedLiquidationTypes}
            setSelectedLiquidationTypes={setSelectedLiquidationTypes}
          />
        </Grid>
        <Grid item xs={12} padding={2} display='flex'>
          <Button
            variant='contained'
            onClick={() => setAdditionalsModalOpen(true)}
            disabled={
              !liquidation.client_id ||
              !liquidation.date_from ||
              !liquidation.date_to ||
              !selectedLiquidationTypes.income.calculation ||
              !selectedLiquidationTypes.stay.calculation ||
              !selectedLiquidationTypes.discharge.calculation
            }
          >
            Crear Liquidación
          </Button>
        </Grid>
      </>
    );
  }
}
