import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { groupBy } from 'helpers/groupBy';
import { useToast } from 'hooks/useToast';
import { useMemo, useState } from 'react';
import { MdAllInbox } from 'react-icons/md';
import { RxPlus } from 'react-icons/rx';
import { ArticleLocation, OutcomeMovementArticleRowProps } from 'types';

export default function AddMovementArticleRow({
  movement,
  availableMovementArticles,
  rowIndex,
  onMovementArticleAdd,
}: OutcomeMovementArticleRowProps) {
  const [amount, setAmount] = useState<number>(1);
  const { showSnackBar } = useToast();
  const [selectedArticleLocation, setSelectedArticleLocation] =
    useState<ArticleLocation>(availableMovementArticles[0].article_locations);
  const [selectedLote, setSelectedLote] = useState<string>(
    availableMovementArticles[0].lote?.toString() || 'Sin Lote'
  );
  const groupedByLocation = useMemo(() => {
    return Object.entries(
      groupBy(availableMovementArticles, [
        'article_locations',
        'location',
        'code',
      ])
    );
  }, [availableMovementArticles]);

  const groupLotes = useMemo(() => {
    const lotes: string[] = [];
    const entry = groupedByLocation.find(
      e => e[0] === selectedArticleLocation.location.code
    );
    if (entry) {
      entry[1].forEach(ma => lotes.push(ma.lote?.toString() || 'Sin Lote'));
    }
    return lotes;
  }, [groupedByLocation, selectedArticleLocation]);

  const handleSubmit = () => {
    if (amount > selectedArticleLocation.stock) {
      showSnackBar(
        `La cantidad a egresar no puede ser mayor a la cantidad en la ubicación (${selectedArticleLocation.stock})`,
        'error'
      );
    } else {
      onMovementArticleAdd({
        id: rowIndex,
        article_locations: selectedArticleLocation,
        article_locations_id: selectedArticleLocation.id,
        article_id: selectedArticleLocation.article.id,
        client_id: selectedArticleLocation.article.client.id,
        location_id: selectedArticleLocation.location.id,
        container: selectedArticleLocation.article.container.description,
        description: selectedArticleLocation.article.description,
        weight: selectedArticleLocation.article.weight,
        volume_weight: selectedArticleLocation.article.volume_weight,
        unit: selectedArticleLocation.article.unit.description,
        amount: amount,
        amount_per_container:
          selectedArticleLocation.article.amount_per_container,
        subtotal: amount * selectedArticleLocation.article.amount_per_container,
        subtotal_kg: amount * selectedArticleLocation.article.weight,
        lote: selectedLote,
      });
      setAmount(1);
    }
  };
  return (
    <TableRow>
      <TableCell align='center'>
        {availableMovementArticles[0].article_locations.article.code}
      </TableCell>
      <TableCell align='center'>
        {availableMovementArticles[0].article_locations.article.description}
      </TableCell>
      <TableCell align='center'>
        {availableMovementArticles[0].article_locations.article.units_per_pallet}
      </TableCell>
      <TableCell align='center'>
        <Select
          value={selectedArticleLocation.location.code}
          label='Ubicacion'
          onChange={e => {
            const newSelection = availableMovementArticles.find(
              ma => ma.article_locations.location.code === e.target.value
            );
            if (newSelection)
              setSelectedArticleLocation(newSelection.article_locations);
          }}
        >
          {groupedByLocation.map((entries, index) => (
            <MenuItem key={index} value={entries[0]}>
              {entries[0]}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align='center'>
        <Select
          label='Lote'
          value={selectedLote}
          onChange={e => {
            setSelectedLote(e.target.value);
          }}
        >
          {groupLotes.map((lote, index) => (
            <MenuItem key={index} value={lote}>
              {lote}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align='center'>
        <TextField
          data-cy={`qty-${selectedArticleLocation.article.code}`}
          label={`Cantidad a egresar`}
          type='number'
          defaultValue={1}
          onChange={e => setAmount(parseInt(e.target.value))}
          error={
            amount >
            selectedArticleLocation.stock - selectedArticleLocation.reserved
          }
        />
        {amount >
          selectedArticleLocation.stock - selectedArticleLocation.reserved && (
          <FormHelperText sx={theme => ({ color: `${theme.palette.error}` })}>
            No puede ingresar/egresar más del máximo
          </FormHelperText>
        )}
      </TableCell>
      <TableCell align='center'>
        <TextField
          data-cy={`amount-per-container-${selectedArticleLocation.article.code}`}
          label={`Cant. por Contenedor`}
          value={`${availableMovementArticles[0].amount_per_container} (${selectedArticleLocation.article.unit.description})`}
          type='text'
          disabled
        />
      </TableCell>
      <TableCell align='center'>
        <Box display='flex' alignItems='center' gap={1}>
          <Typography noWrap>
            {selectedArticleLocation.stock - selectedArticleLocation.reserved}
          </Typography>
          <Typography noWrap display='flex' alignItems='center' gap={0.4}>
            ({selectedArticleLocation.reserved} <MdAllInbox />)
          </Typography>
        </Box>
      </TableCell>
      <TableCell align='center'>
        <Tooltip
          disableHoverListener={
            amount <
            selectedArticleLocation.stock - selectedArticleLocation.reserved
          }
          title='La cantidad seleccionada supera el stock disponible'
        >
          <Box>
            <Button
              data-cy={`add-mov-art-${selectedArticleLocation.article.code}`}
              variant='contained'
              type='submit'
              disabled={
                Boolean(
                  movement.articles.find(
                    art =>
                      art.article_locations.id === selectedArticleLocation.id &&
                      art.amount_per_container ===
                        availableMovementArticles[0].amount_per_container
                  )
                ) ||
                amount >
                  selectedArticleLocation.stock -
                    selectedArticleLocation.reserved
              }
              onClick={handleSubmit}
            >
              <RxPlus size={'1.2rem'} />
            </Button>
          </Box>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
