import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Loader from 'components/lists/Loader';
import { ADDITIONAL_ITEMS_IN_MOVEMENTS_URL } from 'constants/urls';
import useCrud from 'hooks/useCrud';
import { useEffect } from 'react';
import { Liquidation } from 'types';
import AdditionalItemRow from './AdditionalItemRow';

export default function AdditionalsModal({
  open,
  liquidation,
  onClose,
  onLiquidationCreate,
  onAdditionalItemChange,
}: {
  open: boolean;
  liquidation: Liquidation;
  onClose: () => void;
  onLiquidationCreate: () => void;
  onAdditionalItemChange: (additional_item: {
    id: number;
    description: string;
    price: number;
  }) => void;
}) {
  const { client_id, store_id, date_from, date_to } = liquidation;

  const {
    data: additional_items,
    loading,
    fetchData: getAdditionalItems,
  } = useCrud<{ id: number; description: string; price: number }>(
    ADDITIONAL_ITEMS_IN_MOVEMENTS_URL,
    { disableAutoFetch: true }
  );

  useEffect(() => {
    getAdditionalItems(undefined, true, {
      clientId: client_id,
      storeId: store_id,
      fromDate: date_from,
      toDate: date_to,
    });
  }, [liquidation]);
  return (
    <Modal open={open}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '50%', md: '50%' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        {loading && <Loader isFirstLoad={false} isLoading={loading} />}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant='h4' fontWeight={'bold'}>
              Listado de Adicionales
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxHeight: '20rem' }}>
              <Table size='small' stickyHeader>
                <TableHead>
                  <TableRow key={'head'}>
                    <TableCell style={{ textAlign: 'center' }}>
                      Descripcion
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Precio
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additional_items.map((ai, index) => (
                    <AdditionalItemRow
                      additional_item={ai}
                      key={index}
                      onChange={onAdditionalItemChange}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Box
              width={'100%'}
              display={'inline-flex'}
              justifyContent={'right'}
              gap={2}
            >
              <Button variant='outlined' onClick={onLiquidationCreate}>
                Crear Liquidacion
              </Button>
              <Button variant='outlined' onClick={() => onClose()}>
                Cancelar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
