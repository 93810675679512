//TODO: take logged user id from context and put it as created_by
import { useState } from 'react';
import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  Autocomplete,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { Client, User } from 'types';
import { Roles } from 'constants/index';

type Props = {
  open: boolean;
  handleOpen: () => void;
  onCreate: (newUser: User) => void;
  clients: Client[];
  users: User[];
};

export default function AddUserModal({
  open = true,
  handleOpen,
  onCreate,
  clients,
  users
}: Props) {
  const defaultValues: Omit<User, 'id'> = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    is_active: true,
    role: Roles.ADMIN,
    client_id: null,
  };

  const [selectedClient, setSelectedClient] = useState<Pick<
    Client,
    'id' | 'razon_social'
  > | null>(null);
  const [data, setData] = useState<Omit<User, 'id'>>(defaultValues);
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handleChange = <K extends keyof Omit<User, 'id'>>(
    name: K,
    value: Omit<User, 'id'>[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = async (newUser: Omit<User, 'id'>) => {
    onCreate(newUser as User);
  };

  const handleClose = () => {
    setData(defaultValues);
    setSelectedClient(null);
    handleOpen();
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            onSubmit(data);
            handleClose();
          }}
        >
          <Grid container spacing={2}>
            <Typography variant='h5' gutterBottom>
              Nuevo Usuario
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl required margin='none'>
                <TextField
                  label='Nombre'
                  required
                  fullWidth
                  onChange={e => handleChange('first_name', e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl required margin='none'>
                <TextField
                  label='Apellido'
                  required
                  fullWidth
                  onChange={e => handleChange('last_name', e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl required fullWidth margin='none'>
                <Autocomplete
                  id='role'
                  autoComplete
                  value={data.role}
                  options={[
                    Roles.ADMIN,
                    Roles.SUPERVISOR,
                    Roles.OPERATOR,
                    Roles.CLIENT,
                  ]}
                  onChange={(e, value) => {
                    if (data.role === Roles.CLIENT) setSelectedClient(null);
                    handleChange('role', value as Roles);
                  }}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} required label='Rol' />
                  )}
                />
              </FormControl>
            </Grid>
            {data.role === Roles.CLIENT && (
              <Grid item xs={12}>
                <FormControl required fullWidth margin='none'>
                  <Autocomplete
                    id='client'
                    autoComplete
                    value={selectedClient}
                    options={clients.map(client => {
                      return {
                        id: client.id,
                        razon_social: client.razon_social,
                      };
                    })}
                    defaultValue={selectedClient}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option => option.razon_social}
                    onChange={(e, value) => {
                      setSelectedClient(value);
                      handleChange('client_id', value?.id || null);
                    }}
                    fullWidth
                    renderInput={params => (
                      <TextField {...params} required label='Cliente' />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={6} >
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={data.is_active}
                    onChange={(e) =>
                      handleChange('is_active', e.target.checked)
                    }
                  />
                }
                label='Usuario activo'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin='none'>
                <TextField
                  label='Email'
                  error={
                    users.some(user => user.email === data.email) &&
                    data.email !== ''
                  }
                  helperText={
                    users.some(user => user.email === data.email) &&
                    data.email !== ''
                      ? 'Ya existe un usuario con este email.'
                      : ''
                  }
                  required
                  fullWidth
                  type='email'
                  onChange={e => handleChange('email', e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin='none'>
                <TextField
                  label='Contraseña'
                  required
                  fullWidth
                  type='password'
                  inputProps={{ minLength: 8 }}
                  error={data.password !== '' && data.password.length < 8}
                  helperText={
                    data.password.length < 8
                      ? 'La contraseña debe tener al menos 8 caracteres.'
                      : ''
                  }
                  onChange={e => handleChange('password', e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin='none'>
                <TextField
                  label='Confirmar contraseña'
                  required
                  fullWidth
                  type='password'
                  error={
                    data.password !== '' && data.password !== confirmPassword
                  }
                  helperText={
                    data.password !== '' && data.password !== confirmPassword
                      ? 'Las contraseñas no coinciden.'
                      : ''
                  }
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
              <Button
                type='button'
                variant='outlined'
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                variant='contained'
                disabled={
                  data.password !== confirmPassword ||
                  data.password.length < 8 ||
                  users.some(user => user.email === data.email)
                }
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
}
