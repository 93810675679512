import {
  Modal,
  Paper,
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useToast } from 'hooks/useToast';
import { ROUTES } from 'constants/index';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'hooks/useUser';

export default function ChangePassword() {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');

  const {
    updateLoading,
    changePassword,
    showToast,
    setShowToast,
    toastMessage,
    toastSeverity,
  } = useUser();
  const navigate = useNavigate();
  const { showSnackBar } = useToast();

  const handleSubmit = async () => {
    const success = await changePassword(currentPassword, newPassword);
    if (success) {
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      if (showToast) {
        showSnackBar(toastMessage, toastSeverity);
        setShowToast(false);
      }
    }
  };

  useEffect(() => {
    if (showToast) {
      showSnackBar(toastMessage, toastSeverity);
      setShowToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  return (
    <Modal open>
      <Paper
        elevation={4}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container spacing={2}>
            {updateLoading && (
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant='h5' gutterBottom>
                Nueva Contraseña
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin='none'>
                <TextField
                  label='Contraseña actual'
                  required
                  fullWidth
                  type='password'
                  value={currentPassword}
                  inputProps={{ minLength: 8 }}
                  error={currentPassword !== '' && currentPassword.length < 8}
                  helperText={
                    currentPassword !== '' && currentPassword.length < 8
                      ? 'La contraseña debe tener al menos 8 caracteres.'
                      : ''
                  }
                  onChange={e => setCurrentPassword(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin='none'>
                <TextField
                  label='Contraseña'
                  required
                  fullWidth
                  type='password'
                  value={newPassword}
                  inputProps={{ minLength: 8 }}
                  error={newPassword !== '' && newPassword.length < 8}
                  helperText={
                    newPassword.length < 8
                      ? 'La contraseña debe tener al menos 8 caracteres.'
                      : ''
                  }
                  onChange={e => setNewPassword(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth margin='none'>
                <TextField
                  label='Confirmar contraseña'
                  required
                  fullWidth
                  type='password'
                  value={confirmPassword}
                  error={
                    confirmPassword !== '' && newPassword !== confirmPassword
                  }
                  helperText={
                    confirmPassword !== '' && newPassword !== confirmPassword
                      ? 'Las contraseñas no coinciden.'
                      : ''
                  }
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
              <Button
                type='button'
                variant='outlined'
                disabled={updateLoading}
                onClick={() => {
                  setNewPassword('');
                  setConfirmPassword('');
                  setCurrentPassword('');
                  navigate(ROUTES.HOME);
                }}
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                variant='contained'
                disabled={
                  newPassword !== confirmPassword || newPassword.length < 8
                }
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
}
