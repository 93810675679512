import { Movement, OperationsFilters } from 'types';

import { Grid } from '@mui/material';
import OperationsLogsCard from './OperationsLogsCard';
import { useSession } from 'providers/SessionProvider';

type Props = {
  movements: Movement[];
  filters: OperationsFilters;
};

export default function OperationsLogs({ movements, filters }: Props) {
  const { stores } = useSession();
  return (
    <>
      <Grid container gap={2}>
        {movements.map(movement => (
          <OperationsLogsCard key={movement.id} movement={movement} stores={stores} />
        ))}
      </Grid>
    </>
  );
}
