import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { STATUS_CODES, USER_STORAGE_KEY } from 'constants/index';
import { AUTH_REFRESH_URL } from 'constants/urls';
import { Tokens } from 'types';

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshAccessToken = async (): Promise<string | null> => {
  const tokens: Tokens = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || '{}');
  if (!tokens?.refresh_token) return null;

  try {
    const response = await api.get(AUTH_REFRESH_URL, {
      headers: {
        Authorization: `Bearer ${tokens.refresh_token}`,
      },
    });
    if (response.status === STATUS_CODES.OK) {
      const newTokens = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
      };
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(newTokens));
      return newTokens.access_token;
    }
  } catch (error) {
    console.error('Error refrescando el token', error);
  }
  return null;
};

const handleUnauthorized = async (originalRequest: AxiosRequestConfig) => {
  const newAccessToken = await refreshAccessToken();
  if (newAccessToken) {
    originalRequest.headers = {
      ...originalRequest.headers,
      Authorization: `Bearer ${newAccessToken}`,
    };
    return api.request(originalRequest);
  } else {
    localStorage.removeItem(USER_STORAGE_KEY);
    window.location.reload();
  }
};

const handleRequestWithAuth = async (
  request: () => Promise<AxiosResponse<any>>,
  originalRequest: AxiosRequestConfig
): Promise<{ status: number; data: any }> => {
  try {
    const response = await request();
    return response;
  } catch (error: any) {
    if (error.response && error.response.data.status === STATUS_CODES.UNAUTHORIZED) {
      return handleUnauthorized(originalRequest) as Promise<AxiosResponse<any>>;
    }

    return {
      status: error.response?.status || 500,
      data: error.response?.data || null,
    };
  }
};

export const axiosGet = async (url: string, auth: boolean = false, params = {}) => {
  let headers: Record<string, string> | undefined = undefined;
  const tokens: Tokens = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || 'null');

  if (tokens?.access_token) {
    headers = {
      Authorization: `Bearer ${tokens.access_token}`,
    };
  }

  const originalRequest: AxiosRequestConfig = {
    url,
    method: 'get',
    headers,
    params,
  };

  return handleRequestWithAuth(() => api.get(url, { headers, params }), originalRequest);
};

export const axiosPost = async <T>(url: string, body: T, auth: boolean = false) => {
  let headers: Record<string, string> | undefined = undefined;
  const tokens: Tokens = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || 'null');

  if (tokens?.access_token) {
    headers = {
      Authorization: `Bearer ${tokens.access_token}`,
    };
  }

  const originalRequest: AxiosRequestConfig = {
    url,
    method: 'post',
    headers,
    data: body,
  };

  return handleRequestWithAuth(() => api.post(url, body, { headers }), originalRequest);
};

export const axiosPut = async <T>(url: string, body: T, auth: boolean = false) => {
  let headers: Record<string, string> | undefined = undefined;
  const tokens: Tokens = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || 'null');

  if (tokens?.access_token) {
    headers = {
      Authorization: `Bearer ${tokens.access_token}`,
    };
  }

  const originalRequest: AxiosRequestConfig = {
    url,
    method: 'put',
    headers,
    data: body,
  };

  return handleRequestWithAuth(() => api.put(url, body, { headers }), originalRequest);
};

export const axiosDelete = async (url: string, auth: boolean = false) => {
  let headers: Record<string, string> | undefined = undefined;
  const tokens: Tokens = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || 'null');

  if (tokens?.access_token) {
    headers = {
      Authorization: `Bearer ${tokens.access_token}`,
    };
  }

  const originalRequest: AxiosRequestConfig = {
    url,
    method: 'delete',
    headers,
  };

  return handleRequestWithAuth(() => api.delete(url, { headers }), originalRequest);
};
