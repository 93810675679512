import {
  Alert,
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import { LiquidationAggregation, LiquidationSettlementType } from 'types';
import { ROUTES, SETTLEMENT_TYPES } from 'constants/index';

import { arsFormatter } from 'helpers';
import {
  MdSearch,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { dateToArgentinianTime } from 'helpers/utcFormatter';
import React from 'react';

type Props = {
  loading: boolean;
  liquidations: LiquidationAggregation[];
};

function checkSettlementType(
  liquidation_settlement_type: LiquidationSettlementType
) {
  switch (liquidation_settlement_type.settlement_type) {
    case SETTLEMENT_TYPES.ABONO:
      return `${arsFormatter(liquidation_settlement_type.calculation)}`;
    case SETTLEMENT_TYPES.PALLETS:
      return `${arsFormatter(
        liquidation_settlement_type.calculation
      )} (x Pallet)`;
    case SETTLEMENT_TYPES.PESO:
      return `${arsFormatter(liquidation_settlement_type.calculation)} (x Kg)`;
    case SETTLEMENT_TYPES.PESO_VOLUMETRICO:
      return `${arsFormatter(liquidation_settlement_type.calculation)} (x Kg)`;
    case SETTLEMENT_TYPES.VOLUMEN:
      return `${arsFormatter(liquidation_settlement_type.calculation)} (x M3)`;
    case SETTLEMENT_TYPES.SUPERFICIE:
      return `${arsFormatter(liquidation_settlement_type.calculation)} (x M2)`;
  }
}

const CustomTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  fontSize: 12,
}));

export default function LiquidationsHistoryTable({
  loading,
  liquidations,
}: Props) {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} elevation={4}>
      <Box width='100%' p={2}>
        <Typography variant='h6'>Historial de Liquidaciones</Typography>
      </Box>
      {loading ? (
        <Box width='100%' p={2}>
          <LinearProgress />
        </Box>
      ) : (
        <Table aria-label='Tabla de liquidaciones' size='small'>
          <TableHead>
            <TableRow>
              <CustomTableCell>Nº</CustomTableCell>
              <CustomTableCell align='right'>Desde</CustomTableCell>
              <CustomTableCell align='right'>Hasta</CustomTableCell>
              <CustomTableCell align='right'>Cálculo Recepción</CustomTableCell>
              <CustomTableCell align='right'>Cálculo Estadía</CustomTableCell>
              <CustomTableCell align='right'>Cálculo Despacho</CustomTableCell>
              <CustomTableCell align='right'>Total Recepción</CustomTableCell>
              <CustomTableCell align='right'>Total Estadía</CustomTableCell>
              <CustomTableCell align='right'>Total Despacho</CustomTableCell>
              <CustomTableCell align='right'>Total Adicionales</CustomTableCell>
              <CustomTableCell align='right'>Total General</CustomTableCell>
              <CustomTableCell align='right'></CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {liquidations.length === 0 ? (
              <TableRow>
                <TableCell colSpan={11}>
                  <Alert
                    severity='info'
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant='body2'>
                      No hay registros para el cliente seleccionado
                    </Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            ) : (
              liquidations.map((lq, index) => {
                const incomeSettlementType = lq.liquidation_settlement_types[0];
                const staySettlementType = lq.liquidation_settlement_types[1];
                const dischargeSettlementType =
                  lq.liquidation_settlement_types[2];
                return (
                  <TableRow key={index}>
                    <CustomTableCell>{lq.id}</CustomTableCell>
                    <CustomTableCell align='right'>
                      {dateToArgentinianTime(lq.date_from)}
                    </CustomTableCell>
                    <CustomTableCell align='right'>
                      {dateToArgentinianTime(lq.date_to)}
                    </CustomTableCell>
                    <CustomTableCell align='right'>
                      {incomeSettlementType &&
                        checkSettlementType(incomeSettlementType)}
                    </CustomTableCell>
                    <CustomTableCell align='right'>
                      {staySettlementType &&
                        checkSettlementType(staySettlementType)}
                    </CustomTableCell>
                    <CustomTableCell align='right'>
                      {dischargeSettlementType &&
                        checkSettlementType(dischargeSettlementType)}
                    </CustomTableCell>
                    <CustomTableCell align='right'>
                      {incomeSettlementType
                        ? arsFormatter(incomeSettlementType.subtotal)
                        : '-'}
                    </CustomTableCell>
                    <CustomTableCell align='right'>
                      {staySettlementType
                        ? arsFormatter(staySettlementType.subtotal)
                        : '-'}
                    </CustomTableCell>
                    <CustomTableCell align='right'>
                      {dischargeSettlementType
                        ? arsFormatter(dischargeSettlementType.subtotal)
                        : '-'}
                    </CustomTableCell>
                    <CustomTableCell align='right'>
                      {arsFormatter(lq.subtotal_adicionales)}
                    </CustomTableCell>
                    <CustomTableCell align='right'>
                      {incomeSettlementType &&
                      staySettlementType &&
                      dischargeSettlementType
                        ? arsFormatter(
                            incomeSettlementType?.subtotal +
                              staySettlementType?.subtotal +
                              dischargeSettlementType?.subtotal +
                              lq.subtotal_adicionales
                          )
                        : '-'}
                    </CustomTableCell>
                    <CustomTableCell align='right' sx={{ px: 1, py: 0 }}>
                      <IconButton
                        size='small'
                        onClick={() => navigate(`${ROUTES.SALES}/${lq.id}`)}
                      >
                        <MdSearch />
                      </IconButton>
                    </CustomTableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
