import { Grid, IconButton } from '@mui/material';
import AdditionalDetailTable from 'components/Liquidations/LiquidationDetail/DetailTables/Aditionals/AdditionalsDetailTable';
import DischargeDetailTable from 'components/Liquidations/LiquidationDetail/DetailTables/IncomeDischarges/DischargeDetailTable';
import IncomeDetailTable from 'components/Liquidations/LiquidationDetail/DetailTables/IncomeDischarges/IncomeDetailTable';
import StayDetailTable from 'components/Liquidations/LiquidationDetail/DetailTables/Stay/StayDetailTable';
import PageLayout from 'components/layout/PageLayout';
import Loader from 'components/lists/Loader';
import { LIQUIDATIONS_URL } from 'constants/urls';
import useCrud from 'hooks/useCrud';
import { createRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { LiquidationAggregation } from 'types';

import LiquidationDetailsCard from 'components/Liquidations/LiquidationDetail/LiquidationDetailsCard';
import TotalCards from 'components/Liquidations/LiquidationDetail/TotalCards';
import { PrintLayout } from 'components/Prints/PrintLayout';
import { MdPrint } from 'react-icons/md';
import { chipHandler } from 'components/utils/LiquidationDetails';

export const LiquidationDetailsPage = () => {
  const { id } = useParams();
  const printRef: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  const { data: liquidations, loading } = useCrud<LiquidationAggregation>(
    `${LIQUIDATIONS_URL}${id}`
  );

  const liquidation = liquidations?.[0];

  return (
    <PageLayout
      pageTitle='Detalle de Liquidación'
      pageDesc='Liquidaciones de ingresos, estadías, egresos y adicionales'
    >
      {liquidation && (
        <PrintLayout
          ref={printRef}
          headerInfo={
            <LiquidationDetailsCard id={id} liquidation={liquidation} />
          }
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TotalCards print liquidation={liquidation} />
            </Grid>
            <Grid item xs={12}>
              {liquidation.liquidation_settlement_types[0].subtotal > 0 && (
                <IncomeDetailTable print liquidation={liquidation} />
              )}
              {liquidation.liquidation_settlement_types[1].subtotal > 0 && (
                <StayDetailTable print liquidation={liquidation} />
              )}
              {liquidation.liquidation_settlement_types[2].subtotal > 0 && (
                <DischargeDetailTable print liquidation={liquidation} />
              )}
              {liquidation.subtotal_adicionales > 0 && (
                <AdditionalDetailTable print liquidation={liquidation} />
              )}
            </Grid>
          </Grid>
        </PrintLayout>
      )}
      {loading || !liquidation ? (
        <Loader isLoading={loading} isFirstLoad={false} />
      ) : (
        <Grid container spacing={2} paddingBottom={10}>
          <Grid item xs={6}>
            <LiquidationDetailsCard
              liquidation={liquidation}
              incomeChip={chipHandler(
                liquidation.liquidation_settlement_types[0].settlement_type
              )}
              stayChip={chipHandler(
                liquidation.liquidation_settlement_types[1].settlement_type
              )}
              dischargeChip={chipHandler(
                liquidation.liquidation_settlement_types[2].settlement_type
              )}
              id={id}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              displayPrint: 'none',
            }}
          >
            <ReactToPrint
              content={() => printRef.current}
              copyStyles={true}
              trigger={() => (
                <IconButton size='medium'>
                  <MdPrint />
                </IconButton>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TotalCards liquidation={liquidation} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} columnSpacing={2}>
              {liquidation.liquidation_settlement_types[0].subtotal > 0 && (
                <IncomeDetailTable
                  liquidation={liquidation}
                  chip={chipHandler(
                    liquidation.liquidation_settlement_types[0].settlement_type
                  )}
                />
              )}
              {liquidation.liquidation_settlement_types[1].subtotal > 0 && (
                <StayDetailTable
                  liquidation={liquidation}
                  chip={chipHandler(
                    liquidation.liquidation_settlement_types[1].settlement_type
                  )}
                />
              )}
              {liquidation.liquidation_settlement_types[2].subtotal > 0 && (
                <DischargeDetailTable
                  liquidation={liquidation}
                  chip={chipHandler(
                    liquidation.liquidation_settlement_types[2].settlement_type
                  )}
                />
              )}
              {liquidation.subtotal_adicionales > 0 && (
                <AdditionalDetailTable liquidation={liquidation} />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};
