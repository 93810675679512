import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import PageLayout from "../components/layout/PageLayout";
import { useReactToPrint } from "react-to-print";
import useCrud from "hooks/useCrud";
import { useToast } from "hooks/useToast";
import { useSession } from "providers/SessionProvider";
import Loader from "components/lists/Loader";
import CustomNumberInput from "components/CustomNumberInput";
import { dateISOFormat } from "helpers";
import { DATE_VIEWS, MOVEMENT_TYPES, MovementTypes, STATUS_CODES, defaultMovement } from "../constants";
import ArticlesList from "components/Operations/ArticlesList";
import AdditionalItemsList from "components/Operations/AdditionalItemsList";
import { Article, AssociatedClient, Movement } from "types";
import { MOVEMENTS_URL } from "constants/urls";
import { useConfirmationModal } from "hooks/useConfirmationModal";
import DateTimePickerActionBar from "components/utils/DatePickerActionBar";
import FileImport from "components/FileImport/FileImport";
import { PrintLayout } from "components/Prints/PrintLayout";
import OperationPrintHeaderDetails from "components/Prints/Operations/OperationPrintHeaderDetails";
import OperationPrintDetails from "components/Prints/Operations/OperationPrintDetails";
import { decimalFormat } from "helpers/formatNumber";

export default function IncomePage() {
  const [movement, setMovement] = useState<Movement>({
    ...defaultMovement,
    movement_type_id: MovementTypes.Income,
  });
  const [movementToPrint, setMovementToPrint] = useState<Movement | undefined>();
  const [movementLoading, setMovementLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [clientAssociatedClients, setClientAssociatedClients] = useState<AssociatedClient[]>([]);
  const [clientArticles, setClientArticles] = useState<Article[]>([]);
  const [rowIndex, setRowIndex] = useState<number>(0);

  const printRef = useRef(null);
  const navigate = useNavigate();
  const { showSnackBar } = useToast();
  const { showModal } = useConfirmationModal();
  const { firstLoad: onMovementFirstLoad, createData: createMovement, fetchData: getMovement } = useCrud(MOVEMENTS_URL);
  const { userData, stores, clients, associatedClients, additionalItems, articles, users, locations } = useSession();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      navigate("/operations");
      setMovementLoading(false);
      showSnackBar("Se ha creado el ingreso correctamente", "success");
    },
  });

  const handleChange = useCallback((name: string, value: any) => {
    setMovement((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSubmit = async () => {
    setMovementLoading(true);
    const movementResponse = await createMovement([movement], true);
    if (movementResponse.status === STATUS_CODES.OK) {
      const aggregatedMovementResponse = await getMovement(
        `${movementResponse.data[0].id}`,
        true
      );
      setMovementToPrint(aggregatedMovementResponse.data[0]);
    } else {
      setMovementLoading(false);
      showSnackBar(
        movementResponse.status === STATUS_CODES.BAD_REQUEST
          ? "El número de remito ya se encuentra registrado, por favor modifiquelo e intente nuevamente."
          : "El ingreso no ha podido crearse, por favor intente nuevamente.",
        "error"
      );
    }
  };

  const calculateTotalPallets = useCallback(() => {
    const totalPallets = movement.articles.reduce((sum, article) => {
      const unitsPerPallet = article.article_locations.article.units_per_pallet;
      const amount = article.amount;
      const palletsForArticle = unitsPerPallet ? Math.ceil(amount / unitsPerPallet) : 0;
      return sum + palletsForArticle;
    }, 0);
    setMovement((prev) => ({ ...prev, pallets: decimalFormat(totalPallets) }));
  }, [movement.articles]);


  const handleConfirmSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    showModal({
      title: "Confirmar",
      message: "¿Está seguro que desea confirmar el ingreso?",
      action: handleSubmit,
    });
  };

  const submitDisabled = useMemo(() => !movement.weight, [movement]);

  const filteredUsers = useMemo(
    () => users?.map((u) => ({
      id: u.id,
      label: `${u.first_name} ${u.last_name}`,
    })),
    [users]
  );

  const filteredStores = useMemo(
    () => stores.filter((s) => s.is_active).map((s) => ({
      id: s.id,
      label: s.description,
    })),
    [stores]
  );

  useEffect(() => {
    if (movementToPrint) {
      handlePrint();
    }
  }, [movementToPrint, handlePrint]);

  useEffect(() => {
    if (movement.client_id !== 0) {
      setClientAssociatedClients(
        associatedClients.filter(
          (ac) => ac.client_id === movement.client_id && ac.is_active
        )
      );
      setClientArticles(
        articles.filter((a) => a.client.id === movement.client_id)
      );
    }
  }, [movement.client_id, associatedClients, articles]);

  useEffect(() => {
    if (userData) {
      handleChange("created_by", userData.id);
    }
  }, [userData, handleChange]);

  useEffect(() => {
    calculateTotalPallets();
  }, [movement.articles, calculateTotalPallets]);

  return (
    <PageLayout
      pageTitle="Ingresos"
      pageDesc="Orden de ingreso de mercadería"
      importButton={
        <FileImport
          movementType={MOVEMENT_TYPES.INCOME.label}
          locations={locations}
          clients={clients}
          articles={articles}
          additional_items={additionalItems}
          users={users}
          stores={stores}
        />
      }
    >
      {movementToPrint && (
        <PrintLayout
          ref={printRef}
          headerInfo={
            <OperationPrintHeaderDetails
              movement={movementToPrint}
              stores={stores}
            />
          }
        >
          <OperationPrintDetails
            movement={movementToPrint}
            client={movementToPrint.client?.razon_social}
          />
        </PrintLayout>
      )}
      <form
        onSubmit={handleConfirmSubmit}
      >
        {movementLoading ? (
          <Loader isLoading={true} isFirstLoad={onMovementFirstLoad}/>
        ) : (
          <Grid container columnSpacing={1}>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="user"
                  autoComplete
                  disableClearable
                  value={{
                    id: movement.created_by,
                    label: `${
                      users.find((u) => u.id === movement.created_by)
                        ?.first_name ?? ""
                    } ${
                      users.find((u) => u.id === movement.created_by)
                        ?.last_name ?? ""
                    }`,
                  }}
                  options={filteredUsers}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value: { id: number; label: string } | null) =>
                    handleChange("created_by", value?.id)
                  }
                  renderInput={(params) => (
                    <TextField {...params} required label="Usuario" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  data-cy="author"
                  id="author"
                  autoComplete
                  disableClearable
                  value={
                    users
                      .find((u) => u.id === movement.updated_by)
                      ? { id: users.find((u) => u.id === movement.updated_by)?.id!, label: `${users.find((u) => u.id === movement.updated_by)?.first_name} ${users.find((u) => u.id === movement.updated_by)?.last_name}` }
                      : undefined
                  }                 
                  options={filteredUsers}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disabled={!movement.created_by}
                  onChange={(e, value: { id: number; label: string } | null) =>
                    handleChange("updated_by", value?.id)
                  }
                  renderInput={(params) => (
                    <TextField {...params} required label="Preparó" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} data-cy={option.label}>
                      {option.label}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <DateTimePicker
                  disableFuture
                  label="Fecha"
                  format="dd/MM/yyyy"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slots={{
                    actionBar: DateTimePickerActionBar,
                  }}
                  slotProps={{
                    textField: {
                      inputProps: {
                        "data-cy": `date`,
                      },
                    },
                  }}
                  views={DATE_VIEWS}
                  value={movement.date ? new Date(movement.date) : null}
                  onChange={(value: Date | null) => {
                    if (value?.toISOString()) {
                      handleChange(
                        "date",
                        dateISOFormat(value.toISOString())
                      );
                      handleChange(
                        "settlement_date",
                        dateISOFormat(value.toISOString())
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  data-cy="client"
                  id="client"
                  disableClearable
                  autoComplete
                  value={
                    clients.find((c) => c.id === movement.client_id) 
                      ? { id: clients.find((c) => c.id === movement.client_id)?.id!, label: clients.find((c) => c.id === movement.client_id)?.razon_social! }
                      : undefined
                  }
                  options={clients.map((c) => ({
                    id: c.id,
                    label: c.razon_social,
                  }))}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(
                    e,
                    value: {
                      id: number;
                      label: string;
                    } | null
                  ) => {
                    setMovement((prev) => ({
                      ...prev,
                      client_id: value?.id ?? 0,
                      associated_client: "",
                      articles: [],
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} required label="Cliente" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} data-cy={option.label}>
                      {option.label}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  data-cy="associated-client"
                  id="associated-client"
                  disableClearable
                  options={clientAssociatedClients.map((ac: AssociatedClient) => ({
                    label: ac.razon_social,
                  }))}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  value={{ label: movement.associated_client }}
                  disabled={!movement.client_id}
                  onChange={(e, value: { label: string } | null) =>
                    handleChange("associated_client", value?.label)
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} required label="Cliente Asociado" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} data-cy={option.label}>
                      {option.label}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth required margin="none">
                <CustomNumberInput
                  data-cy="income-client-doc"
                  label="Documento del Cliente"
                  value={movement.client_document}
                  onChange={(e) => handleChange("client_document", e.target.value)}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  data-cy="warehouse"
                  id="warehouse"
                  autoComplete
                  value={
                    stores
                      .filter((s) => s.is_active)
                      .find((s) => s.id === movement.store_id)
                      ? { 
                          id: stores.find((s) => s.id === movement.store_id)?.id!, 
                          label: stores.find((s) => s.id === movement.store_id)?.description! 
                        }
                      : undefined
                  }
                  options={filteredStores}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(
                    e,
                    value: { id: number; label: string } | null
                  ) => {
                    setMovement((prev) => ({
                      ...prev,
                      store_id: value?.id ?? 0,
                      articles: [],
                    }));
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} required label="Almacen" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} data-cy={option.label}>
                      {option.label}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <CustomNumberInput
                  label="Pallets"
                  value={movement.pallets}
                  onChange={(e) => handleChange("pallets", Number(e.target.value))}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <CustomNumberInput
                  label="Peso"
                  value={movement.weight}
                  onChange={(e) => handleChange("weight", Number(e.target.value))}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="none">
                <CustomNumberInput
                  data-cy="remito"
                  label="Número de Remito"
                  value={movement.numero_remito}
                  onChange={(e) => handleChange("numero_remito", e.target.value)}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                data-cy="obs"
                id="observations"
                label="Observaciones"
                type="text"
                fullWidth
                value={movement.observation}
                onChange={(e) => handleChange("observation", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)}>
                  <Tab label="Artículos" />
                  <Tab label="Ítems Adicionales" />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12} my={2}>
              {currentTab === 0 ? (
                <ArticlesList
                  articles={clientArticles}
                  movement={movement}
                  onMovementUpdate={handleChange}
                  operationType={MOVEMENT_TYPES.INCOME.label}
                  locations={locations}
                  stores={stores}
                  hasPalletAutocalculation={true}
                  hasWeightAutocalculation={true}
                  rowIndex={rowIndex}
                  setRowIndex={setRowIndex}
                />
              ) : (
                <AdditionalItemsList
                  additionalItems={additionalItems}
                  movement={movement}
                  onMovementUpdate={handleChange}
                  rowIndex={rowIndex}
                  setRowIndex={setRowIndex}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                data-cy="confirm-income-button"
                type="submit"
                variant="contained"
                disabled={submitDisabled}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </PageLayout>
  );
}

