import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  CustomHeadCell,
  MovementTypeHeaderCell,
} from "components/utils/LiquidationDetails";
import { arsFormatter } from "helpers";
import { dateToArgentinianTime } from "helpers/utcFormatter";
import React, { useMemo } from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { LiquidationAggregation, MovementLiquidation } from "types";

export default function Row({
  row,
  liquidation,
  print,
}: {
  row: MovementLiquidation;
  liquidation: LiquidationAggregation;
  print: boolean;
}) {
  const [open, setOpen] = React.useState(print);

  const additional_items = useMemo(() => {
    return liquidation.liquidation_movement_additional_items.filter(
      (mai) => mai.movement_id === row.movement_id
    );
  }, [row, liquidation]);

  const subtotal = useMemo(() => {
    let sum = 0;
    additional_items.forEach((ai) => (sum += ai.subtotal));
    return sum;
  }, [additional_items]);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {!print && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <BsArrowUp /> : <BsArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{MovementTypeHeaderCell(row.movement_type_id)}</TableCell>
        <TableCell>{row.numero_remito}</TableCell>
        <TableCell>{dateToArgentinianTime(row.date)}</TableCell>
        <TableCell>{arsFormatter(subtotal)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalle del Movimiento
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Descripción</TableCell>
                    <TableCell>Cantidad (Unidades)</TableCell>
                    <CustomHeadCell>Precio</CustomHeadCell>
                    <CustomHeadCell>Subtotal</CustomHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additional_items.map((movement_additional_items, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {
                          liquidation.liquidation_additional_items.find(
                            (additional_item) =>
                              additional_item.additional_item_id ===
                              movement_additional_items.additional_item_id
                          )?.description
                        }
                      </TableCell>
                      <TableCell>{movement_additional_items.amount}</TableCell>
                      <TableCell>
                        {arsFormatter(
                          liquidation.liquidation_additional_items.find(
                            (additional_item) =>
                              additional_item.additional_item_id ===
                              movement_additional_items.additional_item_id
                          )?.price || 0
                        )}
                      </TableCell>
                      <TableCell>
                        {arsFormatter(movement_additional_items.subtotal)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
