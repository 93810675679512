import { useMemo, useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  Autocomplete,
} from '@mui/material';

import { AdditionalItem } from 'types';
import { isValidPositiveFloatNumber, toFloat } from 'helpers/formatNumber';
import { useSession } from 'providers/SessionProvider';
import CustomNumberInput from 'components/CustomNumberInput';

type Props = {
  open: boolean;
  handleOpen: () => void;
  onCreate: (newAdditionalItem: AdditionalItem) => void;
};

export default function AddAdditionalItemModal({
  open = true,
  handleOpen,
  onCreate,
}: Props) {
  const defaultValues: Omit<AdditionalItem, 'id'> = {
    description: '',
    price: 0,
    client_id: null,
    client: null,
  };

  const [data, setData] = useState<Omit<AdditionalItem, 'id'>>(defaultValues);
  const { clients } = useSession();
  const [selectedClient, setSelectedClient] = useState<{
    id: number;
    label: string;
  }>({
    id: 0,
    label: 'Todos',
  });

  const handleChange = <K extends keyof Omit<AdditionalItem, 'id'>>(
    name: K,
    value: Omit<AdditionalItem, 'id'>[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = (newAdditionalItem: Omit<AdditionalItem, 'id'>) => {
    onCreate(newAdditionalItem as AdditionalItem);
    setData(defaultValues);
    setSelectedClient({ id: 0, label: 'Todos' });
    handleOpen();
  };

  const handleClose = () => {
    setData(defaultValues);
    setSelectedClient({ id: 0, label: 'Todos' });
    handleOpen();
  };

  const options = useMemo(() => {
    const clientList = clients.map(client => ({
      id: client.id,
      label: client.razon_social,
    }));
    clientList.unshift({ id: 0, label: 'Todos' });
    return clientList;
  }, [clients]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <form
          onSubmit={() => {
            onSubmit(data);
          }}
        >
          <Grid container spacing={2}>
            <Typography variant='h5' gutterBottom>
              Nuevo ítem adicional
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth required margin='none'>
                <TextField
                  label='Descripción'
                  type='text'
                  fullWidth
                  onChange={e => handleChange('description', e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin='none'>
                <Autocomplete
                  id='client'
                  autoComplete
                  value={selectedClient}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  options={options}
                  onChange={(
                    e,
                    value: {
                      id: number;
                      label: string;
                    } | null
                  ) => {
                    if (value?.label === 'Todos') {
                      handleChange('client_id', null);
                    } else {
                      handleChange('client_id', value?.id || null);
                    }
                    setSelectedClient(value || { id: 0, label: 'Todos' });
                  }}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} required label='Cliente' />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required margin='none'>
                <CustomNumberInput
                  label='Precio'
                  required
                  value={data.price}
                  allowOnlyPositive
                  onChange={e => handleChange('price', toFloat(e.target.value))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
              <Button type='button' variant='outlined' onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                type='submit'
                variant='contained'
                disabled={
                  data.price.toString() === '0' ||
                  !isValidPositiveFloatNumber(data.price.toString()) ||
                  data.description === ''
                }
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
}
