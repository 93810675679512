import { config } from '../config';
import { ENTITY } from './entities';
const baseApiUrl = config.apiUrl;

export const AUTH_URL = `${baseApiUrl}/auth`;
export const AUTH_REGISTER_URL = `${baseApiUrl}/auth/register`;
export const AUTH_LOGIN_URL = `${baseApiUrl}/auth/access-token`;
export const AUTH_REFRESH_URL = `${baseApiUrl}/auth/refresh-token`;
 
export const USERS_URL = `${baseApiUrl}/${ENTITY.USERS}/`;
export const CLIENTS_URL = `${baseApiUrl}/${ENTITY.CLIENTS}/`;
export const ASSOCIATED_CLIENTS_URL = `${baseApiUrl}/${ENTITY.ASSOCIATED_CLIENTS}/`;
export const ARTICLES_URL = `${baseApiUrl}/${ENTITY.ARTICLES}/`;
export const STORES_URL = `${baseApiUrl}/${ENTITY.STORES}/`;
export const UNITS_URL = `${baseApiUrl}/${ENTITY.UNITS}/`;
export const CONTAINERS_URL = `${baseApiUrl}/${ENTITY.CONTAINERS}/`;
export const ADDITIONAL_ITEMS_URL = `${baseApiUrl}/${ENTITY.ADDITIONAL_ITEMS}/`;
export const SETTLEMENT_TYPES_URL = `${baseApiUrl}/${ENTITY.SETTLEMENT_TYPES}/`;
export const CLIENT_SETTLEMENT_TYPES_URL = `${baseApiUrl}/${ENTITY.CLIENT_SETTLEMENT_TYPES}/`;
export const MOVEMENTS_URL = `${baseApiUrl}/${ENTITY.MOVEMENTS}/`;
export const MOVEMENT_TYPES_URL = `${baseApiUrl}/${ENTITY.MOVEMENT_TYPES}/`;
export const LOCATIONS_URL = `${baseApiUrl}/${ENTITY.LOCATIONS}/`;
export const MOVEMENT_ARTICLES_URL = `${baseApiUrl}/${ENTITY.MOVEMENT_ARTICLES}/`;
export const ARTICLE_LOCATIONS_URL = `${baseApiUrl}/${ENTITY.ARTICLE_LOCATIONS}/`;
export const LIQUIDATIONS_URL = `${baseApiUrl}/${ENTITY.LIQUIDATIONS}/`;
export const ADDITIONAL_ITEMS_IN_MOVEMENTS_URL = `${baseApiUrl}/${ENTITY.MOVEMENTS}/${ENTITY.ADDITIONAL_ITEMS}/`;

export const ENTITY_NAMES = {
    [STORES_URL]: 'Almacén',
    [LOCATIONS_URL]: 'Ubicación',
    [MOVEMENTS_URL]: 'Movimiento',
    [CLIENTS_URL]: 'Cliente',
    [ASSOCIATED_CLIENTS_URL]: 'Cliente asociado',
    [ARTICLES_URL]: 'Artículo',
    [UNITS_URL]: 'Unidad',
    [CONTAINERS_URL]: 'Contenedor',
    [ADDITIONAL_ITEMS_URL]: 'Item adicional',
    [SETTLEMENT_TYPES_URL]: 'Tipo de liquidación',
    [USERS_URL]: 'Usuario',
  };