import { useEffect, useState } from 'react';

import CustomGrid from '../CustomGrid';
import { gridStructure } from 'constants/CustomGridStructure';

import { Box, Paper } from '@mui/material';

import { GridRowSelectionModel } from '@mui/x-data-grid';

import AddClientModal from './AddClientModal';
import DeleteConfirmationModaL from 'components/utils/DeleteConfirmationModal';
import { EnhancedTableToolbar } from '../EnhancedToolbar';

import { Client } from 'types';
import { CLIENTS_URL as BASE_URL } from 'constants/urls';

import { ENTITY } from 'constants/entities';
import EditClientModal from './EditClientModal';
import Loader from '../Loader';
import useModals from 'hooks/useModals';
import useCrud from 'hooks/useCrud';
import { STATUS_CODES } from 'constants/index';
import { useToast } from 'hooks/useToast';
import { useSession } from 'providers/SessionProvider';
import { useFilter } from 'hooks/useFilter';

export default function ClientList() {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const {
    addModalOpen,
    setAddModalOpen,
    editModalOpen,
    setEditModalOpen,
    deleteModalOpen,
    setDeleteModalOpen,
  } = useModals();
  const {
    loading,
    firstLoad,
    updateData: updateClient,
    deleteData: deleteClient,
    createData: createClient,
    showToast,
    toastMessage,
    toastSeverity,
    setShowToast,
  } = useCrud<Client>(BASE_URL);

  const { clients, addSessionData, updateSessionData, deleteSessionData } =
    useSession();

  const { showSnackBar } = useToast();

  const { filterText, setFilterText, filteredData: filteredClients } = useFilter<Client>({
    data: clients,
    fields: gridStructure[ENTITY.CLIENTS].fields,
  });

  const handleCreate = async (newClient: Client) => {
    const response = await createClient(newClient, true, true);
    if (response.status === STATUS_CODES.OK) {
      addSessionData(ENTITY.CLIENTS, response.data as Client);
    }
  };

  const handleDeleteSelected = async () => {
    const failedDeletes: number[] = [];

    for (const id of selected) {
      const reponse = await deleteClient(id, true, true);
      if (reponse.status !== STATUS_CODES.OK) {
        failedDeletes.push(Number(id));
      }
    }
    deleteSessionData(
      ENTITY.CLIENTS,
      selected
        .filter(item => !failedDeletes.includes(Number(item)))
        .map(item => item.toString())
    );

    setSelected([]);
  };

  const handleEdit = async (clientToEdit: Client) => {
    const response = await updateClient(
      clientToEdit.id,
      clientToEdit,
      true,
      true
    );
    if (response.status === STATUS_CODES.OK) {
      updateSessionData(ENTITY.CLIENTS, clientToEdit);
    }
    setSelected([]);
  };

  useEffect(() => {
    if (showToast) {
      showSnackBar(toastMessage, toastSeverity);
      setShowToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  return (
    <>
      {firstLoad ? (
        <Loader isFirstLoad={firstLoad} isLoading={loading} />
      ) : (
        <>
          <Loader isFirstLoad={firstLoad} isLoading={loading} />
          <AddClientModal
            open={addModalOpen}
            handleOpen={() => setAddModalOpen(!addModalOpen)}
            onCreate={handleCreate}
          />
          <DeleteConfirmationModaL
            open={deleteModalOpen}
            handleOpen={() => setDeleteModalOpen(!deleteModalOpen)}
            entityName={ENTITY.CLIENTS}
            values={selected.map(item => {
              const value = clients.find(c => c.id === item)?.razon_social;
              if (value) return value;
              return '';
            })}
            handleDelete={handleDeleteSelected}
          />
          {selected.length > 0 ? (
            <EditClientModal
              open={editModalOpen}
              handleOpen={() => setEditModalOpen(!editModalOpen)}
              value={clients.find(c => c.id === selected[0]) as Client}
              onEdit={handleEdit}
            />
          ) : null}
          <Box sx={{ width: '99%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                handleAddModalOpen={() => setAddModalOpen(!addModalOpen)}
                handleEditModalOpen={() => setEditModalOpen(!editModalOpen)}
                handleDeleteModalOpen={() =>
                  setDeleteModalOpen(!deleteModalOpen)
                }
                onFilterChange={setFilterText}
              />
              <CustomGrid
                structure={gridStructure[ENTITY.CLIENTS]}
                rows={filteredClients}
                onEdit={handleEdit}
                selectionModel={selected}
                onRowSelection={setSelected}
              />
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}
