import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import { RxArrowDown } from "react-icons/rx";
import { DetailTableProps } from "types";
import { MovementTypes } from "constants/index";
import {
  CustomHeadCell,
  UnitHeaderCell,
} from "components/utils/LiquidationDetails";
import { arsFormatter } from "helpers";
import Row from "./MovementDetailRow";

export default function IncomeDetailTable({
  liquidation,
  print = false,
  chip,
}: DetailTableProps) {
  const theme = useTheme();

  const settlementType = useMemo(
    () => liquidation.liquidation_settlement_types[0],
    [liquidation]
  );

  const movements = useMemo(() => {
    return liquidation.liquidation_movements.filter(
      (m) => m.movement_type_id === MovementTypes.Income
    );
  }, [liquidation]);

  return (
    <Grid item xs={12}>
      <Accordion defaultExpanded={print}>
        <AccordionSummary
          expandIcon={
            !print && (
              <RxArrowDown style={{ color: theme.palette.primary.main }} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">Detalle de Recepciones</Typography>
            {chip && (
              <Chip
                color={chip.color}
                size="small"
                label={chip.label}
                icon={chip.icon}
              />
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} elevation={4}>
            <Table size="small" aria-label="collapsible table">
              <TableHead
                sx={(theme) => ({
                  backgroundColor: theme.palette.primary.main,
                })}
              >
                <TableRow>
                  <CustomHeadCell></CustomHeadCell>
                  <CustomHeadCell>Tipo Mov.</CustomHeadCell>
                  <CustomHeadCell>Nº Remito</CustomHeadCell>
                  <CustomHeadCell>Fecha</CustomHeadCell>
                  {UnitHeaderCell(settlementType.settlement_type)}
                  <CustomHeadCell>Subtotal</CustomHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movements.map((movement, index) => (
                  <Row
                    key={index}
                    row={movement}
                    liquidation={liquidation}
                    settlementType={settlementType}
                    print={print}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={13} align="right">
                    <Typography variant="h6">
                      {"Total Recepción:"}{" "}
                      {arsFormatter(settlementType.subtotal)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
