import { Button, DialogActions } from '@mui/material';
import { PickersActionBarProps } from '@mui/x-date-pickers';

export default function DateTimePickerActionBar(props: PickersActionBarProps) {
  const { onClear, onSetToday, className } = props;
  return (
    <DialogActions className={className}>
      <Button onClick={onClear}>Borrar</Button>
      <Button onClick={onSetToday}>Hoy</Button>
    </DialogActions>
  );
}
