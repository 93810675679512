import { useEffect, useState } from 'react';
import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Container } from 'types';
import { isValidPositiveFloatNumber, toFloat } from 'helpers/formatNumber';

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: Container | undefined;
  onEdit: (container: Container) => void;
};

export default function EditContainerModal({
  open = true,
  handleOpen,
  value,
  onEdit,
}: Props) {
  const [data, setData] = useState<Container>(value || {
    id: 0,
    description: '',
    width: 0,
    height: 0,
    long: 0,
    is_active: false,
  });

  useEffect(() => {
    if (open) {
      setData(value || {
        id: 0,
        description: '',
        width: 0,
        height: 0,
        long: 0,
        is_active: false,
      });
    }
  }, [open, value]);

  const handleChange = <K extends keyof Omit<Container, 'id'>>(
    name: K,
    value: Omit<Container, 'id'>[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = (updatedContainer: Container) => {
    onEdit(updatedContainer);
    handleOpen();
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Editar Contenedor
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              label='Descripción'
              fullWidth
              value={data.description}
              onChange={e => handleChange('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label='Ancho'
              fullWidth
              type='text'
              value={data.width}
              error={
                data.width.toString() !== '0' &&
                !isValidPositiveFloatNumber(data.width.toString())
              }
              helperText={
                data.width.toString() !== '0' &&
                !isValidPositiveFloatNumber(data.width.toString())
                  ? 'El ancho debe ser un número positivo'
                  : ''
              }
              onChange={e => handleChange('width', toFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label='Alto'
              fullWidth
              type='text'
              value={data.height}
              error={
                data.height.toString() !== '0' &&
                !isValidPositiveFloatNumber(data.height.toString())
              }
              helperText={
                data.height.toString() !== '0' &&
                !isValidPositiveFloatNumber(data.height.toString())
                  ? 'El alto debe ser un número positivo'
                  : ''
              }
              onChange={e => handleChange('height', toFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label='Largo'
              fullWidth
              type='text'
              value={data.long}
              error={
                data.long.toString() !== '0' &&
                !isValidPositiveFloatNumber(data.long.toString())
              }
              helperText={
                data.long.toString() !== '0' &&
                !isValidPositiveFloatNumber(data.long.toString())
                  ? 'El largo debe ser un número positivo'
                  : ''
              }
              onChange={e => handleChange('long', toFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.is_active}
                  onChange={(e) => handleChange("is_active", e.target.checked)}
                  color="primary"
                />
              }
              label="Contenedor activo"
            />
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                handleOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              onClick={() => {
                onSubmit(data);
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
