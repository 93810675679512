import { IQuery } from 'types';

export const queryBuilder = <T extends IQuery>(params: T): string => {
  const query = Object.entries(params)
    .filter(([k, v]) => v !== undefined && v !== null)
    .map(([k, v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v))
    .join('&');
  return `?${query}`;
};

export const urlParamsToObject = (
  iterator: URLSearchParams
): {
  [key: string]: string;
} => {
  const arrayIterator = Array.from(iterator.entries());
  const params: { [key: string]: string } = {};
  for (const [key, value] of arrayIterator) {
    params[key] = value;
  }
  return params;
};
