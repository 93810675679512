import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

type Props = {
  pageTitle: string;
  pageDesc: string;
  children: React.ReactNode;
  importButton?: React.ReactNode;
};

export default function PageLayout({
  pageTitle,
  pageDesc,
  children,
  importButton,
}: Props) {
  return (
    <Box width={'100%'} height={'100%'}>
      <Grid container displayPrint='none'>
        <Grid item xs={12} display='flex' alignItems='center' gap={1}>
          <Typography variant='h4' fontWeight='bold'>
            {pageTitle}
          </Typography>
        </Grid>
        <Grid
          item
          xs={importButton ? 6 : 12}
          display='flex'
          alignItems='center'
        >
          <Typography variant='h6' fontWeight='regular' sx={{ py: '0.5rem' }}>
            {pageDesc}
          </Typography>
        </Grid>
        {importButton && (
          <Grid
            item
            xs={6}
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            height='100%'
          >
            {importButton}
          </Grid>
        )}
      </Grid>
      {children}
    </Box>
  );
}
