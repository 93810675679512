import React, { useState, useMemo, createContext } from 'react';
import {
  ThemeProvider,
  CssBaseline,
  createTheme,
  useMediaQuery,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import es from 'date-fns/locale/es';
import { ToastProvider } from 'providers/ToastProvider';
import { ConfirmationModalProvider } from 'providers/ConfirmationModalProvider';
import { SessionProvider } from 'providers/SessionProvider';
import { Router } from './Router';
export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState<'light' | 'dark'>(
    prefersDarkMode ? 'dark' : 'light'
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
        localStorage.setItem('theme', mode === 'light' ? 'dark' : 'light');
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: {
            main: '#cb282b',
          },
          secondary: {
            main: '#325294',
          },
        },
        typography: {
          fontFamily: ['Rubik', 'sans-serif'].join(','),
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
          },
        },
        components: {
          MuiButton: {
            defaultProps: {
              size: 'small',
            },
          },
          MuiFilledInput: {
            defaultProps: {
              margin: 'dense',
            },
          },
          MuiFormControl: {
            defaultProps: {
              margin: 'dense',
            },
          },
          MuiFormHelperText: {
            defaultProps: {
              margin: 'dense',
            },
          },
          MuiIconButton: {
            defaultProps: {
              size: 'small',
            },
          },
          MuiInputBase: {
            defaultProps: {
              margin: 'dense',
            },
          },
          MuiInputLabel: {
            defaultProps: {
              margin: 'dense',
              size: 'small',
            },
          },
          MuiListItem: {
            defaultProps: {
              dense: true,
            },
          },
          MuiOutlinedInput: {
            defaultProps: {
              margin: 'dense',
            },
          },
          MuiFab: {
            defaultProps: {
              size: 'small',
            },
          },
          MuiTable: {
            defaultProps: {
              size: 'small',
            },
          },
          MuiSelect: {
            defaultProps: {
              size: 'small',
            },
          },
          MuiTextField: {
            defaultProps: {
              margin: 'dense',
              size: 'small',
              color: 'secondary',
            },
          },
          MuiToolbar: {
            defaultProps: {
              variant: 'dense',
            },
          },
          MuiAutocomplete: {
            defaultProps: {
              noOptionsText: 'No hay opciones disponibles.',
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ToastProvider>
      <SessionProvider>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={es}
            >
              <ConfirmationModalProvider>
                <CssBaseline />
                <Router />
              </ConfirmationModalProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </SessionProvider>
    </ToastProvider>
  );
}

export default App;
