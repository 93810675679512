import { useState } from 'react';
import { Grid, Tabs, Tab, Box, Divider } from '@mui/material';

import PageLayout from 'components/layout/PageLayout';
import { Liquidation } from 'types';
import LiquidationCreate from 'components/Liquidations/LiquidationCreation/LiquidationCreate';
import LiquidationsHistory from 'components/Liquidations/LiquidationsHistory/LiquidationsHistory';
import { Outlet } from 'react-router-dom';
import { useSession } from 'providers/SessionProvider';

export default function LiquidationsPage() {
  const { stores, clients } = useSession();

  const blankLiquidation = {
    client_id: null,
    store_id: null,
    date_from: null,
    date_to: null,
    observations: '',
    additional_items: []
  };

  const [currentTab, setCurrentTab] = useState<number>(1);
  const [liquidation, setLiquidation] = useState<Liquidation>(blankLiquidation);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleChange = <K extends keyof Liquidation>(
    name: K,
    value: Liquidation[K]
  ) => {
    setLiquidation(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <PageLayout
      pageTitle='Liquidaciones'
      pageDesc='Liquidaciones de ingresos, estadías, egresos y adicionales'
    >
      <Box my={1}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab value={1} label='Detalles de Liquidaciones' />
          <Tab value={2} label='Crear Liquidacion' />
        </Tabs>
      </Box>
      <Divider />
      <Grid container spacing={1} columnSpacing={2} maxWidth={'100%'}>
        {currentTab === 1 && <LiquidationsHistory clients={clients} />}
        {currentTab === 2 && (
          <LiquidationCreate
            clients={clients}
            stores={stores}
            liquidation={liquidation}
            handleChange={handleChange}
            resetLiquidation={() => setLiquidation(blankLiquidation)}
          />
        )}
      </Grid>
      <Outlet />
    </PageLayout>
  );
}
