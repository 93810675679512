/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useRef, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";

import PageLayout from "../components/layout/PageLayout";

import OperationPrintDetails from "components/Prints/Operations/OperationPrintDetails";
import OperationPrintHeaderDetails from "components/Prints/Operations/OperationPrintHeaderDetails";
import { PrintLayout } from "components/Prints/PrintLayout";
import Loader from "components/lists/Loader";
import DateTimePickerActionBar from "components/utils/DatePickerActionBar";
import { ARTICLE_LOCATIONS_URL, MOVEMENTS_URL } from "constants/urls";
import { dateISOFormat } from "helpers";
import useCrud from "hooks/useCrud";
import { useToast } from "hooks/useToast";
import { useSession } from "providers/SessionProvider";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import {
  ArticleLocation,
  AssociatedClient,
  Location,
  Movement,
  Transfer,
} from "types";
import {
  DATE_VIEWS,
  MovementTypes,
  STATUS_CODES,
  defaultMovement,
} from "../constants";
import TransferLocationTable from "components/Transfer/TransferLocationTable";
import TransferNewLocation from "components/Transfer/TransferNewLocation";
import CustomNumberInput from "components/CustomNumberInput";

export enum DestinationTypes {
  Existing = 1,
  New = 2,
}

export type SelectedArticleLocations = {
  from: ArticleLocation | null;
  to: ArticleLocation | null;
};

export default function TransferPage() {
  const [movement, setMovement] = useState<Movement>({
    ...defaultMovement,
    movement_type_id: MovementTypes.Transfer,
  });

  const [destinationType, setDestinationType] = useState<DestinationTypes>(
    DestinationTypes.Existing
  );

  const [transferStock, setTransferStock] = useState<{
    isTotal: boolean | null;
    value: number;
  }>({
    isTotal: null,
    value: 0,
  });

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => {},
    onAfterPrint: async () => {
      resetTransfer();
      await getArticleLocations();
      setMovementLoading(false);
      showSnackBar("La transferencia se ha realizado exitosamente", "success");
    },
  });

  const [movementLoading, setMovementLoading] = useState<boolean>(false);
  const [clientAssociatedClients, setClientAssociatedClients] = useState<
    AssociatedClient[]
  >([]);

  const {
    loading: articleLocationLoading,
    firstLoad: articleLocationsFirstLoad,
    data: articleLocations,
    fetchData: getArticleLocations,
  } = useCrud<ArticleLocation>(ARTICLE_LOCATIONS_URL);
  const { data: movementById, fetchData: getMovementById } = useCrud<Movement>(
    MOVEMENTS_URL,
    { disableAutoFetch: true }
  );
  const { data: createdMovements, createData: createMovement } = useCrud<
    Movement[]
  >(MOVEMENTS_URL, {
    disableAutoFetch: true,
  });

  const { userData, stores, clients, associatedClients, locations, users } =
    useSession();
  const { showSnackBar } = useToast();

  const [selectedStoreLocations, setSelectedStoreLocations] =
    useState<Location[]>(locations);

  const handleChangeMovement = (
    name: string,
    value: string | number | Transfer | null
  ) => {
    setMovement((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeDestinationType = (e: ChangeEvent<HTMLInputElement>) => {
    setTransferStock({ isTotal: null, value: 0 });
    setMovement({
      ...movement,
      transfer: movement.transfer
        ? { from: null, to: null, new_location_id: null, amount: 0 }
        : undefined,
    });
    setDestinationType(parseInt(e.target.value));
  };

  const resetTransfer = () => {
    setTransferStock({ isTotal: null, value: 0 });
    setMovement({
      ...defaultMovement,
      created_by: movement.created_by,
      movement_type_id: MovementTypes.Transfer,
    });
  };

  const submitValidation = () => {
    if (destinationType === DestinationTypes.Existing) {
      return !(movement.transfer?.from && movement.transfer?.to);
    } else {
      return !movement.transfer?.new_location_id;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMovementLoading(true);
    if (movement.transfer) {
      const response = await createMovement(
        [
          {
            ...movement,
            transfer: {
              from: movement.transfer.from,
              to: movement.transfer.to,
              amount: movement.transfer.amount,
              new_location_id: movement.transfer.new_location_id,
              user_id: movement.created_by,
            },
          },
        ],
        true
      );

      if (response.status === STATUS_CODES.OK) {
        const aggregatedMovement = await getMovementById(
          `${response.data[0].id}`,
          true
        );

        setMovement(aggregatedMovement.data[0]);
      } else {
        setMovementLoading(false);
        showSnackBar(
          response.status === STATUS_CODES.BAD_REQUEST
            ? "El número de remito ya se encuentra registrado, por favor modifiquelo e intente nuevamente."
            : "No se ha podido realizar la transferencia, por favor intente nuevamente",
          "error"
        );
      }
    } else {
      setMovementLoading(false);
    }
  };

  useEffect(() => {
    setClientAssociatedClients(
      associatedClients.filter((ac) => ac.client_id === movement.client_id)
    );
  }, [movement.client_id]);

  useEffect(() => {
    if (movement.store_id !== 0 && movement.store_id) {
      setSelectedStoreLocations(
        locations.filter((l) => l.store_id === movement.store_id)
      );
    }
  }, [movement.store_id]);

  useEffect(() => {
    if (userData) {
      setMovement({
        ...movement,
        created_by: userData.id,
      });
    }
  }, [userData]);

  useEffect(() => {
    movement.transfer &&
      setMovement({
        ...movement,
        transfer: { ...movement.transfer, amount: transferStock.value },
      });
  }, [transferStock.value]);

  useEffect(() => {
    async function getPrintMovement() {
      await getMovementById(`${createdMovements[0][0].id}`);
    }
    if (createdMovements.length > 0) {
      getPrintMovement();
    }
  }, [createdMovements]);

  useEffect(() => {
    if (movementById.length > 0) {
      handlePrint();
    }
  }, [movementById]);

  return (
    <PageLayout
      pageTitle="Transferencia"
      pageDesc="Orden de transferencia de mercadería"
    >
      {movementById.length > 0 && (
        <PrintLayout
          ref={printRef}
          headerInfo={
            <OperationPrintHeaderDetails
              movement={movementById[0]}
              stores={stores}
            />
          }
        >
          <OperationPrintDetails
            movement={movementById[0]}
            client={movementById[0].client?.razon_social}
          />
        </PrintLayout>
      )}
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        {articleLocationLoading || movementLoading ? (
          <Loader isLoading={true} isFirstLoad={articleLocationsFirstLoad} />
        ) : (
          <Grid container columnSpacing={1}>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="user"
                  autoComplete
                  value={{
                    id: movement.created_by,
                    label: `${
                      users.find((u) => u.id === movement.created_by)
                        ?.first_name ?? ""
                    } ${
                      users.find((u) => u.id === movement.created_by)
                        ?.last_name ?? ""
                    }`,
                  }}
                  options={users?.map((u) => ({
                    id: u.id,
                    label: `${u.first_name} ${u.last_name}`,
                  }))}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value: { id: number; label: string } | null) =>
                    handleChangeMovement("created_by", value?.id ?? null)
                  }
                  renderInput={(params) => (
                    <TextField {...params} required label="Usuario" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="author"
                  autoComplete
                  options={users?.map((u) => ({
                    id: u.id,
                    label: `${u.first_name} ${u.last_name}`,
                  }))}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disabled={!movement.created_by}
                  onChange={(e, value: { id: number; label: string } | null) =>
                    handleChangeMovement("updated_by", value?.id ?? null)
                  }
                  renderInput={(params) => (
                    <TextField {...params} required label="Preparó" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <DateTimePicker
                  disableFuture
                  label="Fecha"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slots={{
                    actionBar: DateTimePickerActionBar,
                  }}
                  onChange={(value) => {
                    handleChangeMovement(
                      "date",
                      dateISOFormat(value as string)
                    );
                    handleChangeMovement(
                      "settlement_date",
                      dateISOFormat(value as string)
                    );
                  }}
                  views={DATE_VIEWS}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="client"
                  autoComplete
                  options={clients?.map((c) => ({
                    id: c.id,
                    label: c.razon_social,
                  }))}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(
                    e,
                    value: {
                      id: number;
                      label: string;
                    } | null
                  ) => {
                    setMovement((prev) => ({
                      ...prev,
                      client_id: value?.id ?? 0,
                      associated_client: "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} required label="Cliente" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  id="associated-client"
                  options={clientAssociatedClients.map((ac) => ({
                    label: ac.razon_social,
                  }))}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  value={{ label: movement.associated_client }}
                  disabled={!movement.client_id}
                  onChange={(e, value: { label: string } | null) =>
                    handleChangeMovement(
                      "associated_client",
                      value?.label ?? null
                    )
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} required label="Cliente Asociado" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth required margin="none">
                <CustomNumberInput
                  label="Documento del Cliente"
                  value={movement.client_document}
                  onChange={(e) => handleChangeMovement("client_document", e.target.value)}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="none">
                <CustomNumberInput
                  label="Número de Remito"
                  value={movement.numero_remito}
                  onChange={(e) => handleChangeMovement("numero_remito", e.target.value)}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="observations"
                label="Observaciones"
                type="text"
                fullWidth
                value={movement.observation}
                onChange={(e) =>
                  handleChangeMovement("observation", e.target.value)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={2}
              sx={(theme) => ({
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: "1rem",
              })}
            >
              <MdOutlineKeyboardDoubleArrowDown size={50} />
              <Box display="flex" justifyContent="center" width="30rem">
                <FormControl>
                  <RadioGroup
                    value={destinationType}
                    row
                    onChange={handleChangeDestinationType}
                  >
                    <FormControlLabel
                      value={DestinationTypes.Existing}
                      control={<Radio />}
                      label="Ubicación Existente"
                    />
                    <FormControlLabel
                      value={DestinationTypes.New}
                      control={<Radio />}
                      label="Ubicación Nueva"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <MdOutlineKeyboardDoubleArrowDown size={50} />
            </Grid>
            <Grid item xs={12}>
              <TransferLocationTable
                tableType={"from"}
                articleLocations={articleLocations}
                transferStock={transferStock}
                setTransferStock={setTransferStock}
                movement={movement}
                handleChangeMovement={handleChangeMovement}
              />
            </Grid>
            <Grid item xs={12}>
              {destinationType === DestinationTypes.Existing ? (
                <TransferLocationTable
                  tableType={"to"}
                  articleLocations={articleLocations}
                  transferStock={transferStock}
                  setTransferStock={setTransferStock}
                  movement={movement}
                  handleChangeMovement={handleChangeMovement}
                />
              ) : (
                <TransferNewLocation
                  stores={stores}
                  locations={selectedStoreLocations}
                  articleLocations={articleLocations}
                  handleChange={handleChangeMovement}
                  movement={movement}
                />
              )}
            </Grid>
            <Grid item xs={12} mt={4}>
              <Button
                type="submit"
                variant="contained"
                disabled={submitValidation()}
              >
                Transferir
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </PageLayout>
  );
}
