import { useEffect, useMemo, useState } from 'react';

import CustomGrid from '../CustomGrid';
import { gridStructure } from 'constants/CustomGridStructure';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { Box, Paper } from '@mui/material';
import Loader from '../Loader';

import AddArticleModal from './AddArticleModal';
import EditArticleModal from './EditArticleModal';
import DeleteConfirmationModaL from 'components/utils/DeleteConfirmationModal';
import { EnhancedTableToolbar } from '../EnhancedToolbar';

import { Article, Container } from 'types';
import { ARTICLES_URL as BASE_URL, CONTAINERS_URL } from 'constants/urls';
import { ENTITY } from 'constants/entities';

import useModals from 'hooks/useModals';
import useCrud from 'hooks/useCrud';
import { STATUS_CODES } from 'constants/index';
import { useToast } from 'hooks/useToast';
import { useSession } from 'providers/SessionProvider';
import { useFilter } from 'hooks/useFilter';

export default function ArticlesList() {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const {
    addModalOpen,
    setAddModalOpen,
    editModalOpen,
    setEditModalOpen,
    deleteModalOpen,
    setDeleteModalOpen,
  } = useModals();

  const {
    loading,
    firstLoad,
    updateData: updateArticle,
    deleteData: deleteArticle,
    createData: createArticle,
    showToast,
    toastMessage,
    toastSeverity,
    setShowToast,
  } = useCrud<Article>(BASE_URL);

  const { createData: createContainer, updateData: updateContainer } =
    useCrud<Container>(CONTAINERS_URL, {
      disableAutoFetch: true,
    });

  const {
    addSessionData,
    addMultipleSessionData,
    updateSessionData,
    updateMultipleSessionData,
    deleteSessionData,
    articles,
    containers,
    clients,
    units,
  } = useSession();

  const { filterText, setFilterText, filteredData: filteredArticles } = useFilter<Article>({
    data: articles,
    fields: gridStructure[ENTITY.ARTICLES].fields,
  });

  const { showSnackBar } = useToast();

  const handleCreateContainer = async (newContainer: Container): Promise<{ status: number; data: Container }> => {
    const response = await createContainer(newContainer, true, true);
  
    if (response.status === STATUS_CODES.OK && response.data) {
      addSessionData(ENTITY.CONTAINERS, response.data as Container);
      return { status: response.status, data: response.data as Container };
    } else {
      throw new Error('Error al crear el contenedor.');
    }
  };
  

  const handleEditContainer = async (
    articleToEdit: Article,
    containerToEdit: Container
  ) => {
    const response = await updateContainer(
      containerToEdit.id,
      containerToEdit,
      true
    );
    if (response.status === STATUS_CODES.OK) {
      updateMultipleSessionData(
        [ENTITY.ARTICLES, ENTITY.CONTAINERS],
        [articleToEdit, containerToEdit]
      );
    }
  };

  const handleDeleteSelected = async () => {
    const failedDeletes: number[] = [];

    for (const id of selected) {
      const reponse = await deleteArticle(id, true, true);
      if (reponse.status !== STATUS_CODES.OK) {
        failedDeletes.push(Number(id));
      }
    }
    deleteSessionData(
      ENTITY.ARTICLES,
      selected
        .filter(item => !failedDeletes.includes(Number(item)))
        .map(item => item.toString())
    );

    setSelected([]);
  };

  const checkSelectedArticlesStock = (): boolean => {
    let canDelete = true;
    selected.map(item => {
      const value = articles.find(a => a.id === item)?.amount;
      if (value && value > 0) canDelete = false;
    });

    return canDelete;
  };

  const handleCreate = async (
    newArticle: Article,
    hasNewContainer?: boolean
  ) => {
    const response = await createArticle(newArticle, true, true);
    if (response.status === STATUS_CODES.OK) {
      hasNewContainer
        ? addMultipleSessionData(
            [ENTITY.ARTICLES, ENTITY.CONTAINERS],
            [response.data as Article, newArticle.container as Container]
          )
        : addSessionData(ENTITY.ARTICLES, response.data as Article);
    }
  };

  const handleEdit = async (
    articleToEdit: Article,
    containerToEdit?: Container
  ) => {
    const response = await updateArticle(
      articleToEdit.id,
      articleToEdit,
      true,
      true
    );
    if (response.status === STATUS_CODES.OK) {
      if (containerToEdit) {
        await handleEditContainer(articleToEdit, containerToEdit as Container);
        updateSessionData(ENTITY.CONTAINERS, containerToEdit);
      }
      updateSessionData(ENTITY.ARTICLES, articleToEdit);
    }
    setSelected([]);
  };
  

  useEffect(() => {
    if (showToast) {
      showSnackBar(toastMessage, toastSeverity);
      setShowToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  return (
    <>
      {firstLoad ? (
        <Loader isFirstLoad={firstLoad} isLoading={loading} />
      ) : (
        <>
          <AddArticleModal
            open={addModalOpen}
            handleOpen={() => setAddModalOpen(!addModalOpen)}
            onCreate={handleCreate}
            containers={containers}
            clients={clients}
            units={units}
            onNewContainerCreate={handleCreateContainer}
          />
          <DeleteConfirmationModaL
            open={deleteModalOpen}
            handleOpen={() => setDeleteModalOpen(!deleteModalOpen)}
            entityName={'Artículos'}
            canDelete={checkSelectedArticlesStock()}
            values={selected.map(item => {
              const value = articles.find(a => a.id === item)?.description;
              if (value) return value;
              return '';
            })}
            handleDelete={handleDeleteSelected}
          />
          {selected.length > 0 ? (
            <EditArticleModal
              open={editModalOpen}
              handleOpen={() => setEditModalOpen(!editModalOpen)}
              value={articles.find(a => a.id === selected[0])}
              onEdit={handleEdit}
              clients={clients}
              units={units}
            />
          ) : null}
          <Box sx={{ width: '99%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                handleAddModalOpen={() => setAddModalOpen(!addModalOpen)}
                handleEditModalOpen={() => setEditModalOpen(!editModalOpen)}
                handleDeleteModalOpen={() =>
                  setDeleteModalOpen(!deleteModalOpen)
                }
                onFilterChange={setFilterText}
              />
              <CustomGrid<Article>
                structure={gridStructure[ENTITY.ARTICLES]}
                rows={filteredArticles}  
                onEdit={handleEdit}
                selectionModel={selected}
                onRowSelection={setSelected}
              />
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}
