import { useEffect, useState } from 'react';

import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { Store } from 'types';

type Props = {
  open: boolean;
  handleOpen: () => void;
  value: Store;
  onEdit: (store: Store) => void;
};

export default function EditStoreModal({
  open = true,
  handleOpen,
  value,
  onEdit,
}: Props) {

  const [data, setData] = useState<Store>(value);

  useEffect(() => {
    if (open) {
      setData(value);
    }
  }, [open, value]);

  const handleChange = <K extends keyof Omit<Store, 'id'>>(
    name: K,
    value: Omit<Store, 'id'>[K]
  ) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Modal open={open} onClose={handleOpen}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { sm: '90%', md: '30rem' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant='h5' gutterBottom>
            Editar almacén
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              id='outlined-required'
              label='Código'
              fullWidth
              type='text'
              value={data.code}
              onChange={e => handleChange('code', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.is_active}
                  onChange={(e) => handleChange("is_active", e.target.checked)}
                  color="primary"
                />
              }
              label="Almacén activo"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id='outlined-required'
              label='Descripción'
              fullWidth
              value={data.description}
              onChange={e => handleChange('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' gap={1}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                handleOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                onEdit(data);
                handleOpen();
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}