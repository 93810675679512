import {
  Box,
  Grid,
  Paper,
  Radio,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import CustomNumberInput from "components/CustomNumberInput";
import { SETTLEMENT_TYPES_URL } from "constants/urls";
import useCrud from "hooks/useCrud";
import { useState } from "react";
import { BsBoxFill } from "react-icons/bs";
import { RxDownload, RxUpload } from "react-icons/rx";
import { Client, ClientPreference, SettlementType } from "types";

const ClientSettlementTypesTableHeader = ({
  columns,
}: {
  columns: string[];
}) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          return (
            <TableCell
              align={column === "Valor por defecto" ? "center" : "left"}
            >
              <Typography variant="body2">{column}</Typography>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const ClientSettlementTypesTableRow = ({
  content,
  selectDefaultRow,
  handlePriceChange,
}: {
  content: ClientPreference;
  selectDefaultRow: (type: string | undefined, movement_type: string) => void;
  handlePriceChange: (
    type: string | undefined,
    movement_type: string,
    price: number
  ) => void;
}) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body1" gutterBottom>
          {content.settlement_type.description}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Radio
          size="small"
          checked={content.is_default}
          onClick={() => {
            selectDefaultRow(
              content.settlement_type.description,
              content.movement_type
            );
          }}
        />
      </TableCell>
      <TableCell>
        <CustomNumberInput
          label="$"
          value={content.price}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (content.is_default) {
              if (value >= 0) {
                handlePriceChange(
                  content.settlement_type.description,
                  content.movement_type,
                  value
                );
              }
            } else {
              handlePriceChange(
                content.settlement_type.description,
                content.movement_type,
                value
              );
            }
          }}
        />
      </TableCell>
    </TableRow>
  );
};
const ClientSettlementTypesTable = ({
  clientPreferences,
  handleDefaultChange,
  handlePriceChange,
}: {
  clientPreferences: ClientPreference[];
  handleDefaultChange: (
    type: string | undefined,
    movement_type: string
  ) => void;
  handlePriceChange: (
    type: string | undefined,
    movement_type: string,
    price: number
  ) => void;
}) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <ClientSettlementTypesTableHeader
          columns={["Tipo de liquidacion", "Valor por defecto", "Precio"]}
        />
        <TableBody>
          {clientPreferences.map((row, index) => {
            return (
              <ClientSettlementTypesTableRow
                key={index}
                content={row}
                selectDefaultRow={handleDefaultChange}
                handlePriceChange={handlePriceChange}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function ClientPreferences({
  client,
  onChange,
}: {
  client: Client;
  onChange: <K extends keyof Omit<Client, "id">>(
    name: K,
    value: Omit<Client, "id">[K]
  ) => void;
}) {
  const MOVEMENT_TYPES = ["INGRESO", "ESTADIA", "EGRESO"];
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [clientSettlementTypes, setClientSettlementTypes] = useState<
    ClientPreference[]
  >(client.client_settlement_types || []);

  const { data: settlementTypes } =
    useCrud<SettlementType>(SETTLEMENT_TYPES_URL);

  settlementTypes.forEach((settlementType) => {
    MOVEMENT_TYPES.forEach((movementType) => {
      if (
        !clientSettlementTypes.find(
          (clientSettlementType) =>
            clientSettlementType.settlement_type_id === settlementType.id &&
            clientSettlementType.movement_type === movementType
        )
      ) {
        setClientSettlementTypes((prevState) => [
          ...prevState,
          {
            settlement_type_id: settlementType.id,
            movement_type: movementType,
            price: 0,
            is_default: false,
            settlement_type: settlementType,
          },
        ]);
      }
    });
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleDefaultChange = (
    type: string | undefined,
    movement_type: string
  ) => {
    let update = clientSettlementTypes.map((clientSettlementTypes) => {
      if (
        clientSettlementTypes.settlement_type?.description === type &&
        clientSettlementTypes.movement_type === movement_type
      ) {
        clientSettlementTypes.is_default = true;
      } else if (
        clientSettlementTypes.settlement_type?.description !== type &&
        clientSettlementTypes.movement_type === movement_type
      ) {
        clientSettlementTypes.is_default = false;
      }
      return clientSettlementTypes;
    });
    setClientSettlementTypes(update);
    onChange(
      "client_settlement_types",
      update.filter((preference) => Number(preference.price) >= 0)
    );
  };

  const handlePriceChange = (
    type: string | undefined,
    movement_type: string,
    price: number
  ) => {
    let update = clientSettlementTypes.map((clientSettlementTypes) => {
      if (
        clientSettlementTypes.settlement_type?.description === type &&
        clientSettlementTypes.movement_type === movement_type
      ) {
        clientSettlementTypes.price = price;
      }
      return clientSettlementTypes;
    });
    setClientSettlementTypes(update);
    onChange(
      "client_settlement_types",
      update.filter((preference) => preference.price >= 0)
    );
  };

  return (
    <Paper elevation={4}>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={1}
      >
        <Typography variant="h5">Tipos de Liquidación</Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          centered
          textColor="primary"
        >
          <Tab
            sx={{ textTransform: "none" }}
            value={1}
            label={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
              >
                <RxDownload />
                <Typography variant="body1">Ingreso *</Typography>
              </Box>
            }
          />
          <Tab
            sx={{ textTransform: "none" }}
            value={2}
            label={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
              >
                <BsBoxFill />
                <Typography variant="body1">Estadía *</Typography>
              </Box>
            }
          />
          <Tab
            sx={{ textTransform: "none" }}
            value={3}
            label={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
              >
                <RxUpload />
                <Typography variant="body1">Egreso *</Typography>
              </Box>
            }
          />
        </Tabs>
      </Grid>
      <Grid item xs={12} p={2}>
        <ClientSettlementTypesTable
          handleDefaultChange={handleDefaultChange}
          handlePriceChange={handlePriceChange}
          clientPreferences={clientSettlementTypes.filter(
            (clientSettlementType) =>
              clientSettlementType.movement_type ===
              MOVEMENT_TYPES[currentTab - 1]
          )}
        />
      </Grid>
    </Paper>
  );
}
