import {
    Alert,
    Autocomplete,
    Box,
    FormControl,
    Grid,
    Paper,
    TextField,
    Typography,
  } from '@mui/material';
  
  import { ArticleLocation, Location, Movement, Store, Transfer } from 'types';
  
  type Props = {
    stores: Store[];
    locations: Location[];
    articleLocations: ArticleLocation[];
    handleChange: (
      name: string,
      value: string | number | Transfer | null
    ) => void;
    movement: Movement;
  };
  
  export default function TransferNewLocation({
    stores,
    locations,
    articleLocations,
    handleChange,
    movement,
  }: Props) {
    return (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box width='100%' p={2}>
          <Typography variant='h6'>Hasta</Typography>
        </Box>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth margin='none'>
                <Autocomplete
                  id='warehouse'
                  autoComplete
                  options={stores
                    .filter(s => s.is_active)
                    .map(s => ({
                      id: s.id,
                      label: s.description,
                    }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value: { id: number; label: string } | null) => {
                    handleChange('store_id', value?.id ?? null);
                  }}
                  fullWidth
                  renderInput={params => (
                    <TextField {...params} required label='Almacen' />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin='none'>
                <Autocomplete
                  autoComplete
                  disableClearable
                  disabled={
                    !movement.store_id ||
                    locations.filter(
                      l => !articleLocations.some(al => al.location_id === l.id)
                    ).length === 0
                  }
                  options={locations.filter(
                    l => !articleLocations.some(al => al.location_id === l.id)
                  )}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.code}
                  onChange={(e, value: Location | null) => {
                    if (value) {
                      handleChange('transfer', {
                        from: movement.transfer?.from ?? null,
                        to: movement.transfer?.to ?? null,
                        amount: movement.transfer?.amount ?? 0,
                        new_location_id: value.id,
                      });
                    }
                  }}
                  renderInput={params => (
                    <TextField {...params} required label='Seleccione' />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {locations.filter(
                l => !articleLocations.some(al => al.location_id === l.id)
              ).length === 0 && (
                <Alert severity='warning' sx={{ height: '100%' }}>
                  No hay ubicaciones vacias disponibles en el almacen
                  seleccionado.
                </Alert>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
  