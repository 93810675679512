import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  CustomHeadCell,
  MovementSettlementHeaderCells,
  MovementTypeHeaderCell,
  UnitHeaderCell,
  UnitValueCell,
  UnitValueHelperText,
} from "components/utils/LiquidationDetails";
import { SETTLEMENT_TYPES } from "constants/index";
import { arsFormatter, floatFormatter } from "helpers";
import { dateToArgentinianTime } from "helpers/utcFormatter";
import React, { useMemo } from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import {
  LiquidationAggregation,
  LiquidationSettlementType,
  MovementLiquidation,
} from "types";

export default function Row({
  row,
  liquidation,
  settlementType,
  print,
}: {
  row: MovementLiquidation;
  liquidation: LiquidationAggregation;
  settlementType: LiquidationSettlementType;
  print: boolean;
}) {
  const [open, setOpen] = React.useState(print);

  const articles = useMemo(() => {
    return liquidation.liquidation_movement_articles.filter(
      (ma) => ma.movement_id === row.movement_id
    );
  }, [row, liquidation]);

  const unitsTotal = useMemo(() => {
    switch (settlementType.settlement_type) {
      case SETTLEMENT_TYPES.ABONO:
        return 0;
      case SETTLEMENT_TYPES.PALLETS:
        return row.pallets;
      case SETTLEMENT_TYPES.PESO:
        return row.weight;
      case SETTLEMENT_TYPES.VOLUMEN:
        let volumen = 0;
        articles.forEach((movement_article) => {
          const container = liquidation.liquidation_articles.find(
            (article) => article.article_id === movement_article.article_id
          )?.container;

          if (container) {
            const { width, height, long } = container;
            volumen += width * height * long * movement_article.amount;
          }
        });
        return volumen;
      case SETTLEMENT_TYPES.SUPERFICIE:
        let superficie = 0;
        articles.forEach((movement_article) => {
          const container = liquidation.liquidation_articles.find(
            (article) => article.article_id === movement_article.article_id
          )?.container;

          if (container) {
            const { width, height } = container;
            superficie += width * height * movement_article.amount;
          }
        });
        return superficie;
    }
  }, [row, liquidation, settlementType, articles]);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {!print && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <BsArrowUp /> : <BsArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{MovementTypeHeaderCell(row.movement_type_id)}</TableCell>
        <TableCell>{row.numero_remito}</TableCell>
        <TableCell>{dateToArgentinianTime(row.date)}</TableCell>
        <TableCell>{floatFormatter(unitsTotal || 0)}</TableCell>
        <TableCell>{arsFormatter(row.subtotal)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalle del Movimiento
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {MovementSettlementHeaderCells(settlementType.settlement_type)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {articles.map((movement_article, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {
                          liquidation.liquidation_articles.find(
                            (article) =>
                              article.article_id === movement_article.article_id
                          )?.code
                        }
                      </TableCell>
                      <TableCell>
                        {
                          liquidation.liquidation_articles.find(
                            (article) =>
                              article.article_id === movement_article.article_id
                          )?.description
                        }
                      </TableCell>
                      <TableCell>{movement_article.amount}</TableCell>

                      {
                        settlementType.settlement_type !== SETTLEMENT_TYPES.ABONO && (
                          UnitValueCell(
                            settlementType.settlement_type,
                            liquidation,
                            movement_article
                          )
                        )}

                      {
                        settlementType.settlement_type !== SETTLEMENT_TYPES.ABONO && (
                          <>
                            <TableCell>
                              {floatFormatter(movement_article.value)}
                            </TableCell>
                            <TableCell>
                              {arsFormatter(settlementType.calculation)}
                            </TableCell>
                            <TableCell>
                              {arsFormatter(movement_article.subtotal)}
                            </TableCell>
                          </>
                        )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
