import { Typography, Alert as MUIAlert } from '@mui/material';

type AlertProps = {
  severity: 'error' | 'info' | 'success' | 'warning';
  text: string;
  variant?: 'filled' | 'outlined' | 'standard';
};

export default function Alert({ severity, text, variant }: AlertProps) {
  return (
    <MUIAlert severity={severity}>
      <Typography variant='body2' textAlign='center'>
        {text}
      </Typography>
    </MUIAlert>
  );
}
