import { useNavigate } from 'react-router-dom';

import { Avatar, Divider, Menu, MenuItem } from '@mui/material';

import { ROUTES } from 'constants/index';
import { useSession } from 'providers/SessionProvider';
import { MdLogout, MdSettings } from 'react-icons/md';

type Props = {
  handleClose: () => void;
  anchorEl: null | HTMLElement;
  open: boolean;
};

export default function UserMenu({ handleClose, anchorEl, open }: Props) {
  const navigate = useNavigate();
  const { logOut } = useSession();

  return (
    <Menu
      anchorEl={anchorEl}
      id='account-menu'
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              svg: {
                mr: 0,
              },
              width: 25,
              height: 25,
              mr: 1,
            },
            svg: {
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={() => navigate(ROUTES.USER_SETTINGS)}>
        <Avatar /> Mi Perfil
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => navigate(ROUTES.CHANGE_PASSWORD)}>
        <MdSettings />
        Cambiar contraseña
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          logOut();
          navigate(ROUTES.INDEX);
        }}
      >
        <MdLogout /> Salir
      </MenuItem>
    </Menu>
  );
}
