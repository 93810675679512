import 'App.css';
import React, { useContext, useEffect, useState } from 'react';

import {
  AppBar,
  Avatar,
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  ToolbarProps,
  Tooltip,
  styled,
  useTheme,
} from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import { BiSolidDollarCircle } from 'react-icons/bi';
import { BsFillPersonFill } from 'react-icons/bs';
import { FaStoreAlt } from 'react-icons/fa';
import { GoContainer } from 'react-icons/go';
import { LiaPiggyBankSolid } from 'react-icons/lia';
import {
  MdDarkMode,
  MdExpandLess,
  MdExpandMore,
  MdLightMode,
  MdMenu,
  MdProductionQuantityLimits,
} from 'react-icons/md';
import {
  RxActivityLog,
  RxCardStackPlus,
  RxColumnSpacing,
  RxDownload,
  RxGear,
  RxHome,
  RxListBullet,
  RxUpload,
} from 'react-icons/rx';

import { ROUTES, ROUTES_PERMISSIONS } from 'constants/index';

import { ColorModeContext } from 'App';
import SHCLogo from 'assets/imgs/shc-logo.png';
import { useUser } from 'hooks/useUser';
import { useSession } from 'providers/SessionProvider';
import UserMenu from './UserMenu';

type Props = {
  children: React.ReactNode;
};

const CustomToolbar = styled(Toolbar)<ToolbarProps>(({ theme }) => ({
  display: 'flex',
  minHeight: '3.5rem',
}));

const CustomSideMenu = styled(Box)(({ theme }) => ({
  padding: '1rem',
  displayPrint: 'none',
  borderRight:
    theme.palette.mode === 'dark'
      ? '1px solid rgba(255, 255, 255, 0.12)'
      : 'none',
  minHeight: '100%',
  textAlign: 'center',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.2)',
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '5rem',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  img: {
    width: '8rem',
    height: 'auto',
  },
  textAlign: 'center',
}));

export default function DashboardLayout({ children }: Props) {
  const { userData } = useSession();
  const userRole = userData?.role || '';

  const navItems = [
    {
      label: 'Inicio',
      icon: <RxHome />,
      href: ROUTES.HOME,
    },
    {
      label: 'Ingresos',
      icon: <RxDownload />,
      href: ROUTES.INCOME,
      hidden: !ROUTES_PERMISSIONS[ROUTES.INCOME].includes(userRole),
    },
    {
      label: 'Egresos',
      icon: <RxUpload />,
      href: ROUTES.DISCHARGE,
      hidden: !ROUTES_PERMISSIONS[ROUTES.DISCHARGE].includes(userRole),
    },
    {
      label: 'Adicionales',
      icon: <RxCardStackPlus />,
      href: ROUTES.ADDITIONALS,
      hidden: !ROUTES_PERMISSIONS[ROUTES.ADDITIONALS].includes(userRole),
    },
    {
      label: 'Transferencia',
      icon: <RxColumnSpacing />,
      href: ROUTES.TRANSFER,
      hidden: !ROUTES_PERMISSIONS[ROUTES.TRANSFER].includes(userRole),
    },
    {
      label: 'Movimientos',
      icon: <RxActivityLog />,
      href: ROUTES.OPERATIONS,
      hidden: !ROUTES_PERMISSIONS[ROUTES.OPERATIONS].includes(userRole),
    },
    {
      label: 'Liquidaciones',
      icon: <LiaPiggyBankSolid />,
      href: ROUTES.SALES,
      hidden: !ROUTES_PERMISSIONS[ROUTES.SALES].includes(userRole),
    },
    {
      label: 'Listados',
      icon: <RxListBullet />,
      href: ROUTES.LISTS,
      hidden: !ROUTES_PERMISSIONS[ROUTES.LISTS].includes(userRole),
      options: [
        {
          label: 'Usuarios',
          icon: <BsFillPersonFill />,
          href: ROUTES.USERS,
        },
        {
          label: 'Clientes',
          icon: <BsFillPersonFill />,
          href: ROUTES.CLIENTS,
        },
        {
          label: 'Clientes asociados',
          icon: <BsFillPersonFill />,
          href: ROUTES.ASSOCIADED_CLIENTS,
        },
        {
          label: 'Artículos',
          icon: <MdProductionQuantityLimits />,
          href: ROUTES.PRODUCTS,
        },
        {
          label: 'Almacenes',
          icon: <FaStoreAlt />,
          href: ROUTES.STORES,
        },
        {
          label: 'Ubicaciones',
          icon: <RxCardStackPlus />,
          href: ROUTES.LOCATIONS,
        },
        {
          label: 'Unidades',
          icon: <BiSolidDollarCircle />,
          href: ROUTES.UNITS,
        },
        {
          label: 'Contenedores',
          icon: <GoContainer />,
          href: ROUTES.CONTAINERS,
        },
        {
          label: 'Ítems adicionales',
          icon: <RxCardStackPlus />,
          href: ROUTES.ADDITIONAL_ITEMS,
        },
      ],
    },
    {
      label: 'Configuraciones',
      icon: <RxGear />,
      href: ROUTES.CONFIGS,
      hidden: !ROUTES_PERMISSIONS[ROUTES.CONFIGS].includes(userRole),
    },
  ];
  const [userMenuAnchorEl, setUserMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(userMenuAnchorEl);

  const [loading, setLoading] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const colorModeContext = useContext(ColorModeContext);

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isLoggedIn } = useUser();

  const [openListsSubmenu, setOpenListsSubmenu] = useState<boolean>(false);

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(prev => !prev);
  };

  useEffect(() => {
    const checkData = async () => {
      setLoading(true);
      if (!isLoggedIn()) {
        navigate(ROUTES.LOGIN);
      }
      setLoading(false);
    };
    checkData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
      }}
    >
      <CustomSideMenu
        zIndex={1000}
        position='fixed'
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          width: '13rem',
        }}
      >
        <ImageContainer>
          <img src={SHCLogo} alt='Logo SHC' />
        </ImageContainer>
        <Divider sx={{ marginY: '1rem' }} />
        <List
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            width: '11rem',
            position: 'fixed',
            overflowY: 'scroll',
            top: '8rem',
            bottom: 0,
          }}
        >
          {navItems.map(
            (item, index) =>
              !item.hidden && (
                <React.Fragment key={index}>
                  <ListItem disablePadding dense>
                    <ListItemButton
                      data-cy={item.label}
                      onClick={() => {
                        if (item.options) {
                          setOpenListsSubmenu(!openListsSubmenu);
                        } else {
                          navigate(item.href);
                        }
                      }}
                      sx={{
                        borderRadius: '0.5rem',
                        backgroundColor:
                          item.href === location.pathname
                            ? 'action.focus'
                            : null,
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 'auto', mr: '1rem' }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          '.MuiListItemText-primary': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                        primary={item.label}
                      />
                      {item.options && (
                        <>
                          {openListsSubmenu ? (
                            <MdExpandLess />
                          ) : (
                            <MdExpandMore />
                          )}
                        </>
                      )}
                    </ListItemButton>
                  </ListItem>
                  {item.options && (
                    <Collapse
                      in={openListsSubmenu}
                      timeout='auto'
                      unmountOnExit
                    >
                      <List component='div' disablePadding>
                        {item.options.map((option, index) => (
                          <ListItemButton
                            key={index}
                            sx={{ pl: 4, borderRadius: '0.5rem' }}
                            dense
                            onClick={() => navigate(`${option.href}`)}
                          >
                            <ListItemIcon sx={{ minWidth: 'auto', mr: '1rem' }}>
                              {option.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={option.label}
                              sx={{
                                '.MuiListItemText-primary': {
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  fontSize: 12,
                                },
                              }}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              )
          )}
        </List>
      </CustomSideMenu>
      <Box zIndex={1} sx={{ flexGrow: 1 }}>
        <AppBar sx={{ displayPrint: 'none' }}>
          <CustomToolbar
            sx={{ justifyContent: { xs: 'space-between', md: 'flex-end' } }}
          >
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MdMenu />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                gap: 1.5,
                alignItems: 'center',
                padding: 1,
              }}
            >
              <Tooltip
                title={`Cambiar a modo ${
                  theme.palette.mode === 'dark' ? 'claro' : 'oscuro'
                }`}
              >
                <IconButton
                  onClick={() => colorModeContext.toggleColorMode()}
                  size='small'
                  sx={{
                    boxShadow:
                      '-2px -2px 14px rgba(0, 0, 0, .1), -2px -2px 10px rgba(0, 0, 0, .1), 2px 2px 8px rgba(0, 0, 0, .075),2px 2px 10px rgba(0, 0, 0, .15);',
                  }}
                >
                  {theme.palette.mode === 'dark' ? (
                    <MdLightMode />
                  ) : (
                    <MdDarkMode />
                  )}
                </IconButton>
              </Tooltip>
              <IconButton
                onClick={handleUserMenuOpen}
                size='small'
                aria-controls={userMenuOpen ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={userMenuOpen ? 'true' : undefined}
              >
                <Avatar />
              </IconButton>
            </Box>
            <UserMenu
              handleClose={handleUserMenuClose}
              anchorEl={userMenuAnchorEl}
              open={userMenuOpen}
            />
          </CustomToolbar>
        </AppBar>
        <Box
          sx={{
            maxWidth: '100vw',
            paddingRight: { xs: '1rem', md: '2.5rem' },
            paddingLeft: { xs: '1rem', md: '15.5rem' },
            paddingTop: '5.5rem',
            paddingBottom: '1rem',
          }}
        >
          {loading ? (
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          ) : (
            children
          )}
        </Box>
      </Box>
      <Box component='nav' sx={{ displayPrint: 'none' }}>
        <Drawer
          variant='temporary'
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '15rem',
            },
          }}
        >
          <Box
            onClick={handleDrawerToggle}
            sx={{
              textAlign: 'center',
              padding: '1rem',
            }}
          >
            <ImageContainer>
              <img src={SHCLogo} alt='Logo SHC' />
            </ImageContainer>
            <Divider sx={{ marginY: '1rem' }} />
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              {navItems.map((item, index) => (
                <ListItem key={index} disablePadding dense>
                  <ListItemButton
                    onClick={() => navigate(item.href)}
                    sx={{ borderRadius: '0.5rem' }}
                  >
                    <ListItemIcon sx={{ minWidth: 'auto', mr: '1rem' }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
}
