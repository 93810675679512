import { useState, useMemo } from 'react';

interface UseFilterProps<T> {
  data: T[];
  fields: { field: string }[];
}

export function useFilter<T>({ data, fields }: UseFilterProps<T>) {
  const [filterText, setFilterText] = useState('');

  const filteredData = useMemo(() => {
    const lowercasedFilterText = filterText.toLowerCase();

    if (!lowercasedFilterText) return data;

    return data.filter(item => {
      return fields.some(field => {
        const fieldValue = item[field.field as keyof T]; 
        return (fieldValue || '').toString().toLowerCase().includes(lowercasedFilterText);
      });
    });
  }, [data, filterText, fields]);

  return {
    filterText,
    setFilterText,
    filteredData,
  };
}

