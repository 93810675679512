import { DataGrid, GridValidRowModel, GridColDef } from '@mui/x-data-grid';
import { CustomGridProps } from 'types';
import { Checkbox } from '@mui/material';

export default function CustomGrid<T extends unknown>({
  structure,
  rows,
  onEdit,
  selectionModel,
  onRowSelection,
}: CustomGridProps<T>) {
  const { fields } = structure;

  return (
    <DataGrid
      sx={{
        '& .MuiDataGrid-cell': {
          borderRight: '1px solid #f5f5f5',
          borderBottom: '1px solid #f5f5f5',
          padding: '0px 16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      density="compact"
      autoHeight
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      hideFooterSelectedRowCount
      localeText={{
        noRowsLabel: 'No hay registros',
        footerTotalRows: 'Total',
        MuiTablePagination: {
          labelRowsPerPage: 'Filas por página',
        },
      }}
      columns={fields}
      rows={rows as GridValidRowModel[]}
      getRowId={(row) => row.id}
      disableRowSelectionOnClick
      checkboxSelection
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        onRowSelection(newRowSelectionModel);
      }}
      processRowUpdate={(newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
        onEdit(newRow as T);
        return newRow;
      }}
    />
  );
}
