/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PageLayout from "../components/layout/PageLayout";

import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";

import { MOVEMENTS_URL } from "constants/urls";
import useCrud from "hooks/useCrud";
import { ArticleLocation, AssociatedClient, Movement } from "types";

import FileImport from "components/FileImport/FileImport";
import AdditionalItemsList from "components/Operations/AdditionalItemsList";
import OperationPrintDetails from "components/Prints/Operations/OperationPrintDetails";
import OperationPrintHeaderDetails from "components/Prints/Operations/OperationPrintHeaderDetails";
import { PrintLayout } from "components/Prints/PrintLayout";
import Loader from "components/lists/Loader";
import DateTimePickerActionBar from "components/utils/DatePickerActionBar";
import { dateISOFormat } from "helpers";
import { useConfirmationModal } from "hooks/useConfirmationModal";
import { useToast } from "hooks/useToast";
import { useSession } from "providers/SessionProvider";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ArticlesList from "../components/Operations/ArticlesList";
import {
  DATE_VIEWS,
  MOVEMENT_TYPES,
  MovementTypes,
  STATUS_CODES,
  defaultMovement,
} from "../constants";
import { decimalFormat } from "helpers/formatNumber";
import CustomNumberInput from "components/CustomNumberInput";

export type GroupedMovementArticles = {
  article_location_id: number;
  lote: number | null;
  article_locations: ArticleLocation;
  amount_per_container: number;
};

export default function DischargesPage() {
  //context
  const {
    stores,
    additionalItems,
    clients,
    userData,
    associatedClients,
    locations,
    articles,
    users,
  } = useSession();

  //custom hooks
  const { createData: createMovement } = useCrud<Movement[]>(MOVEMENTS_URL, {
    disableAutoFetch: true,
  });
  const {
    data: movements,
    firstLoad: onMovementFirstLoad,
    updateData: updateMovement,
    fetchData: getMovements,
  } = useCrud<Movement>(MOVEMENTS_URL, { disableAutoFetch: true });

  const { showModal } = useConfirmationModal();
  const { showSnackBar } = useToast();
  const navigate = useNavigate();

  //local states
  const [movementLoading, setMovementLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [newPrepOrder, setNewPrepOrder] = useState<boolean>(false);
  const [isPalletCalculationEnabled, setIsPalletCalculationEnabled] =
    useState<boolean>(true);
  const [isWeightCalculationEnabled, setIsWeightCalculationEnabled] =
    useState<boolean>(true);
  const [movement, setMovement] = useState<Movement>({
    ...defaultMovement,
    movement_type_id: MovementTypes.Discharge,
  });
  const [rowIndex, setRowIndex] = useState<number>(0);
  const [movementToPrint, setMovementToPrint] = useState<
    Movement | undefined
  >();

  const [selectedDelivery, setSelectedDelivery] = useState<{
    id: number | undefined;
    label: string;
  }>({ id: 0, label: "Egreso Directo" });
  const [selectedUser, setSelectedUser] = useState<{
    id: number;
    label: string;
  } | null>(
    userData
      ? {
          id: userData.id,
          label: `${userData.first_name} ${userData.last_name}`,
        }
      : null
  );
  const [selectedAuthor, setSelectedAuthor] = useState<{
    id: number;
    label: string;
  } | null>(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState<{
    id: number | null;
    label: string;
  } | null>(null);
  const [selectedClient, setSelectedClient] = useState<{
    id: number;
    label: string;
  } | null>(null);
  const [selectedAssociatedClient, setSelectedAssociatedClient] = useState<{
    id: number;
    label: string;
  } | null>(null);

  //memozation
  const nroOrden = useMemo(() => {
    if (movements.length > 0) {
      const lastMovement = movements[movements.length - 1];
      if (lastMovement.id) {
        return lastMovement.id + 1;
      }
    }
    return 1;
  }, [movements]);

  const prepList = useMemo(() => {
    const movList = movements
      .filter((m) => m.movement_type_id === MovementTypes.Preparation)
      .map((m) => ({ id: m.id, label: `Pedido ${m.id}` }));
    movList.unshift({ id: 0, label: "Egreso Directo" });
    return movList;
  }, [movements]);

  const storesList = useMemo(() => {
    const storesL = stores.map((s) => ({ id: s.id, label: s.description }));
    storesL.unshift({ id: 0, label: "Todos" });
    return storesL;
  }, [stores]);

  const clientAssociatedClients = useMemo(() => {
    return associatedClients.filter(
      (ac: AssociatedClient) => ac.client?.id === movement.client_id
    );
  }, [movement.client_id]);

  //events
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      if (newPrepOrder) {
        setNewPrepOrder(false);
        setMovementLoading(false);
        showSnackBar(
          "Se ha creado el pedido a preparar correctamente",
          "success"
        );
        setMovementToPrint(undefined);
      } else {
        navigate("/operations");
        setMovementLoading(false);
        showSnackBar("Se ha creado el egreso correctamente", "success");
      }
    },
  });

  const handleChange = useCallback(
    <K extends keyof Movement>(
      name: K,
      value: Movement[K] | null | undefined | string
    ) => {
      setMovement((prev: Movement) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleSubmit = async () => {
    setMovementLoading(true);
    if (selectedDelivery.id === 0 || newPrepOrder) {
      const movementResponse = await createMovement([movement], true);
      if (movementResponse.status === STATUS_CODES.OK) {
        const aggregatedMovementResponse = await getMovements(
          `${movementResponse.data[0].id}`,
          true
        );
        setMovement(aggregatedMovementResponse.data[0]);
        setMovementToPrint(aggregatedMovementResponse.data[0]);
      } else {
        setMovementLoading(false);
        showSnackBar(
          movementResponse.status === STATUS_CODES.BAD_REQUEST
            ? "El número de remito ya se encuentra registrado, por favor modifiquelo e intente nuevamente."
            : "El pedido no ha podido crearse, por favor intente nuevamente",
          "error"
        );
      }
    } else {
      const updateMovementResponse = await updateMovement(
        movement.id || 0,
        movement,
        true,
        true
      );
      if (updateMovementResponse.status === STATUS_CODES.OK) {
        const aggregatedMovementResponse = await getMovements(
          `${movement.id}`,
          true
        );
        setMovementToPrint(aggregatedMovementResponse.data[0]);
      } else {
        showSnackBar(
          "El egreso no ha podido crearse, por favor intente nuevamente",
          "error"
        );
      }
    }
    setMovementLoading(false);
  };

  const calculateTotalPallets = useCallback(() => {
    const totalPallets = movement.articles.reduce((sum, article) => {
      const unitsPerPallet = article.article_locations.article.units_per_pallet;
      const amount = article.amount;
      const palletsForArticle = unitsPerPallet ? Math.ceil(amount / unitsPerPallet) : 0;
      return sum + palletsForArticle;
    }, 0);
    setMovement((prev) => ({ ...prev, pallets: decimalFormat(totalPallets) }));
  }, [movement.articles]);

  //side effects
  useEffect(() => {
    const setMovementData = async () => {
      await getMovements(
        undefined,
        true,
        { movementTypeId: MovementTypes.Preparation },
        true
      );
      setMovement({
        ...defaultMovement,
        created_by: selectedUser?.id ?? defaultMovement.created_by,
        movement_type_id: newPrepOrder
          ? MovementTypes.Preparation
          : MovementTypes.Discharge,
      });
      setSelectedAuthor(null);
      setSelectedWarehouse(null);
      setSelectedClient(null);
      setSelectedAssociatedClient(null);
    };
    setMovementData();
  }, [newPrepOrder]);

  useEffect(() => {
    if (selectedDelivery.id === 0) {
      setMovement({
        ...defaultMovement,
        movement_type_id: MovementTypes.Discharge,
      });

      setSelectedUser(null);
      setSelectedAuthor(null);
      setSelectedWarehouse(null);
      setSelectedClient(null);
      setSelectedAssociatedClient(null);
    } else {
      const delivery = movements.find((m) => m.id === selectedDelivery.id);

      if (delivery) {
        setMovement({
          ...delivery,
          movement_type_id: 2,
          articles: delivery.articles.map((a) => ({
            ...a,
            article_locations: {
              id: a.article_locations.id,
              stock: a.article_locations.stock,
              reserved: a.article_locations.reserved,
              article_id: a.article_locations.article_id,
              location_id: a.article_locations.location_id,
              article: a.article_locations.article,
              location: a.article_locations.location,
            },
            article_locations_id: a.article_locations.id,
            article_id: a.article_locations.article.id,
            amount_per_container: a.amount_per_container,
            client_id: a.article_locations.article.client.id,
            location_id: a.article_locations.location_id,
            container: a.article_locations.article.container.description,
            description: a.article_locations.article.description,
            unit: a.article_locations.article.unit.description,
          })),
          additional_items: delivery.additional_items.map((ai) => ({
            ...ai,
            description:
              additionalItems.find((a) => a.id === ai.additional_item_id)
                ?.description || "",
          })),
        });
        setSelectedUser({
          id: delivery.created_by,
          label: `${
            users.find((u) => u.id === delivery.created_by)?.first_name
          } ${users.find((u) => u.id === delivery.created_by)?.last_name}`,
        });
        setSelectedAuthor({
          id: delivery.updated_by,
          label: `${
            users.find((u) => u.id === delivery.updated_by)?.first_name
          } ${users.find((u) => u.id === delivery.updated_by)?.last_name}`,
        });
        if (delivery.store_id === null) {
          setSelectedWarehouse({ id: 0, label: "Todos" });
        } else {
          setSelectedWarehouse({
            id: delivery.store_id,
            label: `${
              stores.find((s) => s.id === delivery.store_id)?.description
            }`,
          });
        }
        setSelectedClient({
          id: delivery.client_id,
          label: `${
            clients.find((c) => c.id === delivery.client_id)?.razon_social
          }`,
        });
        setSelectedAssociatedClient({
          id:
            clientAssociatedClients.find(
              (ac) => ac.razon_social === delivery.associated_client
            )?.id ?? 0,
          label: delivery.associated_client,
        });
      }
    }
  }, [selectedDelivery]);

  useEffect(() => {
    if (userData) {
      setMovement({
        ...movement,
        created_by: userData.id,
      });
      setSelectedUser({
        id: userData.id,
        label: `${userData.first_name} ${userData.last_name}`,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (movementToPrint) {
      handlePrint();
    }
  }, [movementToPrint]);

  useEffect(() => {
    calculateTotalPallets();
  }, [movement.articles, calculateTotalPallets]);

  return (
    <PageLayout
      pageTitle="Egresos"
      pageDesc="Orden de egreso de mercaderí­a"
      importButton={
        <FileImport
          movementType={
            newPrepOrder
              ? MOVEMENT_TYPES.PREPARATION.label
              : MOVEMENT_TYPES.DISCHARGE.label
          }
          locations={locations}
          clients={clients}
          articles={articles}
          additional_items={additionalItems}
          users={users}
          stores={stores}
        />
      }
    >
      {movementToPrint && (
        <PrintLayout
          ref={printRef}
          headerInfo={
            <OperationPrintHeaderDetails
              movement={movementToPrint}
              stores={stores}
            />
          }
        >
          <OperationPrintDetails
            movement={movementToPrint}
            client={movementToPrint.client?.razon_social}
          />
        </PrintLayout>
      )}
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          showModal({
            title: "Confirmar",
            message: "¿Está seguro que desea confirmar el egreso?",
            action: handleSubmit,
          });
        }}
      >
        {movementLoading ? (
          <Loader
            isLoading={movementLoading}
            isFirstLoad={onMovementFirstLoad}
          />
        ) : (
          <>
            <Grid container columnSpacing={1}>
              {!newPrepOrder && (
                <Grid item xs={12} sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body2" fontWeight="bold">
                    Orden nº
                  </Typography>
                  <Typography variant="body2" fontWeight="bold" color="primary">
                    {nroOrden}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{ display: "flex", gap: 1, alignItems: "center" }}
              >
                <Typography variant="body2" fontWeight="bold">
                  Egreso
                </Typography>
                <Switch
                  data-cy="prep-switch"
                  checked={newPrepOrder}
                  onChange={() => setNewPrepOrder(!newPrepOrder)}
                />
                <Typography variant="body2" fontWeight="bold">
                  Pedido a preparar
                </Typography>
              </Grid>
              {!newPrepOrder && (
                <Grid item xs={12} display="flex" alignItems="center" gap={2}>
                  <FormControl sx={{ width: "33%" }} margin="none">
                    <Autocomplete
                      data-cy="prep-order"
                      id="prepOrder"
                      autoComplete
                      disableClearable
                      options={prepList}
                      value={selectedDelivery}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(
                        e,
                        value: {
                          id: number | undefined;
                          label: string;
                        } | null
                      ) => setSelectedDelivery(value || { id: 0, label: "" })}
                      renderInput={(params) => (
                        <TextField {...params} label="Pedido a preparar" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} data-cy={option.label}>
                          {option.label}
                        </li>
                      )}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={4}>
                <FormControl fullWidth margin="none">
                  <Autocomplete
                    id="user"
                    autoComplete
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={selectedUser}
                    options={users.map((u) => ({
                      id: u.id,
                      label: `${u.first_name} ${u.last_name}`,
                    }))}
                    onChange={(
                      e,
                      value: { id: number; label: string } | null
                    ) => {
                      handleChange("created_by", value?.id);
                      setSelectedUser(value || null);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} required label="Usuario" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="none">
                  <Autocomplete
                    data-cy="author"
                    id="author"
                    autoComplete
                    value={selectedAuthor}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disabled={!movement.created_by}
                    options={users.map((u) => ({
                      id: u.id,
                      label: `${u.first_name} ${u.last_name}`,
                    }))}
                    onChange={(
                      e,
                      value: { id: number; label: string } | null
                    ) => {
                      handleChange("updated_by", value?.id);
                      setSelectedAuthor(value || null);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} required label="Preparó" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} data-cy={option.label}>
                        {option.label}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="none">
                  <DateTimePicker
                    disableFuture
                    label="Fecha"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slots={{
                      actionBar: DateTimePickerActionBar,
                    }}
                    slotProps={{
                      textField: {
                        inputProps: {
                          "data-cy": `date`,
                        },
                      },
                    }}
                    views={DATE_VIEWS}
                    value={movement.date ? new Date(movement.date) : null}
                    onChange={(value: Date | null) => {
                      try {
                        if (value?.toISOString()) {
                          handleChange(
                            "date",
                            dateISOFormat(value.toISOString())
                          );
                        }
                      } catch (dateExcep) {
                        return;
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth margin="none">
                  <Autocomplete
                    data-cy="client"
                    id="client"
                    autoComplete
                    value={selectedClient}
                    options={clients.map((c) => ({
                      id: c.id,
                      label: c.razon_social,
                    }))}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(
                      e,
                      value: {
                        id: number;
                        label: string;
                      } | null
                    ) => {
                      setSelectedClient(value || null);
                      setSelectedAssociatedClient(null);
                      setMovement((prev) => ({
                        ...prev,
                        client_id: value?.id ?? 0,
                        articles: [],
                        associated_client: "",
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} required label="Cliente" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} data-cy={option.label}>
                        {option.label}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="none">
                  <Autocomplete
                    data-cy="associated-client"
                    id="associated-client"
                    value={selectedAssociatedClient}
                    options={clientAssociatedClients?.map((c) => ({
                      id: c.id,
                      label: c.razon_social,
                    }))}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    disabled={!movement.client_id}
                    onChange={(
                      e,
                      value: { id: number; label: string } | null
                    ) => {
                      handleChange("associated_client", value?.label);
                      setSelectedAssociatedClient(value || null);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Cliente Asociado"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} data-cy={option.label}>
                        {option.label}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth required margin="none">
                  <CustomNumberInput
                    label="Documento del Cliente"
                    value={movement.client_document}
                    onChange={(e) => handleChange("client_document", e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="none">
                  <Autocomplete
                    data-cy="warehouse"
                    id="warehouse"
                    autoComplete
                    value={selectedWarehouse}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={storesList}
                    onChange={(
                      e,
                      value: { id: number | null; label: string } | null
                    ) => {
                      if (value && value.id === 0) {
                        handleChange("store_id", null);
                      } else {
                        handleChange("store_id", value?.id);
                      }
                      setSelectedWarehouse(value || null);
                      setMovement((prev) => ({
                        ...prev,
                        store_id: value?.id ?? 0,
                        articles: [],
                      }));
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} required label="Almacen" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} data-cy={option.label}>
                        {option.label}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
              {!newPrepOrder ? (
                <>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="none">
                      <CustomNumberInput
                        label="Pallets"
                        value={movement.pallets}
                        onChange={(e) => {
                          setIsPalletCalculationEnabled(false);
                          handleChange("pallets", Number(e.target.value));
                        }}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="none">
                      <CustomNumberInput
                        label="Peso"
                        value={movement.weight}
                        onChange={(e) => {
                          setIsWeightCalculationEnabled(false);
                          handleChange("weight", Number(e.target.value));
                        }}
                        required
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="none">
                      <CustomNumberInput
                        data-cy="remito"
                        label="Número de Remito"
                        value={movement.numero_remito}
                        onChange={(e) => handleChange("numero_remito", e.target.value)}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="none">
                      <TextField
                        data-cy="obs"
                        id="observations"
                        label="Observaciones"
                        type="text"
                        fullWidth
                        value={movement.observation}
                        onChange={(e) =>
                          handleChange("observation", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {!newPrepOrder && (
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="none">
                      <CustomNumberInput
                        data-cy="remito"
                        disabled={selectedDelivery.id !== 0}
                        label="Número de Remito"
                        value={movement.numero_remito}
                        onChange={(e) => handleChange("numero_remito", e.target.value)}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="none">
                      <TextField
                        data-cy="obs"
                        id="observations"
                        label="Observaciones"
                        type="text"
                        fullWidth
                        value={movement.observation}
                        onChange={(e) =>
                          handleChange("observation", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={currentTab} onChange={handleTabChange}>
                  <Tab label="Artículos" />
                  <Tab label="Ítems Adicionales" />
                </Tabs>
              </Box>
              <Grid item xs={12} my={2}>
                {currentTab === 0 ? (
                  <ArticlesList
                    movement={movement}
                    onMovementUpdate={handleChange}
                    operationType={MOVEMENT_TYPES.DISCHARGE.label}
                    locations={locations}
                    stores={stores}
                    hasPalletAutocalculation={isPalletCalculationEnabled}
                    hasWeightAutocalculation={isWeightCalculationEnabled}
                    rowIndex={rowIndex}
                    setRowIndex={setRowIndex}
                  />
                ) : (
                  <AdditionalItemsList
                    additionalItems={additionalItems}
                    movement={movement}
                    onMovementUpdate={handleChange}
                    rowIndex={rowIndex}
                    setRowIndex={setRowIndex}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                data-cy="confirm-prep-discharge-button"
                type="submit"
                variant="contained"
              >
                Enviar
              </Button>
            </Grid>
          </>
        )}
      </form>
    </PageLayout>
  );
}
