import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Chip,
  Divider,
  LinearProgress,
  useTheme,
  Tooltip,
} from "@mui/material";
import { ArticleLocation } from "types";
import { volumeCalc } from "helpers";

type Props = {
  articleData: ArticleLocation;
};

export default function StockCard({ articleData }: Props) {
  const theme = useTheme();
  function normalise(value: number, max: number): number {
    return (value / max) * 100;
  }

  return (
    <Grid item md={4} sm={6} xs={12}>
      <Card sx={{ width: "100%" }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Tooltip
              title={
                <Typography variant="body1">
                  {articleData.article.description}
                </Typography>
              }
              followCursor
              placement="top"
              enterDelay={1000}
              enterNextDelay={1000}
            >
              <Typography
                variant="h5"
                overflow="hidden"
                textOverflow="ellipsis"
                noWrap
              >
                {articleData.article.description}
              </Typography>
            </Tooltip>
            <Chip label={articleData.article.client.razon_social} />
          </Box>
          <Typography variant="h6">{`${articleData.location.store.description} | ${articleData.location.code}`}</Typography>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography variant="body2">Ocupación</Typography>
              <Typography variant="body2">
                Cantidad:{" "}
                <span style={{ fontWeight: "bold" }}>{articleData.stock}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <Typography variant="body2" fontWeight="bold">
                  {articleData.location.code}
                </Typography>
                <Typography variant="caption">
                  {volumeCalc(
                    articleData.location.width,
                    articleData.location.long,
                    articleData.location.height
                  ).toFixed(2) +
                    " m3"}
                </Typography>
              </Grid>
              <Tooltip
                title={
                  <Typography variant="body1">{`${articleData.location.occupied.toFixed(
                    2
                  )} m3`}</Typography>
                }
                followCursor
                placement="top"
              >
                <LinearProgress
                  sx={{
                    height: "0.5rem",
                    borderRadius: 5,
                    ".MuiLinearProgress-bar": {
                      background: `linear-gradient(to right, ${theme.palette.background.default}, ${theme.palette.primary.main})`,
                    },
                  }}
                  color="primary"
                  variant="determinate"
                  value={normalise(
                    articleData.location.occupied,
                    volumeCalc(
                      articleData.location.width,
                      articleData.location.long,
                      articleData.location.height
                    )
                  )}
                />
              </Tooltip>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <Typography variant="caption">Vacío</Typography>
                <Typography variant="caption">Lleno</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
        </CardContent>
        {/*<CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant='outlined'>Acción 1</Button>
          <Button variant='outlined'>Acción 2</Button>
        </CardActions>*/}
      </Card>
    </Grid>
  );
}
