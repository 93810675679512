import { useEffect, useState } from 'react';

import CustomGrid from '../CustomGrid';
import { gridStructure } from 'constants/CustomGridStructure';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { Box, Paper } from '@mui/material';
import Loader from '../Loader';

import AddStoreModal from './AddStoreModal';
import EditStoreModal from './EditStoreModal';
import DeleteConfirmationModaL from 'components/utils/DeleteConfirmationModal';
import { EnhancedTableToolbar } from '../EnhancedToolbar';

import { Store } from 'types';
import { STORES_URL as BASE_URL } from 'constants/urls';
import { ENTITY } from 'constants/entities';

import useModals from 'hooks/useModals';
import useCrud from 'hooks/useCrud';
import { useSession } from 'providers/SessionProvider';
import { STATUS_CODES } from 'constants/index';
import { useToast } from 'hooks/useToast';
import { useFilter } from 'hooks/useFilter';

export default function StoresList() {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const {
    addModalOpen,
    setAddModalOpen,
    editModalOpen,
    setEditModalOpen,
    deleteModalOpen,
    setDeleteModalOpen,
  } = useModals();

  const { stores, addSessionData, updateSessionData, deleteSessionData } =
    useSession();

  const { showSnackBar } = useToast();

  const {
    loading,
    deleteData: deleteStore,
    updateData: udpateStore,
    createData: createStore,
    firstLoad,
    showToast,
    toastMessage,
    toastSeverity,
    setShowToast,
  } = useCrud<Store>(BASE_URL);

  const { filterText, setFilterText, filteredData: filteredStores } = useFilter<Store>({
    data: stores,
    fields: gridStructure[ENTITY.STORES].fields,
  });

  const handleDeleteSelected = async () => {
    const failedDeletes: number[] = [];

    for (const id of selected) {
      const reponse = await deleteStore(id, true, true);
      if (reponse.status !== STATUS_CODES.OK) {
        failedDeletes.push(Number(id));
      }
    }
    deleteSessionData(
      ENTITY.STORES,
      selected
        .filter(item => !failedDeletes.includes(Number(item)))
        .map(item => item.toString())
    );

    setSelected([]);
  };

  const handleEdit = async (storeToEdit: Store) => {
    const response = await udpateStore(storeToEdit.id, storeToEdit, true, true);
    if (response.status === STATUS_CODES.OK) {
      updateSessionData(ENTITY.STORES, storeToEdit);
    }

    setSelected([]);
  };

  const handleCreate = async (newStore: Store) => {
    const response = await createStore(newStore, true, true);
    if (response.status === STATUS_CODES.OK) {
      addSessionData(ENTITY.STORES, response.data as Store);
    }
  };

  useEffect(() => {
    if (showToast) {
      showSnackBar(toastMessage, toastSeverity);
      setShowToast(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  return (
    <>
      {firstLoad ? (
        <Loader isFirstLoad={firstLoad} isLoading={loading} />
      ) : (
        <>
          <Loader isFirstLoad={firstLoad} isLoading={loading} />
          <AddStoreModal
            open={addModalOpen}
            handleOpen={() => setAddModalOpen(!addModalOpen)}
            onCreate={handleCreate}
          />
          <DeleteConfirmationModaL
            open={deleteModalOpen}
            handleOpen={() => setDeleteModalOpen(!deleteModalOpen)}
            entityName={ENTITY.STORES}
            values={selected.map(item => {
              const value = stores.find(c => c.id === item)?.description;
              if (value) return value;
              return '';
            })}
            handleDelete={handleDeleteSelected}
          />
          {selected.length > 0 ? (
            <EditStoreModal
              open={editModalOpen}
              handleOpen={() => setEditModalOpen(!editModalOpen)}
              value={stores.find(s => s.id === selected[0]) as Store}
              onEdit={handleEdit}
            />
          ) : null}
          <Box sx={{ width: '99%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                handleAddModalOpen={() => setAddModalOpen(!addModalOpen)}
                handleEditModalOpen={() => setEditModalOpen(!editModalOpen)}
                handleDeleteModalOpen={() =>
                  setDeleteModalOpen(!deleteModalOpen)
                }
                onFilterChange={setFilterText}
              />
              <CustomGrid<Store>
                structure={gridStructure[ENTITY.STORES]}
                rows={filteredStores}
                onEdit={handleEdit}
                selectionModel={selected}
                onRowSelection={setSelected}
              />
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}
